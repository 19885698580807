<template>
  <div class="datepicker">
    <div class="datepicker-wrapper">
      <font-awesome-icon
        v-if="!hideInputIcon"
        icon="fa-solid fa-calendar-days"
        class="custom-calendar-icon"
      />
      <VueDatepicker
        ref="datepicker"
        hide-input-icon
        :disabled="disabled"
        :model-value="inputValue"
        :auto-apply="true"
        :clearable="false"
        :disabled-dates="disabledDates ? disabledDates.isDateInactive : null"
        :enable-time-picker="false"
        :format="format"
        :hide-offset-dates="true"
        :input-class-name="inputClass"
        :max-date="disabledDates.maxDate"
        :min-date="disabledDates.minDate"
        month-name-format="long"
        :locale="$i18n.locale"
        :prevent-min-max-navigation="disabledDates.maxDate !== null || disabledDates.minDate !== null"
        :year-range="yearRange"
        @closed="$emit('closed')"
        @open="$emit('opened')"
        @update:modelValue="emitNewDate"
      />
    </div>
  </div>
</template>

<script>
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

/**
 * @typedef DisabledDates
 * @property {Date} minDate
 * @property {Date} maxDate
 * @property {Function} [isDateInactive]
 */

export default {
  name: 'Datepicker',

  components: {
    VueDatepicker,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    hideInputIcon: {
      type: Boolean,
      default: false,
    },

    /** @type {Vue.PropOptions<DisabledDates>} */
    disabledDates: {
      type: Object,
      required: false,
      default: () => ({
        minDate: null,
        maxDate: null,
      }),
    },

    /** @type {?Vue.PropOptions<String>} */
    inputClass: {
      type: String,
      default: () => null,
    },

    /** @type {Vue.PropOptions<Date>} */
    value: {
      type: Date,
      required: false,
      default: () => new Date(),
    },
  },

  emits: ['closed', 'update:value', 'opened'],

  data() {
    return {
      /** @type {Date} */
      inputValue: this.value,
    };
  },

  computed: {
    format() {
      return this.$i18n.t('datepicker.dateFormat');
    },

    yearRange() {
      return [new Date().getFullYear() - 4, new Date().getFullYear() + 4];
    },
  },

  watch: {
    value() {
      if (this.value !== this.inputValue) {
        this.inputValue = this.value;
      }
    },
  },

  methods: {
    emitNewDate(date) {
      this.inputValue = date;
      this.$emit('update:value', date);
    },

    /** Allow to close calendar from parent component - not an option in the library */
    forceClose() {
      this.$refs.datepicker.closeMenu();
    },

    openMenu() {
      this.$refs.datepicker.openMenu();
    },
  },
};
</script>

<style lang="scss">
.datepicker-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .custom-calendar-icon {
    margin-right: 5px;
    pointer-events: none;
    font-size: 0.9em;
    color: #00b871;
  }
}

:root {
  // Modify default properties
  --dp-button-icon-height: 1.5em; // icon sizing in buttons
  --dp-cell-size: 30px; // width and height of calendar cell
  --dp-font-family: $font-poppins;
  --dp-font-size: 12px; // overall font-size
  --dp-month-year-row-button-size: 20px; // Specific height for the next/previous buttons
  --dp-menu-min-width: 240px; // Adjust the min width of the menu
  --dp-preview-font-size: 1em; // font size of the date preview in the action row
  --dp-border-radius: 9px; // Border radius everywhere
}

.dp__theme_light {
  --dp-primary-color: #00b871; // $primary-light
  --dp-background-color: #fbfbfb; // $canvas
  --dp-text-color: #4f4f4f; // $text-dark-variant
  --dp-hover-color: #00b871; // $primary-light
  --dp-hover-text-color: #fbfbfb; // $text-light
  --dp-disabled-color: #b3b3b3; // $text-neutral
  --dp-highlight-color: #00b871; // $primary-light
  --dp-icon-color: #00b871; // $primary-light
  --dp-hover-icon-color: #00b871; // $primary-light
}

input.dp__disabled {
  color: $text-neutral;
  cursor: not-allowed;
}

.dp__arrow_top {
  display: none;
}

.dp__button:hover {
  background: $canvas;
}

.dp__calendar_header_item,
.dp__overlay {
  text-transform: capitalize;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__calendar_item {
  font-weight: $font-weight-semi-bold;
}

.dp__calendar_row {
  margin: 0;
}

.dp__inner_nav {
  margin: 5px;

  &:hover {
    background: $canvas;
  }
}

.dp__inner_nav_disabled {
  background: $canvas;
  color: $text-neutral;

  &:hover {
    background: $canvas;
    color: $text-neutral;
  }
}

.dp__input_icons {
  width: 16px;
  height: 16px;
  padding: 0;
}

.dp__month_year_select {
  color: $primary-light;
  font-weight: $font-weight-semi-bold;
  font-size: 14px;
  text-transform: capitalize;

  &:hover {
    background: $canvas;
    color: $primary-light;
  }
}

.dp__overlay_cell {
  color: var(--dp-text-color);
  font-weight: $font-weight-semi-bold;
}

.dp__overlay_cell_disabled {
  background: $canvas;
  color: $text-neutral;

  &:hover {
    background: $canvas;
    color: $text-neutral;
  }
}

.datepicker {
  &__arrow {
    position: relative;

    // Chevron arrow in date input :
    &::before {
      content: '\f078';
      position: absolute;
      top: calc(50% - (18px / 2));
      right: 15px;
      z-index: 1;
      height: 18px;
      color: $primary-light;
      font-weight: $font-weight-semi-bold;
      font-family: $font-awesome;
      transition-duration: 0.2s;
      transition-property: transform;
    }

    &--open::before {
      transform: rotate(180deg);
    }
  }
}
</style>
