<template>
  <Modal modal-class="modal-device-edit" @close="closeModal">
    <template #title>
      {{ $t('editDevice') }}
    </template>

    <template v-if="device" #body>
      <div class="form-group">
        <label class="form-group__label" for="deviceId">{{ $t('deviceId') }}</label>
        <input
          id="deviceId"
          class="form-group__input form-group__input--disabled"
          type="text"
          required
          :value="deviceId"
          disabled
        />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="lastTs">{{ $t('lastTs') }}</label>
        <input
          id="lastTs"
          class="form-group__input form-group__input--disabled"
          type="text"
          required
          :value="device.ts ? $d(device.ts * 1000, 'datetimeLong') : $tc('none', 2)"
          disabled
        />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="name">{{ $t('name') }}</label>
        <input id="name" v-model="name" class="form-group__input" type="text" />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="teams">
          {{ $t('column.teams') }}
        </label>
        <SingleTeamSelector
          id="teams"
          :team-id="teamId"
          no-team-placeholder
          :edit-mode="true"
          @valueChanged="updateTeams"
        />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="driver">{{ $t('assigned_driver') }}</label>
        <Selector
          id="driver"
          v-model:value="assignedDriverId"
          :options="driversOptions"
          mode="single"
          @open="scrollToElement"
        />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="vehicle">{{ $t('assigned_vehicle') }}</label>
        <Selector
          id="vehicle"
          v-model:value="assignedVehicleId"
          :options="vehiclesOptions"
          mode="single"
          @open="scrollToElement"
        />
      </div>

      <template v-if="!device.app_version || isVersionValid(String(device.app_version), '2.3')">
        <div class="form-group">
          <label class="form-group__label" for="mode">
            {{ $t('mode.title') }}
          </label>
          <Selector
            id="mode"
            v-model:value="mode.name"
            :options="deviceModesOptions"
            mode="single"
            @select="mode.param = null"
          />
        </div>

        <template v-if="mode.name === DeviceMode.TRIP">
          <div class="form-group">
            <label class="form-group__label" for="route-filter">{{ $t('routeFilter') }}</label>
            <Selector
              id="route-filter"
              v-model:value="routeId"
              :options="routesOptions"
              mode="single"
              @select="mode.param = null"
            />
          </div>
        </template>

        <template v-if="mode.name !== DeviceMode.TRIP || routeId">
          <div class="form-group">
            <label class="form-group__label" for="mode-filter">{{ $t('modeFilter') }}</label>
            <Selector
              id="mode-filter"
              v-model:value="mode.param"
              :options="driveModeFilterOptions"
              mode="single"
            />
          </div>
        </template>
      </template>

      <div class="form-group">
        <v-checkbox id="simulation" v-model="simulation" color="success" hide-details>
          <template #label>
            {{ $t('simulation') }}
          </template>
        </v-checkbox>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!(isDeviceChanged && isFormValid)" @click="submitEditModal">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Selector from '@/components/ui/Selector.vue';
import SingleTeamSelector from '@/components/ui/SingleTeamSelector.vue';
import { DeviceMode, getDeviceMode } from '@/store/devices';
import { formatDriver } from '@/store/drivers';
import { formatVehicle } from '@/store/vehicles';

export default {
  name: 'ModalDeviceEdit',

  components: {
    Btn,
    Modal,
    Selector,
    SingleTeamSelector,
  },

  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },

  emits: ['close'],

  data: () => ({
    DeviceMode,

    mode: {
      /** @type {?DeviceMode} */
      name: null,
      /** @type {?string} */
      param: null,
    },

    /** @type {?string} */
    assignedDriverId: null,
    /** @type {?string} */
    assignedVehicleId: null,
    /** @type {?string} */
    name: null,
    /** @type {?string} */
    routeId: null,
    /** @type {{[routeId: string]: Array<{tripId: string, tripName: string}>}} */
    routeTripNames: {},
    /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
    routes: null,
    /** @type {Boolean} */
    simulation: false,
    /** @type {?string} */
    teamId: null,
    /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
    tripDetails: {},
    /** @type {boolean} */
    tripDetailsLoaded: false,
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {import('@/store/devices').Device} */
        device(state) {
          return state.devices.list[this.deviceId];
        },
        /** @return {{[driverId: string]: import('@/store/drivers').Driver}} */
        driversList: state => state.drivers.list,
        /** @return {{[driverId: string]: import('@/store/vehicles').Vehicle}} */
        vehiclesList: state => state.vehicles.list,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    driversOptions() {
      return Object.values(this.driversList).map(driver => ({
        value: driver._id,
        label: this.formatDriver(driver),
      }));
    },

    vehiclesOptions() {
      return Object.values(this.vehiclesList).map(vehicle => ({
        value: vehicle._id,
        label: this.formatVehicle(vehicle),
      }));
    },

    /** @return {Array<DeviceMode>} */
    deviceModesOptions() {
      return [
        { value: DeviceMode.TRIP, label: this.$t(`mode.${DeviceMode.TRIP}`) },
        {
          value: DeviceMode.SEQUENCE,
          label: this.$t(`mode.${DeviceMode.SEQUENCE}`),
        },
      ];
    },

    /** @return {Array<string>} */
    blockIds() {
      /** @type {{[blockId: string]: boolean}} */
      const blockIds = {};
      Object.values(this.tripDetails).forEach(trip => {
        if (trip.block_id) {
          blockIds[trip.block_id] = true;
        }
      });

      return Object.keys(blockIds);
    },

    /** @return {Array<{label: string, label: string}>} */
    driveModeFilterOptions() {
      /** @type {Array<{label: string, label: string}>} */
      let options = [{ value: '', label: /** @type {string} */ (this.$t('deadRun')) }];

      switch (this.mode.name) {
        case DeviceMode.SEQUENCE: {
          options = options.concat(this.blockIds.map(id => ({ value: id, label: id })));
          break;
        }

        case DeviceMode.TRIP: {
          options = options.concat(
            (this.routeTripNames[this.routeId] || []).map(info => ({
              value: info.tripId,
              label: info.tripName,
            }))
          );
          break;
        }
        default:
          break;
      }

      return options;
    },

    /** @return {boolean} */
    isDeviceChanged() {
      if (this.device) {
        const hasTeamChange = !!(
          (this.teamId && (!this.device.teams || !this.device.teams.includes(this.teamId))) ||
          (!this.teamId && this.device.teams)
        );
        return (
          hasTeamChange ||
          this.name !== this.device.name ||
          this.simulation !== !!this.device.simulation_mode ||
          this.assignedDriverId !== this.device.assigned_driver_id ||
          this.assignedVehicleId !== this.device.assigned_vehicle_id ||
          this.isModeChanged
        );
      }

      return false;
    },

    /** @return {boolean} */
    isFormValid() {
      return !(
        this.mode.param &&
        ((this.mode.name === DeviceMode.SEQUENCE && !this.blockIds.includes(this.mode.param)) ||
          (this.mode.name === DeviceMode.TRIP && !this.tripDetails[this.mode.param]))
      );
    },

    /** @return {boolean} */
    isModeChanged() {
      if (this.device) {
        const deviceMode = getDeviceMode(this.device) || {};

        return this.mode.name !== deviceMode.name || this.mode.param !== deviceMode.param;
      }

      return false;
    },

    /** @return {Array<string>} */
    routesOptions() {
      return Object.keys(this.routes).map(routeId => ({
        label: this.getRouteName(routeId),
        value: routeId,
      }));
    },
  },

  watch: {
    deviceId: {
      immediate: true,
      handler() {
        if (this.tripDetailsLoaded) {
          this.initData();
        }
      },
    },

    tripDetails() {
      if (this.device != null) {
        this.initData();
      }
    },
  },

  async created() {
    await this.fetchRoutes();
    await this.fetchTrips();
    this.loadDrivers();
    this.loadVehicles();
  },

  methods: {
    formatDriver,
    formatVehicle,

    /**
     * Close the current modal.
     * @emits event:close
     */
    closeModal() {
      this.$emit('close');
    },

    /**
     * Get all routes from API and set data routes
     */
    async fetchRoutes() {
      this.routes = Object.freeze(await this.$store.dispatch('gtfs/getRoutesMap'));
    },

    /**
     * Get all trips from API and set data trips
     */
    async fetchTrips() {
      this.tripDetailsLoaded = false;
      this.tripDetails = Object.freeze(await this.$store.dispatch('gtfs/getTripsMap'));
      this.tripDetailsLoaded = true;

      this.getRouteTripNames();
    },

    /**
     * Get the route long name or short name if available.
     * @param {string} routeId
     * @return {?string}
     */
    getRouteName(routeId) {
      if (this.routes && this.routes[routeId]) {
        return this.routes[routeId].route_long_name || this.routes[routeId].route_short_name;
      }
      return routeId;
    },

    /**
     * Set routeTripNames data.
     */
    async getRouteTripNames() {
      const routeTripNames =
        /** @type {{[routeId: string]: Array<{tripId: string, tripName: string}>}} */ ({});

      await Promise.all(
        Object.entries(this.tripDetails).map(async ([tripId, trip]) => {
          if (!routeTripNames[trip.route_id]) {
            routeTripNames[trip.route_id] = [];
          }

          const tripName = await this.$store.dispatch('gtfs/formatTripName', {
            tripId,
            date: new Date(),
          });
          routeTripNames[trip.route_id].push({
            tripId,
            tripName,
          });
        })
      );

      this.routeTripNames = routeTripNames;
    },

    initData() {
      this.name = this.device.name;
      this.simulation = !!this.device.simulation_mode;
      this.teamId = this.device.teams ? this.device.teams[0] : null;
      this.assignedDriverId = this.device.assigned_driver_id;
      this.assignedVehicleId = this.device.assigned_vehicle_id;

      this.mode = getDeviceMode(this.device) || {};
      if (this.mode.name === DeviceMode.TRIP) {
        const trip = this.tripDetails[this.mode.param];
        if (trip) {
          this.routeId = trip.route_id;
        }
      }
    },

    /**
     * Check if a version is valid for a target.
     * @param {string} version
     * @param {string} target
     * @return {boolean}
     */
    isVersionValid(version, target) {
      /**
       * Compare versions' values recursively.
       * @param {Array<string>} version
       * @param {Array<string>} target
       * @param {number} [index] - Index to check.
       * @return {boolean} true when valid.
       */
      const compare = (version, target, index = 0) => {
        const versionValue = Number(version[index]) || 0;
        const targetValue = Number(target[index]) || 0;

        // Recursively compare next value when equal
        if (versionValue === targetValue) {
          // Target not more specific: version valid.
          if (index >= target.length) return true;

          return compare(version, target, index + 1);
        }

        return versionValue > targetValue;
      };

      return compare(version.split('.'), target.split('.'));
    },

    /**
     * Load drivers list for assigned values.
     */
    loadDrivers() {
      this.$store.dispatch('drivers/loadList');
    },

    /**
     * Load vehicles list for assigned values.
     */
    loadVehicles() {
      this.$store.dispatch('vehicles/loadList');
    },

    /**
     * @param {string} id
     * Scroll inside the modal so that the multiselect dropdown is fully visible
     */
    scrollToElement(id) {
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    },

    updateTeams(updateAction) {
      this.teamId = updateAction.value ?? null;
    },

    /**
     * Save the selected device informations if they are changed and form is valid.
     */
    async submitEditModal() {
      if (!(this.isDeviceChanged && this.isFormValid)) return;

      const device = {
        ...this.device,
        name: this.name,
        teams: this.teamId ? [this.teamId] : [],
      };

      if (this.isModeChanged) {
        device.drive_mode = this.mode.name;

        /** @type {?import('@/store/devices').TripFilter} */
        let newTripFilter;

        if (this.mode.param != null) {
          switch (this.mode.name) {
            case DeviceMode.SEQUENCE:
              newTripFilter = { block_id: this.mode.param };
              break;
            default:
              newTripFilter = { trip_id: this.mode.param };
          }
        } else {
          newTripFilter = { trip_id: null };
        }

        if (
          !device.trip_filter ||
          !Object.keys(newTripFilter).every(k => newTripFilter[k] === device.trip_filter[k])
        ) {
          device.trip_filter = newTripFilter;
        }
      }

      if (this.device.assigned_driver_id !== this.assignedDriverId) {
        device.assigned_driver_id = this.assignedDriverId;
      }

      if (this.device.assigned_vehicle_id !== this.assignedVehicleId) {
        device.assigned_vehicle_id = this.assignedVehicleId;
      }

      if (this.simulation !== !!this.device.simulation_mode) {
        device.simulation_mode = this.simulation;
      }

      await this.$store.dispatch('devices/put', device);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.modal-device-edit {
  .modal__body {
    overflow-x: initial;

    .form-group {
      max-width: 540px;
    }
  }

  .v-label {
    font-weight: $font-weight-semi-bold;
  }
}
</style>

<i18n locale="fr">
{
  "mode": {
    "title": "Mode",
    "deadMileage": "Haut-le-Pied",
    "sequence": "Service séquentiel",
    "trip": "Course"
  },
  "assigned_driver": "Conducteur affecté",
  "assigned_vehicle": "Véhicule affecté",
  "deadRun": "Haut-le-pied",
  "deviceId": "Identifiant de l'appareil",
  "editDevice": "Modifier l'appareil",
  "lastTs": "Dernière mise à jour",
  "modeFilter": "Mission",
  "name": "Nom de l'appareil",
  "routeFilter": "Filtrer une ligne",
  "simulation": "Simulation",
}
</i18n>

<i18n locale="en">
{
  "mode": {
    "title": "Mode",
    "deadMileage": "Dead running",
    "sequence": "Sequential service",
    "trip": "Trip"
  },
  "assigned_driver": "Driver assigned",
  "assigned_vehicle": "Vehicle assigned",
  "deadRun": "Dead running",
  "deviceId": "Device ID",
  "editDevice": "Edit device",
  "lastTs": "Last update",
  "modeFilter": "Assignment",
  "name": "Device name",
  "routeFilter": "Filter a line",
  "simulation": "Simulation",
}
</i18n>

<i18n locale="cz">
{
  "mode": {
    "trip": "Jízda",
    "sequence": "Sekvenční jízda",
    "deadMileage": "Na cestě z/do depa",
    "title": "Režim"
  },
  "deviceId": "ID zařízení",
  "lastTs": "Poslední aktualizace",
  "name": "Název zařízení",
  "routeFilter": "Filtrovat linku",
  "modeFilter": "Jízda",
  "deadRun": "Na cestě z/do depa",
  "editDevice": "Upravit název zařízení / přiřazený tým",
  "simulation": "Simulace",
  "assigned_driver": "Přiřazený řidič",
  "assigned_vehicle": "Přiřazené vozidlo"
}
</i18n>

<i18n locale="de">
{
  "mode": {
    "trip": "Fahrt",
    "sequence": "Fahrt gemäß der Reihenfolge",
    "deadMileage": "Fahrt zum oder vom Busdepot",
    "title": "Modus"
  },
  "deviceId": "Geräte-ID",
  "lastTs": "Letzte Aktualisierung",
  "name": "Gerätename",
  "routeFilter": "Strecke filtern",
  "modeFilter": "Fahrt",
  "deadRun": "Fahrt zum oder vom Busdepot",
  "editDevice": "Gerätename/Teamzuordnung ändern",
  "simulation": "Simulation",
  "assigned_driver": "Zugeordneter Fahrer",
  "assigned_vehicle": "Zugeordnetes Fahrzeug"
}
</i18n>

<i18n locale="es">
{
  "mode": {
    "trip": "Servicio",
    "sequence": "Servicio secuencial",
    "deadMileage": "Sin pasajeros",
    "title": "Modo"
  },
  "deviceId": "ID de dispositivo",
  "lastTs": "Última actualización",
  "name": "Nombre de dispositivo",
  "routeFilter": "Filtrar una línea",
  "modeFilter": "Servicio",
  "deadRun": "Sin pasajeros",
  "editDevice": "Modificar nombre de dispositivo/asignación de equipo",
  "simulation": "Simulación",
  "assigned_driver": "Conductor asignado",
  "assigned_vehicle": "Vehículo asignado"
}
</i18n>

<i18n locale="it">
{
  "mode": {
    "trip": "Servizio",
    "sequence": "Servizio successivo",
    "deadMileage": "In tragitto da o verso il deposito",
    "title": "Modalità"
  },
  "deviceId": "ID dispositivo",
  "lastTs": "Ultimo aggiornamento",
  "name": "Nome del dispositivo",
  "routeFilter": "Filtra una linea",
  "modeFilter": "Servizio",
  "deadRun": "In tragitto da o verso il deposito",
  "editDevice": "Modifica nome del dispositivo/assegnazione del team",
  "simulation": "Simulazione",
  "assigned_driver": "Autista assegnato",
  "assigned_vehicle": "Veicolo assegnato"
}
</i18n>

<i18n locale="pl">
{
  "mode": {
    "trip": "Usługa",
    "sequence": "Usługa sekwencyjna",
    "deadMileage": "Przejazdy bez pasażerów",
    "title": "Tryb"
  },
  "deviceId": "Ident. urządzenia",
  "lastTs": "Ostatnia aktualizacja",
  "name": "Nazwa urządzenia",
  "routeFilter": "Filtruj linię",
  "modeFilter": "Usługa",
  "deadRun": "Przejazdy bez pasażerów",
  "editDevice": "Zmień nazwę urządzenia/przydział zespołu",
  "simulation": "Symulacja",
  "assigned_driver": "Przydzielony kierowca",
  "assigned_vehicle": "Przydzielony pojazd"
}
</i18n>
