<script setup lang="ts">
import { computed, ref, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { type GenerateCrumbName } from '@/router';

const i18n = useI18n();
const route = useRoute();
const store = useStore();

const crumbName: Ref<string> = ref();

const levels = computed(() => {
  const levels = [
    {
      routeName: route.name,
      crumbName: crumbName.value || i18n.t(`breadcrumb.${route.name as string}`),
    },
  ];

  if (route.meta?.parent) {
    levels.unshift({
      routeName: route.meta.parent as string,
      crumbName: i18n.t(`breadcrumb.${route.meta.parent as string}`),
    });
  }

  return levels;
});

watch(route, () => {
  crumbName.value = null;
  loadCrumbName();
}, { immediate: true });

async function loadCrumbName() {
  const generateCrumbName = route.meta.crumbName as GenerateCrumbName
  if (generateCrumbName) {
    await generateCrumbName(crumbName, route, store)
  }
}
</script>

<template>
  <div class="breadcrumbs">
    <template v-for="level in levels" :key="level.routeName">
      <font-awesome-icon icon="fa-chevron-right" />

      <router-link v-if="route.name !== level.routeName" :to="{ name: level.routeName }">
        {{ level.crumbName }}
      </router-link>
      <span v-else>{{ level.crumbName }}</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.breadcrumbs {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-left: 5px;

  > a:hover {
    text-decoration: underline;
  }

  > .fa-chevron-right {
    color: $text-neutral;
    padding: 0 10px;
  }
}
</style>
