import { baseAppUrl } from '@/libs/helpers/url';

export const API_URL_QR = '/api/v2/qrcode';

/**
 * Get the QrData for a screen stop
 * @param {string} groupId
 * @param {string} stopId
 * @param {string} stopName
 * @return {QRData}
 */
export const getScreenQRData = (groupId, stopId, stopName) => {
  return {
    content: encodeURI(`${baseAppUrl}/screen/#/${groupId}/stop-detailed/${stopId}`),
    filename: `${stopName}(${stopId})`,
    label: `${stopName} (id : ${stopId})`,
  };
};

/**
 * @typedef {Object} QRData
 * @property {string} content
 * @property {string} filename
 * @property {string} label
 */
