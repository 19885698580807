import dayjs from 'dayjs';
import { ServiceExceptionType, daysOfWeek } from '@/store/gtfs';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import store from '@/store';

export default {
  /**
   * Return the inactive dates for a trip from the service Id
   * @param {string} serviceId
   * @param {string} gtfsId
   * @return {Promise}
   */
  async getInactiveDatesForATrip(serviceId, gtfsId) {
    /** @type {{[serviceId: string]: import('@/store/gtfs').Service}} */
    const calendar = await store.dispatch('gtfs/getCalendar', {
      gtfsId,
    });
    /** @type {Array<import('@/store/gtfs').ServiceException>} */
    const calendarDates = await store.dispatch('gtfs/getCalendarDates', {
      gtfsId,
    });
    /** @type {{[gtfsDate: string]: boolean}} */
    const exceptionsRemoved = {};
    /** @type {{[gtfsDate: string]: boolean}} */
    const exceptionsAdd = {};

    const inactiveDates = {};

    calendarDates.forEach(exception => {
      if (exception.service_id === serviceId && exception.exception_type === ServiceExceptionType.REMOVE) {
        exceptionsRemoved[exception.date] = true;
      }

      if (exception.service_id === serviceId && exception.exception_type === ServiceExceptionType.ADD) {
        exceptionsAdd[exception.date] = true;
      }
    });
    if (calendar[serviceId]?.end_date) {
      inactiveDates.maxDate = dateGtfsFormatToObj(calendar[serviceId].end_date);
    }
    if (calendar[serviceId]?.start_date) {
      inactiveDates.minDate = dateGtfsFormatToObj(calendar[serviceId].start_date);
    }

    // 1 year limit before and after today's date
    const todayPlusOneYear = dayjs(new Date()).add(1, 'year').toDate();
    const todayMinusOneYear = dayjs(new Date()).subtract(1, 'year').toDate();
    if (!inactiveDates.maxDate || inactiveDates.maxDate.getTime() > todayPlusOneYear.getTime()) {
      inactiveDates.maxDate = todayPlusOneYear;
    }
    if (!inactiveDates.minDate || inactiveDates.minDate.getTime() < todayMinusOneYear.getTime()) {
      inactiveDates.minDate = todayMinusOneYear;
    }

    inactiveDates.isDateInactive = date => {
      const dateGtfs = dateObjToGtfsFormat(date);
      if (exceptionsRemoved[dateGtfs]) return true;
      if (exceptionsAdd[dateGtfs]) return false;
      return !calendar[serviceId] || !calendar[serviceId][daysOfWeek[date.getDay()]];
    };
    return inactiveDates;
  },

  async isTripActiveOnDate(gtfsId, serviceId, date) {
    const inactiveDates = await this.getInactiveDatesForATrip(serviceId, gtfsId);
    const isInActivePeriod =
      inactiveDates.minDate?.getTime() < date.getTime() && date.getTime() < inactiveDates.maxDate?.getTime();
    return !inactiveDates.isDateInactive(date) && isInActivePeriod;
  },
};
