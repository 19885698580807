<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import Tag from '@/components/ui/Tag.vue';

/** @enum {string} */
const RegulationType = {
  DELAY: 'delay',
  CANCELED: 'canceled',
  STOP_SKIPPED: 'stopSkipped',
};

/** @type {Vue.Propotions<import('@/api').TripUpdates>} */
const props = defineProps({
  regulation: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();

/** @return {Array<import('@/components/ui/Tag.vue').TagProperties>} */
const regulationsInfo = computed(() => {
  const info = [];

  if (props.regulation.canceled) {
    info.push({
      text: t(`regulation.${RegulationType.CANCELED}`),
      color: '#a41414', // $danger-dark
      background: '#fbdddd', //$light-red
      icon: 'fa-xmark',
    });
  }

  if (props.regulation.skipped_stop_sequences?.length > 0) {
    info.push({
      text: t(`regulation.${RegulationType.STOP_SKIPPED}`, {
        count: props.regulation.skipped_stop_sequences.length,
      }),
      color: '#f99c49', // $warn
      background: '#feebdb', //$flat-orange
      icon: 'fa-arrows-split-up-and-left',
    });
  }

  if (props.regulation.delay) {
    info.push({
      text: props.regulation.delay > 0 ? t(`regulation.${RegulationType.DELAY}`) : t(`regulation.advance`),
      color: '#f99c49', // $warn
      background: '#feebdb', //$flat-orange
      icon: 'fa-clock',
    });
  }

  return info;
});
</script>

<template>
  <div class="regulation-status-cell">
    <Tag
      v-for="(info, index) in regulationsInfo"
      :key="index"
      :text="info.text"
      :color="info.color"
      :background="info.background"
      :icon="info.icon"
      class="regulation-tag"
    />
  </div>
</template>

<style scoped>
.regulation-status-cell {
  display: flex;
  gap: 8px;
}
</style>
