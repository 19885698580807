import { toCSV } from './csv';
import { dateGtfsFormatToObj, secondsToStr, timestampMidnight } from '@/libs/helpers/dates';
import i18n from '@/i18n';
import { GroupRoute } from './routing';

const { t, d } = i18n.global;

/** @type {Array<string>} */
export const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

/** @enum {string} */
export const GroupBy = Object.freeze({
  DAY: 'day',
  DEVICE: 'device',
  MONTH: 'month',
  ROUTE: 'route',
  SERVICE: 'service',
  STOP: 'stop',
  TEAM: 'team',
  TRIP: 'trip',
  WEEK: 'week',
});

export const ReportPages = [
  GroupRoute.REPORTING_TRIP_KM,
  GroupRoute.REPORTING_PUNCTUALITY,
  GroupRoute.REPORTING_TRAVEL_TIME,
  GroupRoute.REPORTING_TRIP_TRACKING,
  GroupRoute.REPORTING_PASSENGER_COUNTS,
  GroupRoute.REPORTING_CONNECTED_DEVICES,
  GroupRoute.REPORTING_PASSENGERS_APP,
];

export const ReportsRouteList = [...ReportPages, ...[GroupRoute.REPORTING_OLD_PUNCTUALITY]];

/** @type {{[key in GroupBy]: Array<string>}} */
export const ColumnsGroupBy = {
  [GroupBy.DAY]: ['date'],
  [GroupBy.DEVICE]: ['id', 'name'],
  [GroupBy.MONTH]: ['date'],
  [GroupBy.ROUTE]: ['id', 'gtfs', 'routeShortName', 'routeLongName'],
  [GroupBy.SERVICE]: ['id'],
  [GroupBy.STOP]: ['id', 'name'],
  [GroupBy.TEAM]: ['name'],
  [GroupBy.TRIP]: ['id', 'formattedTripName'],
  [GroupBy.WEEK]: ['week'],
};

/** @enum {string} */
export const ReportType = Object.freeze({
  GRAPH: 'graph',
  MAP: 'map',
  TABLE: 'table',
});

/**
@typedef Cell
@property {string|number} value
@property {string} [title]
*/

// Legacy code from ReportsPunctuality, moved to reports.js in order to be used in NewReportsPunctuality
export const PunctualityReportHelper = {
  downloadFormatedReportPunctuality(groupedData, dlLink, categories, groupBy = GroupBy.DAY) {
    let downloadLink = dlLink;
    if (downloadLink != null) {
      URL.revokeObjectURL(downloadLink);

      // Needed to revoke URL
      downloadLink = null;
    }

    const categoriesColumns = this.categoriesColumns(categories);
    const sortedRows = this.sortedRows(groupedData);

    // Get Required columns
    const columns = ColumnsGroupBy[groupBy].map(
      column => /** @type {string} */ (t(`punctuality.columns.${column}`))
    );
    columns.push(...categoriesColumns.map(({ title }) => title));
    columns.push(/** @type {string} */ (t('punctuality.columns.average')));
    columns.push(/** @type {string} */ (t('punctuality.columns.rate')));

    const data = [
      columns,
      ...sortedRows.map(i => {
        const data = groupedData[i];

        const row = ColumnsGroupBy[groupBy].map(column => data[column]);

        row.push(...categoriesColumns.map((_, i) => data.categories[i] || 0));
        row.push(this.formatDelay(Math.round(data.average / data.count / 60)));
        const val = Math.round((data.rate / data.count) * 100);
        row.push(`${val}%`);

        return row;
      }),
    ];

    downloadLink = toCSV(data);
    return downloadLink;
  },
  downloadRawReportPunctuality(historyStopTimes, dlLink) {
    // do not process if no hst or gtfsData empty
    if (historyStopTimes.length === 0) return null;

    let downloadRawDataLink = dlLink;

    // Get additional datas
    const formatedStopTimes = [];
    historyStopTimes.forEach(hst => {
      const newHst = {
        _id: hst._id,
        gtfs_id: hst.gtfs_id,
        trip_id: hst.trip_id,
        trip_short_name: hst.tripShortName,
        route_id: hst.routeShortName,
        route_long_name: hst.routeLongName,
        block_id: hst.blockId,
        start_date: hst.start_date,
        stop_id: hst.stop_id,
        stop_name: hst.stopName,
        stop_sequence: hst.stop_sequence,
        event: hst.event,
        device_id: hst.device_id,
        device_name: hst.deviceName,
        delay: hst.calculatedDelay,
        recorded_departure: d(hst.ts * 1000, 'datetimeLong'),
        theoretical_departure: d(hst.theoreticalDepartureTime * 1000, 'datetimeLong'),
        team_id: hst.teamName,
      };
      formatedStopTimes.push(newHst);
    });

    // get columns keys
    const columns = Object.keys(formatedStopTimes[0]);

    // Make a copy of historyStopTimes object
    const dataUnformated = JSON.parse(JSON.stringify(formatedStopTimes));
    // Order raw data by gtfs_id, trip_id, start_date
    const formatedData = dataUnformated
      .sort((a, b) => (a.gtfs_id < b.gtfs_id ? -1 : 1))
      .sort((a, b) => {
        if (a.trip_id === b.trip_id) {
          return a.start_date < b.start_date ? -1 : 1;
        }
        if (a.gtfs_id === b.gtfs_id) {
          return a.trip_id < b.trip_id ? -1 : 1;
        }
        return a.gtfs_id < b.gtfs_id ? -1 : 1;
      })
      .map(historyStopTime => {
        return Object.values(historyStopTime);
      });
    const data = [columns, ...formatedData];

    // Create new object url

    downloadRawDataLink = toCSV(data);

    return downloadRawDataLink;
  },

  formatDelay(val) {
    const sign = val < 0 ? '-' : '+';
    return `${sign}${secondsToStr(Math.abs(val) * 60)}`;
  },

  sortedRows(groupedData) {
    const arr = Object.keys(groupedData);
    arr.sort((a, b) => (a > b ? 1 : -1));

    return arr;
  },

  /** @return {Array<{ title: string, type: string }>} */
  categoriesColumns(categories) {
    const columns = [];

    categories.cats.concat([Infinity]).forEach((cat, i) => {
      const col = {
        title: '',
        type: 'on-time',
      };

      const i18nColumn = cat <= 0 ? 'early' : 'late';
      const absCat = Math.abs(cat);
      const prevCat = Math.abs(categories.cats[i - 1]);

      let catFormatedTime = t('punctuality.columns.min', [absCat / 60]);
      let prevCatFormatedTime = t('punctuality.columns.min', [prevCat / 60]);

      // choose to show second if there is one or more.
      if (absCat % 60 !== 0) {
        catFormatedTime = t('punctuality.columns.minSec', [Math.floor(absCat / 60), absCat % 60]);
      }
      if (prevCat % 60 !== 0) {
        prevCatFormatedTime = t('punctuality.columns.minSec', [Math.floor(prevCat / 60), prevCat % 60]);
      }
      if (i === 0 || i >= categories.cats.length) {
        catFormatedTime = i18nColumn === 'early' ? catFormatedTime : prevCatFormatedTime;
        col.title = /** @type {string} */ (t(`punctuality.columns.${i18nColumn}.0`, [catFormatedTime]));
      } else {
        col.title = /** @type {string} */ (
          t(`punctuality.columns.${i18nColumn}.1`, [prevCatFormatedTime, catFormatedTime])
        );
      }

      if (categories.bounds.down && i < categories.bounds.down) {
        col.type = 'early';
      } else if (categories.bounds.up && i > categories.bounds.up) {
        col.type = 'late';
      }

      columns.push(col);
    });
    return columns;
  },
  /**
   * Get route detailed name by historyStopTime
   */
  getAdditionalStopTimeInfosByGtfs(sth, gtfsData, tz, deviceList = null, teams = null) {
    const result = {
      routeShortName: '-',
      routeLongName: '-',
      blockId: '',
      theoreticalDepartureTime: 0,
      calculatedDelay: 0,
      stopName: '',
      tripShortName: '-',
      deviceName: '-',
      teamName: sth.team_id || '-',
    };
    if (gtfsData[sth.gtfs_id] !== undefined) {
      /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
      const { trips } = gtfsData[sth.gtfs_id];
      /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
      const { routes } = gtfsData[sth.gtfs_id];
      /** @type {{[routeId: string]: import('@/store/gtfs').Stop}} */
      const { stops } = gtfsData[sth.gtfs_id];

      // Trip
      const trip = trips[sth.trip_id];
      result.blockId = trip?.block_id || '-';
      result.tripShortName = trip?.trip_short_name || '-';

      // Route
      const route = routes[trip.route_id];
      result.routeShortName = (route && route.route_short_name) || '-';
      result.routeLongName = (route && route.route_long_name) || '-';

      // Stop
      const stop = stops[sth.stop_id];
      result.stopName = stop.stop_name || '-';

      // DeviceName
      if (deviceList) {
        result.deviceName = deviceList[sth.device_id]?.name || '-';
      }

      // TeamName
      if (teams) {
        const searchedTeam = teams.find(team => team.team_id === sth.team_id);
        if (searchedTeam) result.teamName = searchedTeam.name;
      }

      // get stopTime to get theoreticalDepartureTime
      const stopTime = trip.stop_times.find(
        st => st.stop_sequence === sth.stop_sequence && st.stop_id === sth.stop_id
      );

      let theoreticalDepartureTime;
      if (stopTime) {
        const midnight = timestampMidnight(dateGtfsFormatToObj(sth.start_date), tz);
        theoreticalDepartureTime = stopTime.departure_time + midnight;
      }
      if (theoreticalDepartureTime) {
        result.theoreticalDepartureTime = theoreticalDepartureTime;

        result.calculatedDelay = sth.ts - theoreticalDepartureTime;
      }
    }
    return result;
  },
};
