/**
 * Convert an array to a fake map object.
 * @template T
 * @param {Array<T>} arr - Array<T>
 * @param {string} prop - Unique property of <T>
 * @return {Object<string, T>} Map<string, T>
 */
function arrToFakeMap(arr, prop) {
  return arr.reduce((acc, val) => {
    acc[val[prop]] = val;
    return acc;
  }, {});
}

/**
 * Convert an array to an object.
 * @template T
 * @param {Array<T>} arr
 * @param {string} [prop] - Required when <T> is an Object, set the property to extract as key.
 * @return {Object<string, boolean>} Set<string>
 */
function arrToObj(arr, prop) {
  return arr.reduce((acc, val) => {
    const key = prop ? val[prop] : val;
    acc[key] = true;

    return acc;
  }, {});
}

/**
 * Compare 2 object and return wether they have the same keys or not
 * @param {Object} firstObject
 * @param {Object} secondObject
 * @returns
 */
function compareObjectKeys(firstObject, secondObject) {
  const firstKeys = Object.keys(firstObject).sort();
  const secondKeys = Object.keys(secondObject).sort();
  return JSON.stringify(firstKeys) === JSON.stringify(secondKeys);
}

/**
 * Filter a list to remove duplicates values based on an object key.
 * Keeps the initial order of the array
 * @param {Array} list
 * @param {string} key
 * @returns
 */
function filterSameValueInList(list, key) {
  return list.filter((v, i, a) => a.findIndex(v2 => v2[key] === v[key]) === i);
}

/**
 * Convert a stream of state mutations into a stream of full state.
 *
 * It also merge events having the same timestamp and trip.
 *
 * @param {Array<import("@/store/devices").Event>} eventStream Array of (sorted) state mutations
 * @param {import("@/store/devices").Device} [initState] Initial state
 * @return {Array<import("@/store/devices").Event>}
 */
function eventStreamToStateStream(eventStream, initState) {
  /** @type {import("@/store/devices").Event} */
  const state = initState || { device_id: null, ts: 0 };

  /** @type {Array<import("@/store/devices").Event>} */
  const stateStream = [];

  for (const event of eventStream) {
    Object.assign(state, event);
    delete state.update_info;

    const lastState = stateStream[stateStream.length - 1];

    if (
      lastState !== undefined &&
      lastState.ts === state.ts &&
      lastState.trip?.trip_id === state.trip?.trip_id &&
      lastState.trip?.start_date === state.trip?.start_date
    ) {
      Object.assign(lastState, state);
    } else {
      stateStream.push({ ...state });
    }
  }

  return stateStream;
}

export { arrToFakeMap, arrToObj, compareObjectKeys, eventStreamToStateStream, filterSameValueInList };
