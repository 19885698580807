<template>
  <div class="action-cell">
    <section v-if="editMode">
      <div class="action-buttons-inline-edition">
        <div class="btn-container">
          <Btn
            type="secondary"
            class="action-cell-for-rows__cancel"
            :smaller="true"
            @click="$emit('switchOffEditionMode', object)"
          >
            {{ $t('cancel') }}
          </Btn>
          <Btn
            type="primary"
            class="action-cell-for-rows-submit"
            :smaller="true"
            @click="$emit('save', object)"
          >
            {{ $t('save') }}
          </Btn>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="action-buttons">
        <Btn
          v-if="actions.includes('programAlert')"
          type="primary"
          :smaller="true"
          :title="$t('programAlert')"
          @click="$emit('programAlert', object)"
        >
          <span class="action-cell__publish-btn">
            <font-awesome-icon icon="fa-paper-plane" />
            {{ $t('programAlert') }}
          </span>
        </Btn>

        <Btn
          v-if="actions.includes('publish')"
          type="primary"
          :smaller="true"
          :title="$t('publish')"
          @click="$emit('publish', object)"
        >
          <span class="action-cell__publish-btn">
            <font-awesome-icon icon="fa-arrow-circle-up" />
            {{ $t('publish') }}
          </span>
        </Btn>
        <Btn
          v-if="actions.includes('cancelPublication')"
          type="primary"
          :smaller="true"
          :title="$t('cancelPublication')"
          @click="$emit('cancelPublication')"
        >
          <span class="action-cell__publish-btn">
            <font-awesome-icon icon="fa-times" />
            {{ $t('cancelPublication') }}
          </span>
        </Btn>
        <Btn
          v-if="actions.includes('view')"
          :title="$t('view')"
          type="icon-only"
          @click="$emit('view', object)"
        >
          <font-awesome-icon icon="fa-eye" />
        </Btn>
        <Btn
          v-if="actions.includes('edit')"
          type="icon-only"
          :title="$t('edit')"
          @click="$emit('edit', object)"
        >
          <font-awesome-icon icon="fa-pencil" />
        </Btn>
        <Btn
          v-if="actions.includes('copy')"
          type="icon-only"
          :title="$t('copy')"
          @click="$emit('copy', object)"
        >
          <font-awesome-icon icon="fa-copy" />
        </Btn>
        <Btn
          v-if="actions.includes('push')"
          :disabled="!active"
          type="icon-only"
          :title="$t('push')"
          @click="$emit('push', object)"
        >
          <font-awesome-icon icon="fa-paper-plane" />
        </Btn>
        <Btn
          v-if="actions.includes('readNewMessage')"
          type="icon-only"
          :title="$t('markAsUnread')"
          @click="$emit('readNewMessage', object)"
        >
          <font-awesome-icon icon="fa-envelope" />
        </Btn>
        <Btn
          v-if="actions.includes('readMessage')"
          type="icon-only"
          :title="$t('markAsRead')"
          @click="$emit('readMessage', object)"
        >
          <font-awesome-icon icon="fa-envelope-open" />
        </Btn>
        <Btn
          v-if="actions.includes('reply')"
          type="icon-only"
          :title="$t('reply')"
          @click="$emit('reply', object)"
        >
          <font-awesome-icon icon="fa-reply" />
        </Btn>
        <Btn
          v-if="actions.includes('download')"
          type="icon-only"
          :title="$t('download')"
          @click="$emit('download', object)"
        >
          <font-awesome-icon icon="fa-download" />
        </Btn>
        <Btn
          v-if="actions.includes('password')"
          type="icon-only"
          :title="$t('changePassword')"
          @click="$emit('password', object)"
        >
          <font-awesome-icon icon="fa-lock" />
        </Btn>
        <Btn
          v-if="actions.includes('archive')"
          type="icon-only"
          :title="$t('archive')"
          @click="$emit('archive', object)"
        >
          <font-awesome-icon icon="fa-box-archive" />
        </Btn>
        <Btn
          v-if="actions.includes('restore')"
          type="icon-only"
          :title="$t('restore')"
          @click="$emit('restore', object)"
        >
          <font-awesome-icon icon="fa-boxes-packing" />
        </Btn>
        <Btn
          v-if="actions.includes('delete')"
          class="action-cell__delete-btn danger"
          type="icon-only"
          :title="$t('delete')"
          @click="$emit('delete', object)"
        >
          <font-awesome-icon icon="fa-solid fa-trash" />
        </Btn>
      </div>
    </section>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'ActionCellV2',

  components: {
    Btn,
  },

  props: {
    actions: {
      required: true,
      type: Array,
    },

    // for passenger info page
    active: {
      default: false,
      type: Boolean,
    },

    editMode: {
      default: false,
      type: Boolean,
    },

    object: {
      required: true,
      type: Object,
    },
  },

  emits: [
    'archive',
    'cancelPublication',
    'copy',
    'delete',
    'download',
    'edit',
    'password',
    'programAlert',
    'publish',
    'push',
    'switchOffEditionMode',
    'save',
    'reply',
    'readNewMessage',
    'readMessage',
    'restore',
    'view',
  ],
};
</script>

<style lang="scss">
.action-cell {
  max-height: 52px;
  margin-left: -10px;

  .ui-btn.smaller {
    padding: 1px 5px;
  }

  section {
    display: flex;
    flex-wrap: wrap;

    div > div {
      display: inline-block;
    }
  }

  .ui-btn:first-child {
    margin-left: 10px;
  }

  &__publish-btn {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 12px;
  }

  .ui-btn:not(.icon-only) svg {
    margin-right: 3px;
    font-size: 12px;
  }

  .action-cell__delete-btn {
    border: $danger;
  }
}
</style>

<i18n locale="fr">
{
  "programAlert": "Programmer l'info voyageurs",
  "cancelPublication": "Annuler la publication",
  "copy": "Dupliquer",
  "changePassword": "Changer le mot de passe",
  "edit": "Modifier les informations",
  "push": "Envoyer une notification",
  "reply": "Répondre",
  "markAsRead": "Marquer comme non lu",
  "markAsUnread": "Marquer comme lu",
  "publish": "Publier",
  "view": "Voir",
  "delete": "Supprimer"
}
</i18n>

<i18n locale="en">
{
  "programAlert": "Schedule Passengers Info",
  "cancelPublication": "Cancel publication",
  "copy": "Duplicate",
  "changePassword": "Change password",
  "edit": "Edit",
  "push": "Send a push notification",
  "reply": "Reply",
  "markAsRead": "Mark as unread",
  "markAsUnread": "Mark as read",
  "publish": "Publish",
  "view": "View",
  "delete": "Delete"
}
</i18n>
