<template>
  <div class="layout">
    <NavBarLeft />
    <div class="layout__right">
      <NavBarTop />
      <div class="layout__bottom">
        <router-view v-if="$store.getters.group?._id" />
        <ModalUrgency
          v-if="newUrgencies.length > 0 && urgenciesPermission"
          :urgencies="newUrgencies"
          @handleAlerts="closeUrgencies"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Permission } from '@/auth';
import NavBarLeft from '@/components/layout/NavBarLeft.vue';
import NavBarTop from '@/components/layout/NavBarTop/index.vue';
import ModalUrgency from '@/components/common/ModalUrgency.vue';

export default {
  name: 'Group',

  components: {
    NavBarLeft,
    NavBarTop,
    ModalUrgency,
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },
  },

  computed: {
    /** @return {Array<import('@/components/common/ModalUrgency.vue').Urgency>}*/
    newUrgencies() {
      const urgencies = Object.values(this.$store.state.urgencies);
      if (!urgencies.length) return [];
      return urgencies.filter(u => u.group_id === this.groupId && u.localStatus === 'open') || [];
    },

    /** @return {boolean} */
    urgenciesPermission() {
      return this.$store.getters.hasPermission(Permission.HANDLE_URGENCIES);
    },
  },

  methods: {
    closeUrgencies() {
      const urgencies = [...this.newUrgencies];
      urgencies.forEach(u => {
        u.localStatus = 'closed';
      });
      this.$store.commit('updateUrgencies', urgencies);
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * layout
 *   __right
 *   __bottom
 */
.layout {
  display: flex;
  height: 100%;

  &__right {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
    height: 100%;
  }

  &__bottom {
    flex: 1;
    overflow: auto;
  }
}
</style>
