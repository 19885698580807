<template>
  <div class="reports">
    <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />
    <NoReportData v-else-if="!hasData" />
    <div v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('totalPassengersAppSessions')" :number="totalSessions" />
        <NumberDisplay :title="$t('averagePassengersAppSessions')" :number="averageSessions" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :categories="dayChart.categories"
          :series="dayChart.series"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import api from '@/api';
import { dateGtfsFormatToObj, dateObjToGtfsFormat } from '@/libs/helpers/dates';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import NoReportData from '@/components/ui/NoReportData.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import { toCSV } from '@/libs/csv';

export default {
  name: 'ReportsPassengersApp',

  components: {
    AnimatedDots,
    NoReportData,
    BarChart3,
    NumberDisplay,
  },

  props: {
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },

    /** @type {Object} */
    chartHeight: {
      type: Number,
      default: 620,
    },

    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 1200,
    },
  },

  emits: ['downloadLink', 'hasData'],

  data: () => ({
    /** @type {Array<DeviceData>} */
    deviceData: [],

    dayChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },

    /** @type {Boolean} */
    hasData: false,

    /** @type {Boolean} */
    isLoading: false,
  }),

  computed: {
    /** @return {number} */
    averageSessions() {
      if (!this.deviceData.length) return 0;
      return Math.round(
        this.deviceData.reduce((acc, event) => acc + event.sessionCount, 0) / this.deviceData.length
      );
    },

    /** @return {number} */
    totalSessions() {
      return this.deviceData.reduce((acc, event) => acc + event.sessionCount, 0);
    },

    downloadLink() {
      const data = [
        ['Date', 'Sessions'],
        ...this.deviceData.map(event => [
          dayjs(new Date(event.ts * 1000)).format('DD/MM/YYYY'),
          event.sessionCount,
        ]),
      ];

      return toCSV(data);
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },

    downloadLink() {
      this.$emit('downloadLink', this.downloadLink);
    },
  },

  methods: {
    async loadData() {
      this.isLoading = true;

      /** @type {Array<DeviceData>} */
      this.deviceData = [];

      const startDate = new Date(this.dateInterval.start * 1000);
      const endDate = new Date(this.dateInterval.end * 1000);
      const datesFormated = [startDate, endDate].map(date => {
        return dateObjToGtfsFormat(date);
      });

      const data = await api.getAppEvents(this.$store.getters.group._id, ...datesFormated);
      if (data.length) this.hasData = true;
      this.$emit('hasData', true);
      // merge count values from same day in one(random issue from api) op#929
      const result = data.reduce((acc, item) => {
        acc[item.date] = (acc[item.date] || 0) + item.count;
        return acc;
      }, {});

      Object.keys(result).forEach(dateIndex => {
        const date = dateGtfsFormatToObj(dateIndex);
        this.deviceData.push({ sessionCount: result[dateIndex], ts: date.getTime() / 1000 });
      });
      for (let i = 0; this.dateInterval.start + i <= this.dateInterval.end; i += 86400) {
        const emptyDay = !this.deviceData.filter(e => {
          return e.ts === this.dateInterval.start + i;
        }).length;

        if (emptyDay) this.deviceData.push({ sessionCount: 0, ts: this.dateInterval.start + i });
      }

      this.deviceData.sort((a, b) => a.ts - b.ts);

      this.setupChart();

      this.isLoading = false;
    },

    async setupChart() {
      this.dayChart.data = this.deviceData;

      this.dayChart.categories = this.deviceData.map(e =>
        this.$d(new Date(e.ts * 1000), 'dayMonth').replace(/\./g, '')
      );

      this.dayChart.series = [
        {
          color: () => '#00B871', // $primary-light
          data: this.deviceData.map(e => e.sessionCount),
        },
      ];

      const { data } = this.dayChart;

      this.dayChart.options = {
        tooltip: {
          custom: ({ dataPointIndex, w }) => {
            const sessions = data[dataPointIndex + w.config.page * w.config.barsPerScreen].sessionCount;

            return `
              <ul class="apexcharts-custom-tooltip">
                <li>${sessions} Session${sessions > 1 ? 's' : ''}</li>
              </ul>
            `;
          },
        },
      };
    },
  },
};

/**
 * @typedef {{[column: string]: string}} RowInfos
 */

/**
 * @typedef {Object} DeviceData
 * @property {number} sessionCount
 * @property {number} ts
 */

/**
 * @typedef {Object} RowResults
 * @property {string} id
 * @property {number} sessionCount
 */
</script>

<i18n locale="fr">
{
  "totalPassengersAppSessions": "Total des sessions",
  "averagePassengersAppSessions": "Moyenne des sessions par jour",
  "chartDayTitle": "Sessions voyageur par jour",
  "yaxisTitle": "Sessions",
}
</i18n>

<i18n locale="en">
{
  "totalPassengersAppSessions": "Total of passenger app sessions",
  "averagePassengersAppSessions": "Average of passenger app sessions per day",
  "chartDayTitle": "Passenger app sessions per day",
  "yaxisTitle": "Sessions",
}
</i18n>
