<template>
  <div class="retry-toast">
    <span>{{ text }}</span>
    <Btn class="retry-toast__button" type="danger" @click="$emit('toastRetry')">
      {{ $t('retry') }}
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  components: {
    Btn,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
  },
  emits: ['toastRetry'],
};
</script>
<style lang="scss">
.retry-toast {
  display: flex;
  flex-direction: column;

  &__button {
    margin-top: 8px;
    border: 1px solid $border;
  }
}
</style>
