import { addMethod, string } from 'yup';
import i18n from './i18n';

const ipPartRegex = /(?<!\S)((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\b|\.\b){7}/;
const portPartRegex = /(?::\d{0,5})\b/;
const optionalPortPartRegex = /(?::\d{0,5})?\b/;
const eolPartRegex = /(?!\S)/;

const ipRegex = new RegExp(ipPartRegex.source + eolPartRegex.source);
const ipPortRegex = new RegExp(ipPartRegex.source + portPartRegex.source + eolPartRegex.source);
const ipOptionalPortRegex = new RegExp(
  ipPartRegex.source + optionalPortPartRegex.source + eolPartRegex.source
);

const { t } = i18n.global;

addMethod(string, 'ip', function ip() {
  return this.test('ip-test', function ipTest(value) {
    if (!ipRegex.test(value)) {
      return this.createError({ path: this.path, message: t('validation.ip.message') });
    }
    return true;
  });
});

addMethod(string, 'ipPort', function ipPort() {
  return this.test('ip-port-test', function ipPortTest(value) {
    if (!ipPortRegex.test(value)) {
      return this.createError({ path: this.path, message: t('validation.ipPort.message') });
    }
    return true;
  });
});

addMethod(string, 'ipOptionalPort', function ipOptionalPort() {
  return this.test('ip-optional-port-test', function ipOptionalPortTest(value) {
    if (!ipOptionalPortRegex.test(value)) {
      return this.createError({ path: this.path, message: t('validation.ipOptionalPort.message') });
    }
    return true;
  });
});
