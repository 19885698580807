/**
 * Return true if a value is numeric
 * @param {String | Number} value
 * @returns {boolean}
 */
function isNumeric(value) {
  return !Number.isNaN(Number(value)) && Number.isFinite(Number(value));
}

export { isNumeric };
