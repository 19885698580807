import { GroupBy } from '@/libs/reports';
import i18n from '@/i18n';

/** @enum {import('@/pages/ReportsPage/ReportsMixin').CategoryColumn} */
const CategoriesColumns = {
  DATE: {
    localeKey: 'date',
    apiFieldName: 'date',
  },
  GTFS_ID: {
    localeKey: 'gtfsId',
    apiFieldName: 'gtfs_id',
  },
  GTFS_NAME: {
    localeKey: 'gtfsName',
    apiFieldName: 'gtfs_name',
  },
  ID: {
    localeKey: 'routeId',
    apiFieldName: 'route_id',
  },
  ROUTE_SHORT_NAME: {
    localeKey: 'routeShortName',
    apiFieldName: 'route_short_name',
  },
  ROUTE_LONG_NAME: {
    localeKey: 'routeLongName',
    apiFieldName: 'route_long_name',
  },
  TRACKED_KM: {
    localeKey: 'recordedKm',
    apiFieldName: 'recorded_commercial_km',
    cellValueBuilder(item) {
      return Math.round(item[CategoriesColumns.TRACKED_KM.apiFieldName]);
    },
  },
  THEORICAL_KM: {
    localeKey: 'theoricalKm',
    apiFieldName: 'theoretical_commercial_km',
    cellValueBuilder(item) {
      return Math.round(item[CategoriesColumns.THEORICAL_KM.apiFieldName]);
    },
  },
  PERCENT_KM: {
    localeKey: 'percent',
    apiFieldName: 'percent_of_theoretical_km_recorded',
  },
  UNRELIABLE_KM: {
    localeKey: 'unreliableKm',
    apiFieldName: 'unreliable_km',
    cellValueBuilder(item) {
      return Math.round(item[CategoriesColumns.UNRELIABLE_KM.apiFieldName]);
    },
  },
};

/** @enum {Array<import('@/pages/ReportsPage/ReportsMixin').CategoryColumn>} */
const TableColumns = {
  [GroupBy.DAY]: [
    CategoriesColumns.DATE,
    CategoriesColumns.TRACKED_KM,
    CategoriesColumns.THEORICAL_KM,
    CategoriesColumns.PERCENT_KM,
    CategoriesColumns.UNRELIABLE_KM,
  ],
  [GroupBy.ROUTE]: [
    CategoriesColumns.ID,
    CategoriesColumns.GTFS_ID,
    CategoriesColumns.GTFS_NAME,
    CategoriesColumns.ROUTE_SHORT_NAME,
    CategoriesColumns.ROUTE_LONG_NAME,
    CategoriesColumns.TRACKED_KM,
    CategoriesColumns.THEORICAL_KM,
    CategoriesColumns.PERCENT_KM,
    CategoriesColumns.UNRELIABLE_KM,
  ],
};

export const TripKmReportsHelper = {
  /**
   *
   * @param {number} theoricalKm
   * @param {number} recordedKm
   * @param {number} percent
   * @param {number} unreliableKm
   * @param {string} [gtfsNameOrId]
   * @returns {string}
   */
  createTooltip(theoricalKm, recordedKm, percent, unreliableKm, gtfsNameOrId) {
    const { t } = i18n.global;
    const numberFormatter = new Intl.NumberFormat(i18n.locale);
    return `
              <ul class="apexcharts-custom-tooltip">
                ${gtfsNameOrId ? `<li>${gtfsNameOrId || ''}</li>` : ``}
                <li>${t('dashboard.planned', [numberFormatter.format(theoricalKm)])}</li>
                <li>${t('dashboard.recorded', [numberFormatter.format(recordedKm)])}</li>
                <li>${t('dashboard.percent', [numberFormatter.format(percent)])}</li>
                ${
                  unreliableKm
                    ? `<li class="apexcharts-custom-tooltip__alert-text">${t('dashboard.unreliableKm', [
                        unreliableKm,
                      ])}</li>`
                    : ''
                }
              </ul>
              
    `;
  },

  /**
   * @param {import('@/api').KmStatsGroupByDay | import('@/api').KmStatsGroupByRoute} item
   * @param {'day'|'route'} groupBy
   * @return {TripKMObject}
   */
  getGroupByColumnsInfos(item, groupBy) {
    return TableColumns[groupBy].reduce(
      (acc, currColumn) => ({
        ...acc,
        [currColumn.localeKey]: currColumn.cellValueBuilder
          ? currColumn.cellValueBuilder.call(this, item)
          : item[currColumn.apiFieldName],
      }),
      /** @type {TripKMObject} */ ({})
    );
  },

  /**
   * @param {Array<TripKMObject | null>} data
   * @return {Array<import('@/components/ui/BarChart3.vue').ApexChartSerie>}
   */
  getSeries(data) {
    const seriesData = data.reduce(
      (acc, currentTripKmObject) => {
        if (!currentTripKmObject) {
          acc.recordedValues.push(0);
          acc.diffValues.push(0);
        } else {
          let currentDiffValue;
          let currentRecordedValue;
          const { theoricalKm, recordedKm } = currentTripKmObject;
          if (recordedKm < theoricalKm) {
            currentRecordedValue = recordedKm;
            currentDiffValue = theoricalKm - recordedKm;
          } else {
            currentRecordedValue = 0;
            currentDiffValue = theoricalKm;
          }
          acc.recordedValues.push(currentRecordedValue);
          acc.diffValues.push(currentDiffValue);
        }
        return acc;
      },
      {
        /** @type {Array<number>} */
        recordedValues: [],
        /** @type {Array<number>} */
        diffValues: [],
      }
    );

    return [
      {
        // Recorded km  :
        data: seriesData.recordedValues,
        color: ({ dataPointIndex, w }) => {
          const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen] || 0;
          if (!entry) return '';
          const { percent } = entry;
          if (percent < 50) return '#EB5757'; // $danger
          if (percent <= 80) return '#F99C49'; // $warn
          return '#00B871'; // $primary-light
        },
      },
      {
        // Diff between theoricalKm and recorded km (grey zone in the bar):
        data: seriesData.diffValues,
        color: ({ dataPointIndex, w }) => {
          const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen] || 0;
          if (!entry) return '';
          const { theoricalKm, recordedKm } = entry;
          // $text-neutral : $primary-light - switch colors of bottom and top bars when recordedKm exceeds theoricalKm
          return recordedKm < theoricalKm ? '#B3B3B3' : '#00B871';
        },
      },
    ];
  },
};

/**
 * @typedef {Object} TripKMObject
 * @property {string} [date]
 * @property {string} [gtfsId]
 * @property {string} [gtfsName]
 * @property {string} [routeId]
 * @property {string} [routeShortName]
 * @property {string} [routeLongName]
 * @property {number} recordedKm
 * @property {number} theoricalKm
 * @property {number} percent
 * @property {number} unreliableKm
 */
