import { compareObjectKeys } from '@/libs/helpers/objects';

export class DatagridLocalStorageHelper {
  /**
   * Construct datagrid helper
   * @param {Object} args
   * @param {string} [args.groupId]
   * @param {string} [args.localStorageKey]
   * @param {string} [args.propertyKey]
   * @param {{[key: string]: {[key: string]:boolean}}} [args.filters]
   * @memberof DatagridLocalStorageHelper
   */
  constructor({ groupId, localStorageKey, propertyKey, filters }) {
    this.groupId = groupId;
    this.localStorageKey = localStorageKey;
    this.propertyKey = propertyKey;
    this.filters = filters;
    this.lsFilters = this.loadLsFilters();
  }

  /**
   * Compare current filters keys & local filter fetched from LS & return if keys are not different
   * @return {{[key: string]: boolean}}
   */
  getLocalFilter() {
    const localFilter = this.lsFilters[this.propertyKey];
    const currentFilter = this.filters?.[this.propertyKey];
    // Do not return saved filters if current filter has different keys
    if (localFilter && compareObjectKeys(currentFilter, localFilter)) {
      return localFilter;
    }
    return null;
  }

  /**
   * @param {{[key: string]: {[key: string]:boolean}}} filters
   */
  saveFilters(filters) {
    const newObject = {
      groupId: this.groupId,
      filters,
    };
    localStorage.setItem(this.localStorageKey, JSON.stringify(newObject));
    this.filters = filters;
    this.lsFilters = filters;
  }

  /**
   * Add filters in LS or update if already exist
   * @param {{[key: string]: boolean}} filter
   */
  addOrUpdateFilter(filter) {
    const filters = this.fetchFilters() || {};
    filters[this.propertyKey] = filter;
    this.saveFilters(filters);
  }

  /**
   * Remove filter from LS
   */
  removeFilter() {
    const filters = this.fetchFilters();
    if (filters) delete filters[this.propertyKey];
    this.saveFilters(filters);
  }

  /**
   * Fetch filter from LS if current group is equal to groupId saved in LS filter
   * @return {{[key: string]: {[key: string]:boolean}}}
   */
  fetchFilters() {
    const stored = JSON.parse(localStorage.getItem(this.localStorageKey));
    // Keep filter only if groupId match
    if (stored && stored.groupId === this.groupId) return stored.filters;
    return null;
  }

  /**
   * Load filters from localStorage if exist
   * @return {{[key: string]: {[key: string]:boolean}}}
   */
  loadLsFilters() {
    const filters = this.fetchFilters();
    if (filters) return filters;
    return {};
  }
}
