<template>
  <div class="stop-detailed">
    <div class="stop-detailed__header">
      <div class="stop-detailed__header-left">
        <HeaderDatePicker v-model:value="selectedDate" />
      </div>

      <div class="stop-detailed__header-right">
        <a :download="downloadFileName" :href="downloadLink">
          <Btn type="secondary">
            <i class="fas fa-download" aria-hidden="true"></i>
            <span class="btn-text">{{ $t('download') }}</span>
          </Btn>
        </a>
        <Btn
          type="primary"
          class="stop-detailed__primary-btn"
          :route="{
            name: GroupRoute.REPORTING_PUNCTUALITY,
            path: GroupRoute.REPORTING_PUNCTUALITY,
            query: { startDate: defaultStartDateGtfs, endDate: defaultEndDateGtfs },
          }"
        >
          <font-awesome-icon icon="chart-simple" />
          <span class="btn-text">{{ $t('punctualityReport') }}</span>
        </Btn>
      </div>
    </div>

    <div class="stop-detailed__main">
      <div class="stop-detailed__left-main-side">
        <DataGridVuetify
          v-if="!emptyRowList"
          ref="dataGrid"
          v-model:renderedDataLength="renderedDataLength"
          :title="$t('passages', { count: renderedDataLength })"
          :build-cell-injectors="buildCellInjectors"
          :data="rows"
          :datagrid="datagrid"
          :loading="rowsListLoading || !isAllLoaded"
        />
        <div v-else class="stop-detailed__no-data">
          <div>
            <div v-if="nonExistingStop" class="stop-detailed__no-data-text">
              {{ $t('nonExistingStop') }}
            </div>
            <div v-else class="stop-detailed__no-data-text">{{ $t('noPassage') }}</div>
            <div>
              <Btn type="secondary" @click="goToPreviousDay">{{ $t('previousDay') }}</Btn>
              <Btn type="secondary" @click="goToNextDay">{{ $t('nextDay') }}</Btn>
            </div>
          </div>
        </div>
      </div>

      <div class="stop-detailed__right-main-side">
        <StopDetailedInfoCard v-if="stop" :date="selectedDate" :group-id="group._id" :stop-info="stop" />

        <div class="stop-detailed__map">
          <MapboxMap
            v-if="stop"
            border-radius="8px"
            :center="center"
            :gtfs-id="group.current_file"
            :stops="[mapStop]"
            :stops-options="stopsOptions"
            :trips="[]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import MapboxMap from '@/components/map/MapboxMap.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatePicker from '@/components/ui/HeaderDatepicker.vue';
import {
  dateGtfsFormatToObj,
  dateObjToGtfsFormat,
  getISODate,
  timestampFormatHHMM,
  timestampMidnight,
} from '@/libs/helpers/dates';
import { arrToObj } from '@/libs/helpers/objects';

import Calendar from '@/libs/calendar';
import { toCSV } from '@/libs/csv.js';
import { GroupRoute } from '@/libs/routing';
import { trips as ApiTrips } from '@/api';

import { ColumnKey, getDatagrid } from './StopDetailed.conf.js';
import StopDetailedInfoCard from './StopDetailedInfoCard.vue';

dayjs.extend(utc);

/** @enum {number} */
const ExportColumns = {
  ROUTES_ID: 'Route_ID',
  ROUTE_FORMATTED_NAME: 'Route_formatted_name',
  PARENT_STATION: 'Parent_station',
  TRIP_ID: 'Trip_id',
  TRIP_FORMATTED_NAME: 'Trip_formatted_name',
  THEORETICAL_TIME: 'Theoretical_time',
  REAL_TIME: 'Real_time',
  DELAY: 'Delay',
};

export default {
  name: 'StopDetailed',

  components: {
    Btn,
    DataGridVuetify,
    HeaderDatePicker,
    MapboxMap,
    StopDetailedInfoCard,
  },

  props: {
    /** @type {Vue.PropOptions<{date?: string}>} */
    query: {
      default: () => ({}),
      type: Object,
    },

    stopId: {
      required: true,
      type: String,
    },
  },

  data: () => ({
    ColumnKey,
    ExportColumns,
    GroupRoute,

    /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
    datagrid: getDatagrid(),
    /** @type {?string} */
    downloadLink: null,
    /** @type {boolean} */
    emptyRowList: false,
    /** @type {Object<string, boolean>} */
    isLoaded: {
      recordedStopTimes: false,
      routes: false,
      stops: false,
      trips: false,
      tripsFormattedNames: false,
    },
    /** @type {boolean} */
    rowsListLoading: true,
    /** @type {{[gtfsTripId: string]: {[stopSequence: number]: number}}} */
    recordedStopTimes: {},
    /**
     * Map<GtfsId, Map<RouteId, Route>>.
     * @type {Object<string, Object<string, import('@/store/gtfs').Route>>}
     */
    routes: {},
    /** @type {Array<RowStopDetailed>} */
    rows: [],
    /**
     * Map<StopId, Stop>.
     * @type {Object<string, import('@/store/gtfs').Stop>}
     */
    stops: {},
    /**
     * Map<GtfsId, Map<TripId, Trip>>.
     * @type {Object<string, Object<string, import('@/store/gtfs').Trip>>}
     */
    trips: {},
    /**
     * Map<GtfsTripId, string>.
     * @type {Object<string, string>}
     */
    tripsFormattedNames: {},
    /** @type {number} */
    renderedDataLength: null,
  }),

  computed: {
    /** @return {{[key in ColumnTypes]: (data: {apiData: StopListItem) => Object}} */
    buildCellInjectors() {
      return {
        [ColumnKey.TRIP]: () => ({ date: this.selectedDate, groupId: this.group._id }),
      };
    },

    /** @return {[number, number]} */
    center() {
      return [this.stop.stop_lon, this.stop.stop_lat];
    },

    /** @return {string} */
    defaultStartDateGtfs() {
      return dateObjToGtfsFormat(dayjs(new Date()).subtract(8, 'day').toDate());
    },

    /** @return {string} */
    defaultEndDateGtfs() {
      return dateObjToGtfsFormat(new Date());
    },

    downloadFileName() {
      return `stop_detailed_${this.group.current_file}_${this.selectedDateGtfs}.csv`;
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {{[gtfsTripId: string]: {delay: number, stopSequence: number}}} */
    delayTripDevices() {
      /** @type {{[deviceId: string]: import('@/store/devices').Device}} */
      const onlineDevices = this.$store.getters['devices/onlineDevices'];

      return Object.values(onlineDevices).reduce((acc, device) => {
        const tripId = (device.trip != null && device.trip.trip_id) || device.trip_id;

        if (tripId != null) {
          const gtfsTripId = device.gtfs_id + tripId;

          if (!(gtfsTripId in acc)) {
            acc[gtfsTripId] = {
              delay: device.delay,
              stopSequence: device.current_stop_sequence,
            };
          }
        }

        return acc;
      }, /** @type {{[gtfsTripId: string]: {delay: number, stopSequence: number}}} */ ({}));
    },

    /** @return {boolean} */
    isAllLoaded() {
      return Object.values(this.isLoaded).every(loaded => loaded);
    },

    /** @return {?import('@/components/common/MapboxMap.vue').MapStop} */
    mapStop() {
      return {
        id: this.stop.stop_id,
        highlight: true,
      };
    },

    /** @return {number} */
    midnight() {
      const date = dateGtfsFormatToObj(this.selectedDateGtfs);
      return timestampMidnight(date, this.group.tz);
    },

    nonExistingStop() {
      if (this.stops && !this.stop) {
        return true;
      }
      return false;
    },

    selectedDate: {
      /** @return {Date} */
      get() {
        if (this.query.date) return dayjs(this.query.date).utc().toDate();

        return new Date();
      },

      /** @param {Date} value */
      set(value) {
        // Slow down date selection to avoid concurrent loading.
        if (!this.isAllLoaded) return;
        this.emptyRowList = false;

        this.$router.push({
          name: GroupRoute.STOP_DETAILED,
          params: {
            groupId: this.$store.getters.group._id,
            stopId: this.stopId,
          },
          query: {
            date: getISODate(value),
          },
        });
      },
    },

    /** @return {string} */
    selectedDateGtfs() {
      return dateObjToGtfsFormat(this.selectedDate);
    },

    /** @return {?import('@/store/gtfs').Stop} */
    stop() {
      const stop = { ...this.stops?.[this.stopId] };
      if (stop.parent_station) {
        // Get parent_station name from id
        stop.parentStationId = stop.parent_station;
        stop.parentStationName = this.stops[stop.parent_station].stop_name;
      }
      return Object.keys(stop).length !== 0 ? stop : null;
    },

    /** @return {{[gtfsId: string]: {[tripId: string]: {[stopSequence: number]: number}}}} */
    stopTimes() {
      const stopTimes =
        /** @type {{[gtfsId: string]: {[tripId: string]: {[stopSequence: number]: number}}}} */ ({});

      Object.entries(this.trips).forEach(([gtfsId, trips]) => {
        if (!stopTimes[gtfsId]) stopTimes[gtfsId] = {};

        Object.entries(trips).reduce((acc, [tripId, trip]) => {
          if (!stopTimes[gtfsId][tripId]) stopTimes[gtfsId][tripId] = {};

          trip.stop_times.forEach(st => {
            if (st.stop_id === this.stopId) {
              stopTimes[gtfsId][tripId][st.stop_sequence] = st.departure_time;
            }
          });

          return acc;
        }, []);
      });

      return stopTimes;
    },

    /** @return {import('@/components/common/MapboxMap.vue').StopsOptions} */
    stopsOptions() {
      return {
        stationsMarkers: true,
        stationsLabels: false,
        stopsMarkers: true,
        stopsLabels: false,
        stopsZones: true,
      };
    },
  },

  watch: {
    midnight() {
      this.loadRoutes();
    },

    recordedStopTimes() {
      if (this.isLoaded.recordedStopTimes) {
        this.createRowsList();
      }
    },

    rows() {
      if (this.rows) {
        this.createDownloadLink();
      }
    },

    selectedDate() {
      this.loadStops();
      // Also dependency of `loadFormattedNames`, but `stopTimes` should trigger it.
    },

    selectedDateGtfs() {
      this.loadTrips();
      // Also dependency of `loadRecordedStopTimes`, but `stopTimes` should trigger it.
    },

    async stopTimes() {
      // Async dependencies: watcher will be re-run for each.
      if (!this.isLoaded.stops || !this.isLoaded.trips) return;

      await this.loadFormattedNames();
      this.loadRecordedStopTimes();
    },

    stopId() {
      this.loadRecordedStopTimes();
    },
  },

  async created() {
    await Promise.all([
      this.loadRoutes(),
      this.loadStops(),
      this.loadTrips(),
      this.loadFormattedNames(),
      this.loadRecordedStopTimes(),
    ]);
  },

  methods: {
    createDownloadLink() {
      if (this.downloadLink != null) {
        URL.revokeObjectURL(this.downloadLink);
        this.downloadLink = null;
      }

      const tableData = [];

      this.rows.forEach(row => {
        tableData.push([
          row.route.route_id,
          row.route.route_long_name,
          row.parent_station,
          row.tripId,
          row.tripFormattedName,
          row.theoreticalTime,
          row.realTime,
          row.delay,
        ]);
      });

      const data = [Object.values(ExportColumns), ...tableData];

      this.downloadLink = toCSV(data);
    },

    /**
     * List of rows. Sorted by publication date of gtfs and stop time.
     * @return {Array<RowStopDetailed>}
     */
    async createRowsList() {
      this.rowsListLoading = true;
      this.emptyRowList = false;
      const rows = /** @type {Array<RowInfo>} */ ([]);
      const promises = Object.entries(this.stopTimes).map(async ([gtfsId, trips]) => {
        const promisesBis = Object.entries(trips).map(async ([tripId, stopSequences]) => {
          const serviceId = this.trips[gtfsId][tripId].service_id;
          // Check if the trip is active on that date
          const tripActive = await Calendar.isTripActiveOnDate(gtfsId, serviceId, this.selectedDate);
          // Add the trip to the list only if the trip is active on that date
          if (tripActive) {
            Object.keys(stopSequences).forEach(stopSequence => {
              rows.push({
                gtfsId,
                stopSequence,
                tripId,
              });
            });
          }
        });
        await Promise.all(promisesBis);
      });

      await Promise.all(promises);

      // Order trips by departure time
      rows.sort(
        (a, b) =>
          this.stopTimes[a.gtfsId][a.tripId][a.stopSequence] -
            this.stopTimes[b.gtfsId][b.tripId][b.stopSequence] ||
          this.trips[a.gtfsId][a.tripId].stop_times[0].departure_time -
            this.trips[b.gtfsId][b.tripId].stop_times[0].departure_time ||
          (a.tripId !== b.tripId ? (a.tripId < b.tripId ? -1 : 1) : 0)
      );

      // Add route, tripFormattedName, theoreticalTime, realTime and delay to trip object
      rows.forEach((row, index) => {
        row.id = `${row.tripId}-${index}`;
        row.route = this.getTripRoute(row.gtfsId, row.tripId);
        row.routeName = row.route.route_short_name || row.route.route_long_name || row.route.route_id;
        row.tripFormattedName = this.tripsFormattedNames[row.gtfsId + row.tripId] || row.tripId;
        const tsTheoreticalTime = this.midnight + this.stopTimes[row.gtfsId][row.tripId][row.stopSequence];
        const tsRealTime = this.recordedStopTimes[row.gtfsId + row.tripId]
          ? this.recordedStopTimes[row.gtfsId + row.tripId][row.stopSequence]
          : null;
        row.theoreticalTime = this.formatHHMM(tsTheoreticalTime);
        row.realTime = tsRealTime ? this.formatHHMM(tsRealTime) : null;
        row.delay = row.realTime ? Math.floor((tsRealTime - tsTheoreticalTime) / 60) : null;
      });

      this.rows = rows;
      if (this.isLoaded.trips && this.rows.length === 0) {
        this.emptyRowList = true;
      }
      this.rowsListLoading = false;
    },

    /**
     * @param {number} ts
     * @return {string}
     */
    formatHHMM(ts) {
      return timestampFormatHHMM(ts, { refDate: this.selectedDate, tz: this.group.tz });
    },

    /**
     * @param {string} gtfsId
     * @param {string} tripId
     * @param {number} stopSequence
     * @return {number}
     */
    getEstimatedTime(gtfsId, tripId, stopSequence) {
      const departureTime = this.midnight + this.stopTimes[gtfsId][tripId][stopSequence];
      const { delay } = this.delayTripDevices[gtfsId + tripId];
      return departureTime + Math.max(0, delay);
    },

    /**
     * @param {string} gtfsId
     * @param {string} tripId
     * @return {?import('@/store/gtfs').Route}
     */
    getTripRoute(gtfsId, tripId) {
      const routeId = this.trips[gtfsId][tripId].route_id;
      return (this.routes[gtfsId] || {})[routeId];
    },

    async loadFormattedNames() {
      this.isLoaded.tripsFormattedNames = false;
      const formattedNames = /** @type {{[gtfsTripId: string]: string}} */ ({});

      const promises = Object.entries(this.stopTimes).map(([gtfsId, trips]) =>
        Promise.all(
          Object.keys(trips).map(async tripId => {
            formattedNames[gtfsId + tripId] = await this.$store.dispatch('gtfs/formatTripName', {
              gtfsId,
              tripId,
              date: this.selectedDate,
            });
          })
        )
      );

      await Promise.all(promises);
      this.tripsFormattedNames = formattedNames;
      this.isLoaded.tripsFormattedNames = true;
    },

    goToPreviousDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() - 1);
      this.selectedDate = date;
    },

    goToNextDay() {
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() + 1);
      this.selectedDate = date;
    },

    async loadRecordedStopTimes() {
      this.isLoaded.recordedStopTimes = false;
      this.recordedStopTimes = {};
      const recordedStopTimes = /** @type {{[gtfsTripId: string]: {[stopSequence: number]: number}}} */ ({});

      /** @type {Array<import('@/api').StopTimeHistory>} */
      const stopTimeEvents = await ApiTrips.getHistoryStopTimes(
        this.$store.getters.group._id,
        this.selectedDateGtfs,
        null,
        this.stopId
      );
      const filteredEvents = stopTimeEvents.filter(
        st => ((this.stopTimes[st.gtfs_id] || {})[st.trip_id] || {})[st.stop_sequence] != null
      );

      // Find best departure time
      filteredEvents.forEach(st => {
        if (st.event === 'departure') {
          const gtfsTripId = st.gtfs_id + st.trip_id;

          if (!recordedStopTimes[gtfsTripId]) recordedStopTimes[gtfsTripId] = {};

          const thStopTime = this.stopTimes[st.gtfs_id][st.trip_id][st.stop_sequence];
          const currentRecordedStopTime = recordedStopTimes[gtfsTripId][st.stop_sequence];

          if (
            !currentRecordedStopTime ||
            Math.abs(currentRecordedStopTime - thStopTime) > Math.abs(st.ts - thStopTime)
          ) {
            recordedStopTimes[gtfsTripId][st.stop_sequence] = st.ts;
          }
        }

        if (st.event === 'arrival' && st.last_stop) {
          const gtfsTripId = st.gtfs_id + st.trip_id;

          if (!recordedStopTimes[gtfsTripId]) recordedStopTimes[gtfsTripId] = {};

          // Already exists
          if (recordedStopTimes[gtfsTripId][st.stop_sequence]) return;

          const thStopTime = this.stopTimes[st.gtfs_id][st.trip_id][st.stop_sequence];
          const currentRecordedStopTime = recordedStopTimes[gtfsTripId][st.stop_sequence];

          if (
            !currentRecordedStopTime ||
            Math.abs(currentRecordedStopTime - thStopTime) > Math.abs(st.ts - thStopTime)
          ) {
            recordedStopTimes[gtfsTripId][st.stop_sequence] = st.ts;
          }
        }
      });

      this.recordedStopTimes = recordedStopTimes;
      this.isLoaded.recordedStopTimes = true;
    },

    async loadRoutes() {
      this.isLoaded.routes = false;
      const publications = await this.$store.dispatch('gtfs/getGtfsPublicationsIn', {
        fromTs: this.midnight,
        toTs: this.midnight + 86400,
      });
      const gtfsIds = arrToObj(publications, 'current_file');

      // Clean unused GTFS
      Object.keys(this.routes).forEach(gtfsId => {
        if (!gtfsIds[gtfsId]) {
          delete this.routes[gtfsId];
        }
      });

      // Load new GTFS
      await Promise.all(
        Object.keys(gtfsIds).map(async gtfsId => {
          if (!this.routes[gtfsId]) {
            this.routes[gtfsId] = await this.$store.dispatch('gtfs/getRoutesMap', { gtfsId });
          }
        })
      );

      this.isLoaded.routes = true;
    },

    /**
     * Load stops and trips data from GTFS.
     */
    async loadStops() {
      this.isLoaded.stops = false;

      // Get stops from last GTFS at `selectedDate`.
      const date = new Date(this.selectedDate);
      date.setDate(date.getDate() + 1);
      const ts = date.getTime() / 1000;

      this.stops = await this.$store.dispatch('gtfs/getStopsMap', { ts });

      this.isLoaded.stops = true;
    },

    async loadTrips() {
      this.isLoaded.trips = false;

      const trips = await this.$store.dispatch('trips/getPublishedTripsMapOn', {
        dateGtfs: this.selectedDateGtfs,
      });
      this.trips = Object.freeze(trips);

      this.isLoaded.trips = true;
    },
  },
};

/**
 * @typedef {Object} RowStopDetailed
 * @property {number} delay
 * @property {string} gtfsId
 * @property {string} realTime
 * @property {import('@/store/gtfs').Route} route
 * @property {string} theoreticalTime
 * @property {string} tripId
 * @property {string} tripFormattedName
 * @property {number} stopSequence
 */
</script>

<style lang="scss">
.stop-detailed {
  height: 100%;
  padding: $view-standard-padding;
  background-color: $canvas;

  &__primary-btn {
    height: 44px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  &__header-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__header-right {
    display: flex;
    align-items: center;
    gap: 10px;

    .fas.fa-download {
      margin-right: 10px;
    }
  }

  &__main {
    display: flex;
    height: 80vh;
  }

  &__map {
    position: relative;
    height: 80%;
    margin-top: 5%;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  &__no-data-text {
    margin-bottom: 20px;
  }

  &__left-main-side {
    width: 65%;
    margin-right: 20px;
  }

  &__right-main-side {
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .datagrid {
    &__body {
      overflow-y: auto;
      max-height: 80vh;
      border: $border;
      border-radius: 8px;
      background-color: $background;
      box-shadow: none;
    }
  }

  .fa-chart-simple {
    color: white;
  }
}
</style>

<i18n locale="fr">
{
  "latitude": "Latitude",
  "longitude": "Longitude",
  "nonExistingStop": "Cet arrêt n'était pas dans le plan de transport à cette date.",
  "noPassage": "Aucun passage à cet arrêt à cette date.",
  "notFound": "Non trouvé",
  "recordedTime": "Horaire temps-réel",
  "routeName": "Ligne",
  "scheduledTime": "Horaire théorique",
  "station": "Station",
  "stop": "Arrêt",
  "passages": "passages",
  "tripHeadsign": "Girouette",
  "tripName": "Nom de course formaté",
  "punctualityReport": "Rapport de ponctualité",
  "previousDay": "Jour précédent",
  "nextDay": "Jour suivant",
}
</i18n>

<i18n locale="en">
{
  "latitude": "Latitude",
  "longitude": "Longitude",
  "nonExistingStop": "This stop was not part of the transport plan at this date.",
  "noPassage": "No passage at this stop at this date.",
  "notFound": "Not found",
  "recordedTime": "Time in real time",
  "routeName": "Line",
  "scheduledTime": "Theoretical time",
  "station": "Station ",
  "stop": "Stop",
  "passages": "passages",
  "tripHeadsign": "Trip headsign",
  "tripName": "Formatted trip name",
  "punctualityReport": "Go to punctuality report",
  "previousDay": "Previous day",
  "nextDay": "Next day",
}
</i18n>
