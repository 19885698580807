import { ref } from 'vue';
import { useStore } from 'vuex';
import * as Messages from '@/store/messages';
import i18n from '@/i18n';

export const useMessage = () => {
  const store = useStore();
  const { t } = i18n.global;

  /** @type {import('vue').Ref<boolean>} */
  const isLoading = ref(false);
  /** @type {import('vue'.Ref<string>)} */
  const messageListType = ref(Messages.BoxDirection.INBOX);
  /** @type {import('vue').Ref<Array<FormattedMessage>>} */
  const shownMessages = ref([]);
  /** @type {import('vue').Ref<boolean>} */
  const modalMessageArchiveShown = ref(false);
  /** @type {import('vue').Ref<?Array<FormattedMessage>>} */
  const multipleSelectedMessages = ref();

  /** @type {import('vue').Ref<Object>} */
  const modalMessageDetail = ref({
    message: {},
    shown: false,
  });
  /** @type {import('vue').Ref<Object>} */
  const modalMessageNew = ref({
    recipients: [],
    message: {},
    shown: false,
  });

  /**
   * Thanks to messages, format all data for the user.
   * @param {Array<import('@/store/messages').ApiMessage>} messages
   * @return {Promise<Array<FormattedMessage>>} formatted messages.
   */
  async function formatMessages(messages) {
    const messagesFormatted = [];
    // Collect all messages elements and store them in formattedMessage.
    const formatMessage = async message => {
      if (!message.sender?.client) return;
      const formattedMessage = {};

      const loadSender = async () => {
        // Message Sender
        try {
          const sender = await store.dispatch('messages/setFormattedSender', message);
          formattedMessage.senderName = sender.senderName;
          formattedMessage.senderId = sender.senderId;
        } catch (error) {
          console.warn('-=- messages/setFormattedSender / error: ', error);
        }
      };

      const loadRecipients = async () => {
        // Message recipients
        try {
          formattedMessage.recipients = await store.dispatch('messages/setFormattedRecipients', message);
        } catch (error) {
          console.warn('-=- messages/setFormattedRecipients / error: ', error);
        }
      };

      // Start async tasks
      const promises = [loadSender(), loadRecipients()];

      formattedMessage.id = message.id;
      formattedMessage.tripId = message.sender?.trip?.trip_id;
      formattedMessage.content = Messages.getFormattedDetails(message);
      formattedMessage.tripName = message.sender?.trip?.formatted_trip_name || message.sender?.trip?.trip_id;
      formattedMessage.sendDate = Messages.getSendDate(message);
      formattedMessage.status = Messages.getFormattedStatus(message);
      formattedMessage.urgent = message.urgent || false;
      formattedMessage.isUrgentText = t(`message.urgentStatus.${formattedMessage.urgent}`);
      formattedMessage.statusText = formattedMessage.status
        ? t(`message.status.${formattedMessage.status}`)
        : '-';
      await Promise.all(promises);
      messagesFormatted.push(formattedMessage);
    };

    await Promise.all(
      messages.map(async message => {
        try {
          await formatMessage(message);
        } catch (e) {
          console.error('message formatting failed', e);
        }
      })
    );

    return messagesFormatted;
  }

  async function getMessages() {
    isLoading.value = true;
    const messages = await store.dispatch('messages/getMessages');
    let sentmessages = [];
    if (messageListType.value === Messages.BoxDirection.INBOX)
      sentmessages = messages.filter(message => message.sender.client === 'driver');
    else sentmessages = messages.filter(message => message.sender.client !== 'driver');
    shownMessages.value = await formatMessages(sentmessages);
    isLoading.value = false;
  }

  /**
   * Show modalMessageDetail to view a detailed message.
   * @param {FormattedMessage} message
   */
  function showModalMessageDetail(message) {
    modalMessageDetail.value.message = message;
    modalMessageDetail.value.shown = true;
  }

  /** Trigger message update from api on sent message
    /*  Wait in order to let the api process send request
     */
  function handleSent() {
    setTimeout(getMessages, 500);
  }

  /**
   * Show modalMessageNew to create a message.
   * @param {Array<import('@/components/ui/ModalMessageNew.vue').Recipient>} [recipients = []] - Pre-fill new message with this recipient.
   */
  function showModalMessageNew(recipients = [], message = {}) {
    modalMessageNew.value.recipients = recipients;
    modalMessageNew.value.message = message;
    modalMessageNew.value.shown = true;
  }

  /**
   * Show modalMessageArchive to archive a message.
   */
  function showModalMessageArchive() {
    modalMessageArchiveShown.value = true;
  }

  /**
   * archive message
   * @param {Array<FormattedMessage>} messages
   */
  function multiArchive(messages) {
    multipleSelectedMessages.value = messages;
    showModalMessageArchive();
  }
  function closeModalMessageNew() {
    modalMessageNew.value = {
      recipients: [],
      shown: false,
    };
  }

  function submitModalMessageArchive() {
    const checkedIds = new Set([...multipleSelectedMessages.value.map(({ id }) => id)]);
    modalMessageArchiveShown.value = false;
    checkedIds.forEach(id => store.dispatch('messages/archive', id));
    getMessages();
  }

  return {
    isLoading,
    messageListType,
    shownMessages,
    modalMessageArchiveShown,
    multipleSelectedMessages,
    modalMessageDetail,
    modalMessageNew,
    getMessages,
    showModalMessageDetail,
    handleSent,
    showModalMessageNew,
    showModalMessageArchive,
    multiArchive,
    closeModalMessageNew,
    submitModalMessageArchive,
  };
};

/**
 * @typedef {Object} FormattedMessage
 * @property {string} id
 * @property {string} [tripId]
 * @property {string} content
 * @property {string} tripName
 * @property {number} sendDate
 * @property {string} status
 * @property {boolean} urgent
 * @property {string} isUrgentText
 * @property {string} statusText
 * @property {boolean} [archived]

 * @property {string} senderName
 * @property {string} senderId
 * @property {Array<FormattedRecipient>} recipients
 *
*/

/**
 * @typedef {Object} FormattedRecipient
 * @property {string} senderId
 * @property {string} senderName
 * @property {string} statusTitle
 * @property {boolean} reader
 * @property {number | boolean} delivered
 */
