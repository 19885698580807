const RE_ESCAPE = new RegExp('[-/^$*+?.()|[\\]{}]', 'g');

/**
 * Sort function for localeCompare
 * @param {string} a
 * @param {string} b
 * @param {string} locale
 * @returns {number}
 */
function localeCompareSort(a, b, locale) {
  return a?.localeCompare(b, locale, { sensitivity: 'accent' });
}

/**
 * @param {string} input
 * @return {string}
 */
function normalize(input) {
  if (!input) return null;

  let out = input.toLowerCase();
  // Remove accents and diacritics
  out = out.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  return out;
}

/**
 * Pad a value on left with padChar.
 * @param {number} value - Value to pad.
 * @param {number} width - Final width to have for value.
 * @param {string} padChar - Char to add on left a value.
 * @return {string} value - Padded value.
 */
function padLeft(value, width, padChar) {
  let valueToPad = String(value);
  while (valueToPad.length < width) {
    valueToPad = padChar + valueToPad;
  }

  return valueToPad;
}

export { localeCompareSort, normalize, padLeft, RE_ESCAPE };
