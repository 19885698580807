<template>
  <div class="mapbox-trips"></div>
</template>

<script>
import { MapboxHelper } from '@/components/map/mapboxHelper';

const SHAPE_ARROW_ICON = new URL(`../../assets/img/shape_arrow.png`, import.meta.url).href;

/** @enum {number} */
export const HighlightType = {
  NONE: 0,
  LESS: 1,
  MORE: 2,
};

const SHAPE_SOURCE_ID = 'shapesSource';
const SHAPES_ARROW_LAYER_ID = 'shapesArrowLayer';
const SHAPES_HIGHLIGHT_FG_LAYER_ID = 'shapesHighlightFgLayer';
const SHAPES_HIGHLIGHT_BG_LAYER_ID = 'shapesHighlightBgLayer';
const SHAPES_FG_LAYER_ID = 'shapesFgLayer';
const SHAPES_BG_LAYER_ID = 'shapesBgLayer';

export default {
  name: 'MapboxTrips',
  props: {
    /** @type {import('vue').Prop<mapboxgl.Map>} */
    map: {
      type: Object,
      required: true,
    },

    gtfsId: {
      type: String,
      required: true,
    },

    /** @type {import('vue').Prop<Array<import('./MapboxMap.vue').MapTrip>>} */
    trips: {
      type: Array,
      required: true,
    },
  },
  emits: ['click', 'mouseenter', 'mouseleave', 'isLoaded'],

  data: () => ({
    loaded: false,
  }),

  computed: {
    /** @return {{[id: string]: HighlightType}} */
    highlightShapes() {
      return this.trips.reduce((acc, elem) => {
        const trip = this.tripsGtfs[elem.id];
        if (!trip) return acc;

        const id = JSON.stringify({
          shapeId: trip.shape_id,
          routeId: trip.route_id,
        });

        acc[id] = Math.max(elem.highlight, acc[id] || HighlightType.NONE);

        return acc;
      }, /** @type {{[id: string]: HighlightType}} */ ({}));
    },

    /** @return {{[id: string]: ShapeData}} */
    shapesFiltered() {
      if (!this.loaded) return {};

      /** @type {{[shapeId: string]: import('@/store/gtfs').Shape}} */
      const shapesGtfs = this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'shapes');
      /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
      const routesGtfs = this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');

      return this.trips.reduce((acc, elem) => {
        const trip = this.tripsGtfs[elem.id];
        if (!trip) return acc;

        const route = routesGtfs[trip.route_id];
        const shape = shapesGtfs[trip.shape_id];
        if (!route || !shape) return acc;

        const id = JSON.stringify({
          shapeId: trip.shape_id,
          routeId: trip.route_id,
        });

        if (!acc[id]) {
          acc[id] = {
            color: route.route_color || 'ffffff',
            geometry: shape.geometry,
            id,
          };
        }

        return acc;
      }, /** @type {{[id: string]: ShapeData}} */ ({}));
    },

    /** @return {Array<GeoJSON.Feature<GeoJSON.LineString>>} */
    shapesSource() {
      return Object.values(this.shapesFiltered).map(
        shape =>
          /** @type {GeoJSON.Feature<GeoJSON.LineString} */ ({
            type: 'Feature',
            geometry: shape.geometry,
            properties: {
              color: `#${shape.color}`,
              highlight: this.highlightShapes[shape.id],
              id: shape.id,
            },
          })
      );
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Trip}} */
    tripsGtfs() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },
  },

  watch: {
    gtfsId: {
      immediate: true,
      handler() {
        this.$store.dispatch('gtfs/getRoutesMap', { gtfsId: this.gtfsId });
        this.$store.dispatch('gtfs/getShapesMap', { gtfsId: this.gtfsId });
      },
    },
    shapesSource: {
      deep: true,
      handler() {
        MapboxHelper.updateSource(this.map, SHAPE_SOURCE_ID, this.shapesSource);
      },
    },
  },

  created() {
    this.map.once('idle', () => {
      this.loaded = true;
    });

    MapboxHelper.addImage(this.map, SHAPE_ARROW_ICON, 'shape_arrow');

    this.initSourceAndLayer();
  },

  unmounted() {
    MapboxHelper.cleanLayersAndSources(
      this.map,
      [
        SHAPES_ARROW_LAYER_ID,
        SHAPES_HIGHLIGHT_FG_LAYER_ID,
        SHAPES_HIGHLIGHT_BG_LAYER_ID,
        SHAPES_FG_LAYER_ID,
        SHAPES_BG_LAYER_ID,
      ],
      [SHAPE_SOURCE_ID]
    );

    MapboxHelper.cleanImage(this.map, 'shape_arrow');
  },
  methods: {
    initSourceAndLayer() {
      MapboxHelper.createEmptySource(this.map, SHAPE_SOURCE_ID);
      this.addShapesBgLayer();
      this.addShapesFgLayer();
      this.addShapesHighlightBgLayer();
      this.addShapesHighlightFgLayer();
      this.addShapesArrowLayer();
      this.$emit('isLoaded', true);
    },

    addShapesArrowLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: SHAPES_ARROW_LAYER_ID,
          type: 'symbol',
          source: SHAPE_SOURCE_ID,
          filter: ['==', ['get', 'highlight'], HighlightType.MORE],
          layout: {
            'icon-image': 'shape_arrow',
            'icon-size': 0.25,
            'symbol-spacing': 50,
            'symbol-placement': 'line',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
          },
        })
      );
    },
    addShapesHighlightFgLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: SHAPES_HIGHLIGHT_FG_LAYER_ID,
          type: 'line',
          source: SHAPE_SOURCE_ID,
          filter: ['==', ['get', 'highlight'], HighlightType.MORE],
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-width': 3,
            'line-color': ['get', 'color'],
          },
        })
      );
    },
    addShapesHighlightBgLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: SHAPES_HIGHLIGHT_BG_LAYER_ID,
          type: 'line',
          source: SHAPE_SOURCE_ID,
          filter: ['==', ['get', 'highlight'], HighlightType.MORE],
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-width': 5,
            'line-color': '#000000',
          },
        })
      );
    },
    addShapesFgLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: SHAPES_FG_LAYER_ID,
          type: 'line',
          source: SHAPE_SOURCE_ID,
          filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-width': ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 2, 3],
            'line-color': ['get', 'color'],
            'line-opacity': ['case', ['==', ['get', 'highlight'], HighlightType.LESS], 0.3, 1],
          },
        })
      );
      this.map.on('click', SHAPES_FG_LAYER_ID, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('click', e);
        }
      });
      this.map.on('mouseenter', SHAPES_FG_LAYER_ID, e => {
        if (e.features && e.features.length > 0) {
          this.$emit('mouseenter', e);
        }
      });
      this.map.on('mouseleave', SHAPES_FG_LAYER_ID, e => {
        this.$emit('mouseleave', e);
      });
    },
    addShapesBgLayer() {
      MapboxHelper.addLayer(
        this.map,
        /** @type {mapboxgl.Layer} */ ({
          id: SHAPES_BG_LAYER_ID,
          type: 'line',
          source: SHAPE_SOURCE_ID,
          filter: ['!=', ['get', 'highlight'], HighlightType.MORE],
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-width': ['match', ['get', 'highlight'], HighlightType.LESS, 0, 4],
            'line-color': '#000000',
          },
        })
      );
    },
  },
};

/**
 * @typedef {Object} ShapeData
 * @property {string} color
 * @property {GeoJSON.LineString} geometry
 * @property {string} id
 */
</script>
