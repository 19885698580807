<template>
  <Modal
    modal-class="modal-publication"
    :forbid-closing="publicationInProgress"
    :persistent="publicationInProgress"
    :width="900"
    @close="close"
  >
    <template #title>
      <span v-if="mode === 'warning'">
        {{ $t('cantPublish') }}
      </span>
      <span v-if="mode === 'publish' || mode === 'scheduled'">
        {{ $t('addPublishDate') }}
      </span>
      <GtfsValidation
        :validation-in-progress="validationInProgress"
        :validation-data="validationData || {}"
        class="modal-publication__validation"
        @checkGtfs="$emit('redirectToEditor', gtfs._id, true, true)"
        @validationStatus="validationStatus = $event"
      />
    </template>

    <template #body>
      <section v-if="mode === 'warning'">
        <p>
          {{ $t('cantPublishDesc') }}
        </p>
        <p>
          {{ $t('cantPublishFix') }}
        </p>
      </section>

      <section v-if="mode === 'publish'" class="modal-publication__publication-body">
        <div class="form-group modal-publication__form-group">
          <label
            class="modal-publication__datepicker-label form-group__label"
            :class="{ 'label--disabled': instantPublication }"
            for="date"
          >
            {{ $t('publishDate') }}
          </label>
          <Datepicker
            id="date"
            v-model:value="startDateValue"
            class="modal-publication__datepicker"
            :disabled="instantPublication || !canPublish"
            :disabled-dates="disabledDates"
            input-class="form-group__input"
            :hide-input-icon="true"
            @opened="isDateOpen = true"
            @closed="isDateOpen = false"
          />
          <font-awesome-icon
            icon="calendar-days"
            class="modal-publication__input-icon"
            :class="{ 'icon--disabled': instantPublication }"
          />
        </div>
        <div class="form-group modal-publication__form-group">
          <label class="form-group__label" :class="{ 'label--disabled': instantPublication }" for="hour">
            {{ $t('publishHour') }}
          </label>

          <VueDatepicker
            class="modal-publication__timepicker"
            :model-value="startHourValue"
            time-picker
            disable-time-range-validation
            position="right"
            text-input
            :disabled="instantPublication || !canPublish"
            hide-input-icon
            input-class-name="form-group__input"
            :clearable="false"
            :text-input-options="textInputOptions"
            :select-text="$t('timePickerConfirm')"
            :cancel-text="$t('timePickerCancel')"
            @update:modelValue="handleUpdateTimepicker"
          />
          <font-awesome-icon
            icon="fa-clock"
            class="modal-publication__input-icon"
            :class="{ 'icon--disabled': instantPublication }"
          />
        </div>

        <v-checkbox
          id="immediatePublicationCheckbox"
          v-model="instantPublication"
          :disabled="publicationInProgress || !canPublish"
          color="success"
          hide-details
        >
          <template #label>
            {{ $t('instantPublication') }}
          </template>
        </v-checkbox>
      </section>
    </template>

    <template #cta>
      <Btn v-if="mode === 'warning'" type="primary" @click="$emit('redirectToEditor', gtfs._id, true)">
        {{ $t('accessToValidation') }}
      </Btn>

      <section v-if="mode === 'publish'">
        <Btn
          type="primary"
          class="modal-publication__btn"
          :data-loading="publicationInProgress"
          :disabled="!isDateValid || publicationInProgress || !canPublish"
          @click="publishGtfs"
        >
          <span v-if="!publicationInProgress">
            <font-awesome-icon icon="fa-arrow-circle-up" />
            <span v-if="instantPublication">{{ $t('publishNow') }}</span>
            <span v-else-if="!isDefaultValue">
              {{ $t('publishOn', [startDateValueFormatted, startHourValueFormatted]) }}
            </span>
            <span v-else>{{ $t('publishTonight', [startHourValueFormatted]) }}</span>
          </span>
          <span v-else>
            <font-awesome-icon icon="fa-circle-notch" spin />
            <span class="modal-publication__btn-content">
              {{ $t('publicationInProgress') }}
            </span>
          </span>
        </Btn>
      </section>
    </template>

    <template v-if="instantPublication || isInWarningPeriod" #warning>
      <div class="text">
        {{ $t('warning') }}
      </div>
      <div><img alt="illustration" src="@/assets/img/warning.svg" /></div>
    </template>
  </Modal>
</template>

<script>
import dayjs from 'dayjs';
import VueDatepicker from '@vuepic/vue-datepicker';

import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Datepicker from '@/components/ui/Datepicker.vue';

import GtfsValidation, { ValidationStatus } from './GtfsValidation.vue';

/** @enum {number} */
const DayTime = {
  START: 6,
  END: 21,
};

const DEFAULT_PUBLICATION_TIME = 3;

export default {
  name: 'ModalPublication',

  components: {
    Btn,
    Datepicker,
    GtfsValidation,
    Modal,
    VueDatepicker,
  },

  props: {
    mode: {
      default: '',
      type: String,
    },

    /** @type {import('vue').Prop<import('@/api').GtfsSchedule>} */
    gtfs: {
      default: null,
      type: Object,
    },
  },

  emits: ['close', 'publishGtfs', 'redirectToEditor'],

  data() {
    return {
      /** @type {boolean} */
      validationInProgress: false,
      /** @type {import('@/api').GtfsValidateResponse} */
      validationData: null,
      /** @type {import('./GtfsValidation.vue').ValidationStatus} */
      validationStatus: null,
      /** @type {boolean} */
      publicationInProgress: false,
      /** @type {boolean} */
      instantPublication: false,
      /** @type {boolean} */
      isDefaultValue: true,
      /** @type {Date} */
      startDateValue: null,
      startHourValue: { hours: DEFAULT_PUBLICATION_TIME, minutes: 0, seconds: 0 },
      textInputOptions: {
        enterSubmit: true,
        format: 'HH:mm',
      },
      timerNow: {
        _id: null,
        ts: Date.now() / 1000,
      },
    };
  },

  computed: {
    /** @return {boolean} */
    canPublish() {
      return [ValidationStatus.SUCCESS, ValidationStatus.WARNING].includes(this.validationStatus);
    },

    /** @return {import('@/components/ui/Datepicker.vue').DisabledDates} */
    disabledDates() {
      const date = new Date();
      return {
        minDate: date,
        maxDate: null,
      };
    },

    /** @return {boolean} */
    isDateValid() {
      if (this.instantPublication) {
        return true;
      }
      return (
        new Date(this.startDateValue).setHours(this.startHourValue.hours, this.startHourValue.minutes) >
        Date.now()
      );
    },

    /** @return {boolean} */
    isInWarningPeriod() {
      return this.startHourValue.hours >= DayTime.START && this.startHourValue.hours < DayTime.END;
    },

    /**
     * Return the start date in format DD/MM/YYYY
     *  @return {string} */
    startDateValueFormatted() {
      return new Date(this.startDateValue).toLocaleDateString(this.$i18n.locale);
    },

    /**
     * Return the start time in format HH:MM
     *  @return {string} */
    startHourValueFormatted() {
      let hours = this.startHourValue.hours.toString();
      let minutes = this.startHourValue.minutes.toString();
      if (hours.length === 1) {
        hours = `0${hours}`;
      }
      if (minutes.length === 1) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}`;
    },
  },

  watch: {
    startDateValue: {
      handler(newValue, oldValue) {
        if (oldValue) {
          this.isDefaultValue = false;
        }
      },
    },

    gtfs: {
      handler() {
        this.validateGtfs();
      },
      immediate: true,
      deep: true,
    },
  },

  created() {
    this.timerNow._id = setInterval(() => {
      this.timerNow.ts = Date.now() / 1000;
    }, 1000);
    this.startDateValue = dayjs(new Date()).add(1, 'day').toDate();
  },

  beforeUnmount() {
    this.validationData = null;
    clearInterval(this.timerNow._id);
  },

  methods: {
    close() {
      this.instantPublication = false;
      this.startDateValue = null;
      this.startHourValue = { hours: DEFAULT_PUBLICATION_TIME, minutes: 0, seconds: 0 };
      this.$emit('close');
    },

    /**
     * @param {import('@/libs/helpers/dates').TimeObject} value
     */
    handleUpdateTimepicker(value) {
      this.isDefaultValue = false;
      this.startHourValue = value;
    },

    publishGtfs() {
      this.publicationInProgress = true;
      const startHourValue = `${this.startHourValueFormatted}:00`;
      this.$emit('publishGtfs', {
        startDateValue: this.startDateValue,
        startHourValue,
        scheduledPublication: !this.instantPublication,
      });
    },

    async validateGtfs() {
      this.validationInProgress = true;
      const groupId = this.$store.getters.group._id;
      const data = await api.gtfs.getValidationStatus(groupId, this.gtfs._id);
      this.validationInProgress = false;
      this.validationData = data.validation;
    },
  },
};
</script>

<style lang="scss">
.modal-publication {
  .modal__secondary-btn {
    margin: 0 20px 20px;
  }

  .modal__header {
    padding: 20px;
    border-bottom: 1px solid $border;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    background-color: $background;
  }

  .modal__body {
    overflow-y: initial;
    margin-bottom: 0;
    padding: 20px;
    background-color: $white;
  }

  .modal__cta {
    padding: 0 20px;
    background-color: $white;
  }

  .modal__warning-message {
    margin: 0 20px 20px;
  }

  .v-overlay__content {
    padding: 0;
    background-color: $white;
  }

  &__form-group {
    position: relative;

    .label--disabled {
      color: $text-neutral;
    }
  }

  &__datepicker,
  &__timepicker {
    input {
      padding-left: 30px;
    }
  }

  &__input-icon {
    position: absolute;
    top: 36px;
    left: 10px;

    &.icon--disabled {
      color: $text-neutral;
    }
  }

  &__btn-content {
    margin-right: 5px;
  }

  .dp__selection_preview {
    color: transparent;
  }
}
</style>

<i18n locale="fr">
{
  "accessToValidation": "Accéder à la vue \"Vérification\"",
  "addPublishDate": "Publication d'un plan de transport",
  "cantPublish": "Publication impossible",
  "cantPublishDesc": "Des erreurs bloquantes empêchent la publication de votre plan de transport.",
  "cantPublishFix": "Accéder à la vue \"Vérification\" pour les corriger.",
  "instantPublication": "Publication immédiate",
  "publicationInProgress": "Publication en cours",
  "publishDate": "Date",
  "publishHour": "Heure",
  "publishNow": "Publier immédiatement",
  "publishOn": "Publier le {0} à {1}",
  "publishTonight": "Publier cette nuit à {0}",
  "timePickerCancel": "Annuler",
  "timePickerConfirm": "Valider",
  "warning": "Attention : publier un plan de transport en journée n'est pas recommandé"
}
</i18n>

<i18n locale="en">
{
  "accessToValidation": "Access the view \"Verification\"",
  "addPublishDate": "Transport plan publication",
  "cantPublish": "Publication impossible",
  "cantPublishDesc": "Some blocking errors do not allow your transport map to be published.",
  "cantPublishFix": "Please check the \"Verification\" view to correct them.",
  "instantPublication": "Instant publication",
  "publicationInProgress": "Publication in progress",
  "publishDate": "Date",
  "publishHour": "Time",
  "publishNow": "Publish now",
  "publishOn": "Publish on {0} at {1}",
  "publishTonight": "Publish tonight at {0}",
  "timePickerCancel": "Cancel",
  "timePickerConfirm": "Confirm",
  "warning": "Warning: publishing a transport plan during the day is not recommended"
}
</i18n>
