<template>
  <div class="modal-trip-modification__table">
    <v-timeline density="compact" side="end" truncate-line="both" :line-color="routeColor">
      <v-timeline-item
        v-for="(st, index) in feedStopTimes"
        :key="st.stop_id"
        class="mb-2 modal-trip-modification__table__timeline-line-feed"
        :dot-color="st.isNeutralized ? `#A41414` : st.isDisabled ? `#333` : `#F5F5F5`"
        :icon="st.isDisabled || st.isNeutralized ? `fa:fas fa-times` : `fa:fas fa-map-marker-alt`"
        :icon-color="st.isDisabled || st.isNeutralized ? `#FBFBFB` : `#333`"
        size="small"
      >
        <div class="modal-trip-modification__table__row">
          <div
            :id="`stop-${st.stop_id + st.stop_sequence}`"
            class="modal-trip-modification__table__row__stop"
            :class="st.isNeutralized ? `stop-neutralized` : ``"
          >
            <AddTemporaryStop v-if="index === 0" :stop="st" :is-before-first-stop="true" />
            <AddTemporaryStop :stop="st" />

            <div
              :title="getStopName(st.stop_id) || '-'"
              class="ml-2 modal-trip-modification__table__row__stop-name"
            >
              {{ getStopName(st.stop_id) || '-' }}
            </div>
            <div class="modal-trip-modification__table__right-part">
              <span v-if="st.isNeutralized" class="modal-trip-modification__table__neutralized">
                {{ $t('neutralized') }}
              </span>
              <v-btn
                v-else
                class="modal-trip-modification__table__cancel margin-auto"
                :class="st.isNeutralized ? `` : `btn-display-on-hover`"
                size="x-small"
                elevation="0"
                :prepend-icon="st.isDisabled ? 'fas fa-redo' : 'fas fa-times'"
                @click="updateStopList(st)"
              >
                {{ st.isDisabled ? $t('restore') : $t('cancel') }}
              </v-btn>
              <div class="margin-auto" :class="delay && !st.isNeutralized ? 'orange-icon' : ''">
                {{ getStopTimeHour(st) }}
              </div>
            </div>
          </div>

          <div class="modal-trip-modification__table__row__secondary-btn margin-auto">
            <Btn
              class="btn-display-on-hover"
              :route="{
                name: GroupRoute.STOP_DETAILED,
                params: { groupId: group._id, stopId: st.stop_id },
              }"
              target="_blank"
              type="icon-only"
            >
              <font-awesome-icon icon="fa-arrow-up-right-from-square" />
            </Btn>
          </div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import { dateGtfsFormatToObj, timestampFormatHHMM, timestampMidnight } from '@/libs/helpers/dates';
import Btn from '@/components/ui/Btn.vue';
import { GroupRoute } from '@/libs/routing';
import AddTemporaryStop from '@/components/common/ModalTripModification/AddTemporaryStop.vue';

export default {
  components: {
    Btn,
    AddTemporaryStop,
  },
  props: {
    routeColor: {
      default: '#00b871', // $primary-light
      type: String,
    },
    /** @type {Vue.PropOptions<Array<import('@/store/gtfs').Stop>>} */
    neutralizedStops: {
      default: () => [],
      type: Array,
    },
    /** @type {Vue.PropOptions<Array<import('@/store/gtfs').StopTime>>}} */
    stopTimes: {
      type: Array,
      required: true,
    },
    /** @type {Vue.PropOptions<Array<import('@/store/gtfs').StopTime>>}} */
    unscheduledStops: {
      type: Array,
      required: true,
    },
    /** @type {Vue.PropOptions<{[stopId: string]: import('@/store/gtfs').Stop}>} */
    stops: {
      type: Object,
      required: true,
    },
    delay: {
      type: Number,
      default: null,
    },
    date: {
      required: true,
      type: String,
    },
  },

  emits: ['mapFocusStop', 'update:unscheduledStops'],

  data: () => ({
    GroupRoute,
    /** @type {Array<FeedStopTimes>} */
    feedStopTimes: [],
  }),

  computed: {
    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },
    /** @return {number} */
    midnight() {
      const date = dateGtfsFormatToObj(this.date);
      return timestampMidnight(date, this.group.tz);
    },
  },

  watch: {
    unscheduledStops: {
      handler() {
        this.feedStopTimes = this.feedStopTimes.map(st => {
          st.isDisabled = this.unscheduledStops.some(
            s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence
          );
          st.isNeutralized = this.neutralizedStops.some(
            s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence
          );
          return st;
        });
      },
      deep: true,
    },
  },

  created() {
    this.feedStopTimes = cloneDeep(this.stopTimes);
    this.feedStopTimes = this.feedStopTimes.map(st => {
      st.isDisabled = this.unscheduledStops.some(
        s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence
      );
      st.isNeutralized = this.neutralizedStops.some(
        s => s.stop_id === st.stop_id && s.stop_sequence === st.stop_sequence
      );
      return st;
    });
  },

  methods: {
    /**
     * Update stopList on click on a row (also trigger emit to update in Modal & trigger fly on map)
     * @param {FeedStopTimes} feedStopTime
     */
    updateStopList(feedStopTime) {
      if (!feedStopTime.isNeutralized) {
        feedStopTime.isDisabled = !feedStopTime.isDisabled;

        const relatedStopTime = this.stopTimes.find(
          st => st.stop_id === feedStopTime.stop_id && st.stop_sequence == feedStopTime.stop_sequence
        );
        let disabledList = this.unscheduledStops;
        if (feedStopTime.isDisabled) {
          disabledList.push(relatedStopTime);
        } else {
          const stopInUnscheduledList = this.unscheduledStops.find(
            s => s.stop_id === feedStopTime.stop_id && s.stop_sequence === feedStopTime.stop_sequence
          );
          disabledList.splice(this.unscheduledStops.indexOf(stopInUnscheduledList), 1);
        }

        this.$emit('update:unscheduledStops', disabledList);
        this.$emit('mapFocusStop', feedStopTime.stop_id);
      }
    },
    /**
     * @param {string} stopId
     * @return {string}
     */
    getStopName(stopId) {
      return (this.stops[stopId] || {}).stop_name;
    },

    /**
     * Get required time & format it to display
     * @param {import('@/store/gtfs').StopTime} stopTime
     */
    getStopTimeHour(stopTime) {
      let time = stopTime.departure_time;
      // get arrival time instead of departure if we are on last stop
      if (stopTime.stop_sequence === this.stopTimes.length) time = stopTime.arrival_time;

      const dateTs = (this.midnight + this.withDelay(time)) * 1000;
      return timestampFormatHHMM(dateTs / 1000, { tz: this.group.tz });
    },
    /**
     * Take a time and apply delay if exist
     * @param {number} time
     * @return {number}
     * */
    withDelay(time) {
      if (this.delay) {
        return time + this.delay * 60;
      }
      return time;
    },
  },
};

/**
 * @typedef {Object} AdditionalDataStopTimes
 * @property {boolean?} isNeutralized
 * @property {boolean?} isDisabled
 */

/**
 *  @typedef {import('@/store/gtfs').StopTime & AdditionalDataStopTimes} FeedStopTimes
 */
</script>
<style lang="scss">
.modal-trip-modification__table {
  overflow-y: auto;
  width: 50%;
  height: calc(
    96vh - 68px - 40px - 49px - 42px - 25px - 98px - 30px
  ); // 96vh because 2vh margin top&bottom, 68px = header, 40px = modifyService title, 49px = delay input, 42px = cancel trip, 25px = next days, 98px = footer btn, -30px bonus

  margin-bottom: 10px;
  padding: 10px;

  /* Hide scrollbar on every bowsers */
  -ms-overflow-style: none;
  scrollbar-width: none;

  .v-timeline {
    margin: 10px 0;
  }

  &__timeline-line-feed {
    .v-timeline-item__body {
      width: 100%;
    }

    &:hover {
      .add-temporary-stop__btn {
        display: inline-grid;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__cancel {
    border: 1px solid $border-variant;
    border-radius: 5px;
    background: $canvas;
    color: $text-dark-variant;
    font-size: 12px;
  }

  &__neutralized {
    font-weight: $font-weight-semi-bold;
  }

  &__row {
    display: flex;

    &:hover {
      .modal-trip-modification__table__row__stop:not(.stop-neutralized) {
        background-color: $background-variant;
      }

      .btn-display-on-hover {
        opacity: 1;
      }
    }

    &__stop {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      margin-left: -25px;
      padding: 18px 12px;
      border-radius: 10px;
      cursor: pointer;
    }

    &__stop-name {
      @include multi-line-ellipsis($font-size: 14px, $line-height: 21px, $lines-to-show: 1);
    }

    &__stop.stop-neutralized {
      color: $danger-dark;
      cursor: initial;
    }

    &__secondary-btn {
      padding-left: 10px;
    }
  }

  &__right-part {
    display: flex;
    flex-shrink: 0;
    gap: 10px;
  }

  // vuetify timeline "override"
  .v-timeline-divider__before,
  .v-timeline-divider__after {
    border-right: solid 1px black !important;
    border-left: solid 1px black !important;
  }

  // border is baddly implemented in default vuetify css
  .v-timeline-divider__dot {
    border: 1px solid $text-dark;
  }

  .v-timeline-divider__dot--size-small .v-timeline-divider__inner-dot {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .modal-trip-modification__table {
    max-width: calc(100% - 500px);
  }
}
</style>

<i18n locale="fr">
  {
    "neutralized": "Neutralisé"
  }
  </i18n>

<i18n locale="en">
  {
    "neutralized": "Neutralized"
  }
  </i18n>
