<template>
  <v-dialog
    v-model="isOpen"
    class="modal"
    :persistent="persistent"
    :class="modalClass"
    :width="width"
    max-width="1400"
  >
    <Btn
      v-if="hasCloseBtn"
      type="icon-only"
      class="modal__btn-close"
      :disabled="forbidClosing"
      @click="close"
    >
      <font-awesome-icon icon="fa-xmark" />
    </Btn>

    <div class="modal__header">
      <div class="modal__title">
        <slot name="title" />
      </div>

      <div class="modal__subtitle">
        <slot name="subtitle" />
      </div>
    </div>

    <div class="modal__body">
      <slot name="body" />
    </div>

    <div v-if="!hideFooter" class="modal__cta">
      <slot name="cta">
        <Btn type="primary">
          {{ $t('save') }}
        </Btn>
      </slot>
    </div>

    <Btn
      v-if="!hideFooter"
      type="secondary"
      class="modal__secondary-btn"
      :disabled="forbidClosing"
      @click="close"
    >
      <slot name="secondary-btn" class="modal__secondary-btn">
        {{ $t('cancel') }}
      </slot>
    </Btn>

    <div v-if="$slots.warning" class="modal__warning-message">
      <slot name="warning" />
    </div>
  </v-dialog>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'Modal',

  components: {
    Btn,
  },

  props: {
    forbidClosing: {
      type: Boolean,
      default: false,
    },

    hasCloseBtn: {
      type: Boolean,
      default: true,
    },

    modalClass: {
      type: String,
      default: '',
    },

    hideFooter: {
      type: Boolean,
      default: false,
    },

    persistent: {
      type: Boolean,
      default: false,
    },

    width: {
      type: Number,
      default: 600,
    },
  },

  emits: ['close'],

  data() {
    return {
      isOpen: true,
    };
  },
  watch: {
    isOpen(newValue, old) {
      if (!newValue) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal {
  .v-overlay__content {
    padding: 20px;
    border-radius: 9px;
    background-color: $background;
  }

  &__btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
    border-width: 0 !important;
    cursor: pointer;

    svg {
      font-size: 18px !important;

      &:hover {
        opacity: 0.7;
      }
    }

    &.ui-btn:hover:not(:disabled, .link-disabled) {
      background-color: transparent;
    }
  }

  &__body {
    overflow-y: scroll;
    margin-bottom: 10px;

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      height: 20px;
      border-radius: 10px;
      background-color: $text-neutral;
      background-clip: padding-box;
    }
  }

  &__cta {
    margin-bottom: 10px;

    .ui-btn {
      width: 100%;
    }
  }

  &__header {
    padding: 15px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  &__title {
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
    text-align: center;
  }

  &__warning-message {
    display: flex;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid $warn;
    border-radius: 10px;
    background-color: $transparent-warn;

    .text {
      width: 80%;
      padding: 10px;
      text-align: left;
    }
  }
}
</style>
