/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module */
import Api from '@/api';
import { arrToFakeMap } from '@/libs/helpers/objects';

/**
 * @param {Vehicle} vehicle
 * @return {string}
 */
export const formatVehicle = vehicle =>
  vehicle.license_plate ? `${vehicle.license_plate} (${vehicle.fleet_number})` : `${vehicle.license_plate}`;

const setId = obj => {
  if (!obj._id && obj.id) {
    obj._id = obj.id;
    delete obj.id;
  }
};

/** @typedef {typeof state} State */
const state = {
  /**
   * Map<VehicleId, Vehicle>
   * @type {Object<string, Vehicle>}
   */
  list: {},
};

export default /** @type {import('vuex').Module<State, import('.').State>} */ ({
  namespaced: true,

  state,

  mutations: {
    setList(state, list) {
      state.list = list;
    },
    addOrUpdate(state, vehicle) {
      state.list[vehicle._id] = vehicle;
    },
    delete(state, vehicleId) {
      delete state.list[vehicleId];
    },
  },
  getters: {
    /**
     * Get by id a Vehicle representation in format 'license_plate (fleet_number)'
     * @callback getVehicleInfosByIdCallback
     * @param {string} vehicleId
     * @return {string}
     * */
    /** @return {getVehicleInfosByIdCallback} */
    getVehicleInfosById: state => vehicleId => {
      const vehicle = state.list[vehicleId];
      if (!vehicle) {
        return null;
      }
      return formatVehicle(vehicle);
    },
  },

  actions: {
    async loadList({ commit, rootGetters }) {
      let list = await Api.vehicles.get(rootGetters.group._id);
      if (Array.isArray(list)) {
        list.forEach(setId);
        list = list.filter(vehicle => vehicle.archived !== true);
        commit('setList', arrToFakeMap(list, '_id'));
      }
    },
    async createVehicle({ commit, rootGetters }, vehicle) {
      const data = await Api.vehicles.post(rootGetters.group._id, vehicle);
      if (data.id) setId(data);
      commit('addOrUpdate', { ...vehicle, ...data });
    },
    async updateVehicle({ commit, rootGetters }, { vehicleId, vehicle }) {
      if (!vehicle.team_id) vehicle.team_id = '';

      // remove _id and id from the request body to avoid 422 error in api
      const vehiclePayload = { ...vehicle };
      delete vehiclePayload._id;
      delete vehiclePayload.id;

      await Api.vehicles.put(rootGetters.group._id, vehicleId, vehiclePayload);
      commit('addOrUpdate', { ...vehicle, _id: vehicleId });
    },
    async deleteVehicle({ commit, rootGetters }, vehicleId) {
      await Api.vehicles.delete(rootGetters.group._id, vehicleId);
      commit('delete', vehicleId);
    },
    async uploadCsv({ dispatch, rootGetters }, file) {
      const success = await Api.vehicles.uploadCsv(rootGetters.group._id, file);
      if (success) {
        await dispatch('loadList');
      }
    },
  },
});

/**
 * @typedef {Object} Vehicle
 * @property {boolean} archived
 * @property {string} _id // deprecated
 * @property {string} id
 * @property {string} [comment]
 * @property {string} fleet_number
 * @property {string} license_plate
 * @property {number} [capacity]
 * @property {string} [team_id]
 */
