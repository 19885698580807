import { createAuth0 } from '@auth0/auth0-vue';

const auth0 = createAuth0({
  auth0Client: {
    name: 'Op',
    version: '1.0.0',
  },
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}${window.location.pathname}#/`,
    audience: import.meta.env.VITE_AUTH0_AUD,
    prompt: 'login',
  },
});

export const untilAuthenticated = async ms => {
  await auth0.getAccessTokenSilently();
  while (auth0.isLoading.value) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, ms));
  }
};

export default auth0;
