<template>
  <div class="reports-menu">
    <div class="card">
      <div class="card-header">
        {{ $t('metric') }}
      </div>
      <div class="card-body">
        <select class="form-group__input" :value="metric" @change="selectMetric($event.target.value)">
          <option v-for="[value, translation] in metrics" :key="value" :value="value">
            {{ translation }}
          </option>
        </select>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        {{ $t('interval') }}

        <button class="btn btn-small edit-btn" @click="$emit('editPeriod')" @mousedown.prevent>
          <font-awesome-icon icon="fa-pencil" />
        </button>
      </div>

      <div class="card-body">
        <div>
          {{ $t('startDate') }}
          <b>{{ $d(startDateValue, 'dateShort') }}</b>
        </div>

        <div>
          {{ $t('endDate') }}
          <b>{{ $d(endDateValue, 'dateShort') }}</b>
        </div>
      </div>
    </div>

    <div v-if="metric !== RouteName.REPORTING_TRAVEL_TIME" class="card">
      <div class="card-header">
        {{ $t('groupBy.title') }}
      </div>

      <div class="card-body">
        <select
          :key="key"
          class="form-group__input"
          :class="{
            'form-group__input--disabled': canChangeGroup,
          }"
          :value="groupBy"
          :disabled="canChangeGroup"
          @change="$emit('updateQuery', { groupBy: $event.target.value })"
        >
          <option v-for="[option, translation] in groupByOptions" :key="option" :value="option">
            {{ translation }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="metric === RouteName.REPORTING_OLD_PUNCTUALITY" class="card">
      <div class="card-header">
        {{ $t('type') }}
      </div>

      <div class="card-body">
        <div class="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            v-for="option in [ReportType.TABLE, ReportType.GRAPH]"
            :key="option"
            class="btn ui-btn secondary"
            :class="{
              active: type === option,
              focus: focus === option,
            }"
            :title="$t(`reportType.${option}`)"
          >
            <input
              autocomplete="off"
              :checked="type === option"
              name="type"
              type="radio"
              @change="$emit('updateQuery', { type: option })"
              @focus="focus = option"
              @blur="focus = null"
            />

            <font-awesome-icon :icon="ReportTypeIcon[option]" />
          </label>
        </div>
      </div>
    </div>

    <div v-if="displayFilters" class="card">
      <div class="card-header">
        {{ $t('filters.title') }}

        <button class="btn btn-small edit-btn" @click="$emit('editFilters')" @mousedown.prevent>
          <font-awesome-icon icon="fa-pencil" />
        </button>
      </div>

      <div class="card-body">
        <div v-for="[filter, value] in translatedFilters" :key="filter">
          {{ $t(`filters.${filter}`) }}
          <b>{{ value }}</b>
        </div>

        <i v-if="translatedFilters.length === 0" class="text-muted">{{ $t('noFilters') }}</i>
      </div>
    </div>
  </div>
</template>

<script>
import { dateGtfsFormatToObj, secondsToStr } from '@/libs/helpers/dates';
import { GroupBy, DAYS_OF_WEEK, ReportType, ReportPages } from '@/libs/reports';
import { GroupRoute as RouteName } from '@/libs/routing';

export default {
  name: 'ReportsMenu',

  props: {
    /** @type {Vue.PropOptions<{[key in GroupBy]: string}>} */
    availableOptions: {
      type: Object,
      required: true,
    },

    endDate: {
      type: String,
      required: true,
    },

    /** @type {Vue.PropOptions<import('./index.vue').Filters>} */
    filters: {
      type: Object,
      default: () => ({}),
    },

    /** @type {Vue.PropOptions<GroupBy>} */
    groupBy: {
      type: String,
      default: null,
    },

    /** @type {Vue.PropOptions<ReportPages>} */
    metric: {
      type: String,
      required: true,
    },

    startDate: {
      type: String,
      required: true,
    },

    /** @type {Vue.PropOptions<ReportType>} */
    type: {
      type: String,
      default: null,
    },
  },

  emits: ['editPeriod', 'editFilters', 'updateQuery'],

  data: () => ({
    ReportType,
    RouteName,
    ReportTypeIcon: Object.freeze({
      [ReportType.GRAPH]: 'fa-chart-bar',
      [ReportType.TABLE]: 'fa-table',
    }),

    /** @type {?string} */
    focus: null,
    /** @type {number} */
    key: 0,
  }),

  computed: {
    /** @return {boolean} */
    canChangeGroup() {
      return (
        this.metric === RouteName.REPORTING_TRAVEL_TIME ||
        this.metric === RouteName.REPORTING_CONNECTED_DEVICES
      );
    },

    /** @return {boolean} */
    displayFilters() {
      return [RouteName.REPORTING_TRAVEL_TIME, RouteName.REPORTING_OLD_PUNCTUALITY].includes(this.metric);
    },

    /** @return {Date} */
    endDateValue() {
      return dateGtfsFormatToObj(this.endDate);
    },

    /** @return {Array<[GroupBy, string]>} */
    groupByOptions() {
      const options = Object.values(this.availableOptions).map(
        m => /** @type {[GroupBy, string]} */ ([m, /** @type {string} */ (this.$t(`groupBy.${m}`))])
      );
      const collator = new Intl.Collator(this.$i18n.locale);
      options.sort((a, b) => collator.compare(a[1], b[1]));

      return options;
    },

    /** @return {Array<[ReportPages, string]>} */
    metrics() {
      return ReportPages.map(metric => [metric, this.$t(`metrics.${metric}`).toString()]);
    },

    /** @return {Date} */
    startDateValue() {
      return dateGtfsFormatToObj(this.startDate);
    },

    /** @return {Array<[string, string]>} */
    translatedFilters() {
      const filters = Object.entries(this.filters).map(([filter, values]) => {
        let value = values;

        if (filter === 'time') {
          const start = secondsToStr(values.start);
          const end = secondsToStr(values.end);

          return [filter, this.$t('timeInterval', { start, end })];
        }
        if (filter === 'daysOfWeek') {
          value = values.map(i => this.$t(`dowShort.${DAYS_OF_WEEK[i]}`));
        }

        return [filter, value.join(this.$t('commaSeparator')) || this.$tc('none')];
      });

      return filters;
    },
  },

  watch: {
    // Force refresh on select
    groupBy() {
      this.key += 1;
    },
    metric() {
      this.updateParams();
    },
  },
  created() {
    this.updateParams();
  },

  methods: {
    updateParams() {
      let type = '';
      let groupBy = '';
      if (this.$route?.query?.groupBy) {
        groupBy = this.$route?.query?.groupBy;
      } else if (this.metric === RouteName.REPORTING_PASSENGERS_APP) {
        groupBy = GroupBy.DAY;
      } else if (this.metric === RouteName.REPORTING_OLD_PUNCTUALITY) {
        groupBy = GroupBy.TRIP;
      }

      if (this.$route?.query?.type) {
        type = this.$route?.query?.type;
      } else if (this.metric === RouteName.REPORTING_OLD_PUNCTUALITY) {
        type = ReportType.TABLE;
      }
      this.$emit('updateQuery', {
        metric: this.metric,
        groupBy,
        type,
      });
    },
    selectMetric(metric) {
      this.$emit('updateQuery', { metric });
    },
  },
};

/** @typedef {import('@/libs/reports').GroupBy} GroupBy */
</script>

<style lang="scss">
.reports-menu {
  position: fixed;
  width: inherit;
  padding: 10px;

  .btn {
    cursor: pointer;
  }

  .card {
    margin-bottom: 1em;
  }

  .edit-btn {
    float: right;
  }

  // override default margin set between buttons in btn.scss
  .ui-btn:not(.icon-only) i:not(:first-child) {
    margin-left: 0;
  }
}
</style>

<i18n locale="fr">
{
  "filters": {
    "title": "Filtres",
    "blocks": "Service :",
    "daysOfWeek": "Jours pris en compte :",
    "routes": "Lignes :",
    "stops": "Arrêts :",
    "teams": "Équipes :",
    "time": "Période horaire :",
    "tripHeadsigns": "Girouettes :"
  },
  "groupBy": {
    "title": "Regroupement",
    "day": "Jour",
    "device": "Appareil",
    "route": "Ligne",
    "service": "Service",
    "stop": "Arrêt",
    "team": "Équipe",
    "trip": "Course",
    "week": "Semaine",
    "month": "Mois"
  },
  "reportType": {
    "graph": "Vue graphique",
    "map": "Vue cartographique (uniquement pour la ponctualité)",
    "table": "Vue tableau"
  },
  "endDate": "Date de fin :",
  "interval": "Période",
  "metric": "Indicateur",
  "noFilters": "Aucun filtre",
  "startDate": "Date de début :",
  "timeInterval": "{start} - {end}",
  "type": "Type de rapport"
}
</i18n>

<i18n locale="en">
{
  "filters": {
    "title": "Filters",
    "blocks": "Service:",
    "daysOfWeek": "Working days:",
    "routes": "Routes:",
    "stops": "Stops:",
    "teams": "Teams:",
    "time": "Time period:",
    "tripHeadsigns": "Electronic signs:"
  },
  "groupBy": {
    "title": "Merging",
    "day": "Day",
    "device": "Device",
    "route": "Route",
    "service": "Duty",
    "stop": "Stop",
    "team": "Team",
    "trip": "Trip",
    "week": "Week",
    "month": "Month"
  },
  "reportType": {
    "graph": "Graphic display",
    "map": "Map view (for the punctuality item)",
    "table": "Table view"
  },
  "endDate": "End date:",
  "interval": "Period",
  "metric": "Indicator",
  "noFilters": "No filters",
  "startDate": "Start date:",
  "timeInterval": "{start} - {end}",
  "type": "Report type"
}
</i18n>

<i18n locale="cz">
{
  "filters": {
    "blocks": "Jízda:",
    "daysOfWeek": "Pracovní dny:",
    "routes": "Trasy:",
    "stops": "Zastávky:",
    "teams": "Týmy:",
    "time": "Časový úsek:",
    "tripHeadsigns": "Elektronické cedule:",
    "title": "Filtry"
  },
  "groupBy": {
    "title": "Skupina",
    "day": "Den",
    "stop": "Zastávka",
    "week": "Týden",
    "service": "Jízda",
    "trip": "Jízda",
    "device": "Zařízení",
    "route": "Linka",
    "team": "Tým"
  },
  "reportType": {
    "map": "Zobrazení v mapě (pro položku přesnosti)",
    "table": "Tabulkové zobrazení",
    "graph": "Grafické zobrazení"
  },
  "endDate": "Datum ukončení:",
  "metric": "Metrika",
  "noFilters": "Žádný filtr",
  "startDate": "Datum začátku:",
  "timeInterval": "{start} - {end}",
  "type": "Druh reportu",
  "interval": "Období"
}
</i18n>

<i18n locale="de">
{
  "filters": {
    "blocks": "Fahrt:",
    "daysOfWeek": "Fahrttage:",
    "routes": "Strecken:",
    "stops": "Haltestellen:",
    "teams": "Teams:",
    "time": "Zeitspanne:",
    "tripHeadsigns": "Digitale Anzeigetafeln:",
    "title": "Filter"
  },
  "groupBy": {
    "title": "Gruppe",
    "day": "Tag",
    "stop": "Haltestelle",
    "week": "Woche",
    "service": "Fahrt",
    "trip": "Fahrt",
    "device": "Gerät",
    "route": "Strecke",
    "team": "Team"
  },
  "reportType": {
    "map": "Kartenansicht (für die Pünktlichkeit)",
    "table": "Tabellenansicht",
    "graph": "Grafische Anzeige"
  },
  "endDate": "Enddatum:",
  "metric": "Messwert",
  "noFilters": "Keine Filter",
  "startDate": "Startdatum:",
  "timeInterval": "{start} - {end}",
  "type": "Berichtart",
  "interval": "Zeitspanne"
}
</i18n>

<i18n locale="es">
{
  "filters": {
    "blocks": "Servicio:",
    "daysOfWeek": "Días laborables:",
    "routes": "Rutas:",
    "stops": "Paradas:",
    "teams": "Equipos:",
    "time": "Periodo de tiempo:",
    "tripHeadsigns": "Indicadores electrónicos:",
    "title": "Filtros"
  },
  "groupBy": {
    "title": "Grupo",
    "day": "Día",
    "stop": "Paradas",
    "week": "Semana",
    "service": "Servicio",
    "trip": "Servicio",
    "device": "Dispositivo",
    "route": "Línea",
    "team": "Equipo"
  },
  "reportType": {
    "map": "Vista de mapa (para el elemento de puntualidad)",
    "table": "Vista de tabla",
    "graph": "Pantalla gráfica"
  },
  "endDate": "Fecha de finalización:",
  "metric": "Métrica",
  "noFilters": "Sin filtros",
  "startDate": "Fecha de inicio:",
  "timeInterval": "{start} - {end}",
  "type": "Tipo de informe",
  "interval": "Periodo"
}
</i18n>

<i18n locale="it">
{
  "filters": {
    "blocks": "Servizio:",
    "daysOfWeek": "Giorni di servizio:",
    "routes": "Percorsi:",
    "stops": "Fermate:",
    "teams": "Team:",
    "time": "Periodo di tempo:",
    "tripHeadsigns": "Indicatori di destinazione:",
    "title": "Filtri"
  },
  "groupBy": {
    "title": "Gruppo:",
    "day": "Giorno",
    "stop": "Fermata",
    "week": "Settimana",
    "service": "Servizio",
    "trip": "Servizio",
    "device": "Dispositivo",
    "route": "Linea",
    "team": "Team"
  },
  "reportType": {
    "map": "Visualizzazione della mappa (per informazioni sulla puntualità)",
    "table": "Visualizzazione della tabella",
    "graph": "Visualizzazione grafica"
  },
  "endDate": "Data di fine:",
  "metric": "Parametro",
  "noFilters": "Nessun filtro",
  "startDate": "Data di inizio:",
  "timeInterval": "{start} - {end}",
  "type": "Tipo di report",
  "interval": "Periodo"
}
</i18n>

<i18n locale="pl">
{
  "filters": {
    "blocks": "Usługa:",
    "daysOfWeek": "Dni robocze:",
    "routes": "Trasy:",
    "stops": "Postoje:",
    "teams": "Zespoły:",
    "time": "Okres:",
    "tripHeadsigns": "Tablice elektroniczne:",
    "title": "Filtry"
  },
  "groupBy": {
    "title": "Grupa",
    "day": "Dzień",
    "stop": "Postój",
    "week": "Tydzień",
    "service": "Usługa",
    "trip": "Usługa",
    "device": "Urządzenie",
    "route": "Linia",
    "team": "Zespół"
  },
  "reportType": {
    "map": "Widok mapy (dla elementu punktualności)",
    "table": "Widok tabeli",
    "graph": "Przedstawienie graficzne"
  },
  "endDate": "Data końcowa:",
  "metric": "Metryka",
  "noFilters": "Brak filtrów",
  "startDate": "Data początkowa:",
  "timeInterval": "{start} - {end}",
  "type": "Typ raportu",
  "interval": "Okres"
}
</i18n>
