/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

/** @module Store */
import deepmerge from 'deepmerge';
import { createStore } from 'vuex';

import auth0, { untilAuthenticated } from '@/auth0';
import datadogLogs from '@/datadog';
import Api, { HistoryEventsPoller, EventsWebSocket } from '@/api';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { Role, permissionsByRole, routesByPermission } from '@/auth';

import activityLog from './activity-log';
import alerts from './alerts';
import devices, { devicesUpdatePlugin } from './devices';
import drivers from './drivers';
import gtfs from './gtfs';
import integrations from './integrations';
import loading from './loading';
import messages, { messagesHotInboxUpdatePlugin } from './messages';
import trips from './trips';
import vehicles from './vehicles';
import tripDetailed from './trip-detailed';

/**
 * @enum {string}
 * @deprecated use integrationTypes instead
 */
export const EmbeddedInterface = {
  AEP: 'aep',
  DUHIP: 'duhip',
  HANOVER: 'hanover',
  HANOVER_IP: 'hanover_ip',
  KC650: 'kc650',
  KUBA: 'kuba',
  LUMIPLAN: 'lumiplan',
  NMEA: 'nmea',
  QIP: 'qip',
  SILV1: 'silv1',
  CONDUENT: 'conduent',
};

const cache = {
  /** @type {HistoryEventsPoller} */
  poller: null,
  ws: null,
};

export const groupDefaults = Object.freeze({
  notifications: true,
  connection_advance: 0,
  deactivated_routes: [],
  delay_device_online: 120,
  delay_device_offline_visible: 300,
  driver_ontime_interval: [0, 300],
  driver_trip_format: '%dt - %th',
  incoming_distance_threshold: 200,
  retention_period: 36,
  private_routes: [],
  shape_distance_threshold: 300,
  stop_distance_threshold: 50,
  teams: [],
  trip_validation_rules: {
    start_delay_max: 1800,
    start_delay_min: -1800,
    start_distance_max: 1000,
    vk_percent_min: 80,
  },
  tz: 'Europe/Paris',
});

/**
 * Get date range with default interval.
 * @param {number} defaultInterval
 * @return {GTFSDateRange}
 */
const getDefaultDateRange = defaultInterval => {
  const date = new Date();
  const endDate = dateObjToGtfsFormat(date);
  date.setDate(date.getDate() - defaultInterval);
  const startDate = dateObjToGtfsFormat(date);
  return { startDate, endDate };
};

/** @typedef {typeof state & ModuleStates} State */
const defaultState = () => ({
  /** Raw group config as on the api server, used to put update */
  groupServerSide: /** @type {Group} */ ({}),

  /** @type {{[groupId: string]: GroupMinimal}} */
  groups: [],

  /** @type {string|undefined} */
  dateFirstPublish: undefined,

  /** @type {GTFSDateRange} */
  reportingDateRange: getDefaultDateRange(8),

  /**
   * All non archived teams, not limited to user.
   * @type {Array<Team>}
   */
  teams: [],

  user: /** @type {User} */ ({}),

  /** @type {{key:string, value: import('@/components/common/ModalUrgency.vue').Urgency } | Object} */
  urgencies: {},
});

/** @type {import('vuex').GetterTree<State, State>} */
const getters = {
  role(state, getters) {
    if (state.user.superuser) {
      return Role.SUPERUSER;
    }
    if (!getters.group._id || !state.user.roles?.[getters.group._id]) {
      return null;
    }
    return state.user.roles[getters.group._id];
  },

  permissions(_, getters) {
    return getters.role ? permissionsByRole[getters.role] : new Set();
  },

  hasPermission: (_, getters) => permission => getters.permissions.has(permission),

  isAuthorizedRoute(_, getters) {
    return route => {
      if (!getters.isAuthenticated) {
        return false;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const permission of getters.permissions) {
        if (permission && routesByPermission[permission] && routesByPermission[permission].has(route)) {
          return true;
        }
      }
      return false;
    };
  },

  isAuthenticated(state) {
    return !!state.user?.email;
  },

  groupIds: state => state.groups.map(({ _id }) => _id),

  /**
   * Group config with default values when missing from api
   * @return {Group}
   */
  group(state) {
    const group = deepmerge(groupDefaults, state.groupServerSide, {
      arrayMerge: (_, source) => source.slice(),
    });

    // Do some checks:
    // Replace hex colors on 3 characters by 6 (ex: #4a5 -> #44aa55)
    /**
     * @param {string} short - Short hex format (ex: #4a5)
     * @return {string} Long hex format (ex: #44aa55)
     */
    const getLongHexColor = short => {
      const re = /#(.)(.)(.)/;
      return short.replace(re, '#$1$1$2$2$3$3');
    };

    if (group.teams) {
      group.teams.forEach(t => {
        if (t.color?.length === 4) {
          t.color = getLongHexColor(t.color);
        }
      });
    }

    return group;
  },

  getDateFirstPublish(state) {
    return state.dateFirstPublish;
  },

  /**
   * @callback GetTeamNameByIdCallback
   * @param {string} teamId
   * @return {string}
   */
  /**
   * @return {GetTeamNameByIdCallback}
   */
  getTeamNameById: (state, getters) => teamId => {
    if (!getters.group?.teams) return teamId;
    const team = getters.group.teams.find(t => t.team_id === teamId);
    return team?.name ?? teamId;
  },

  activeTeams: (_, getters) => (getters.group.teams ? getters.group.teams.filter(t => !t.archived) : []),
};

/** @type {import('vuex').MutationTree<State>} */
const mutations = {
  updateUrgencies(state, urgencies) {
    urgencies.forEach(urgency => {
      if (!urgencies[urgency.urgency_id]) {
        state.urgencies[urgency.urgency_id] = urgency;
      } else {
        Object.assign(state.urgencies[urgency.urgency_id], urgency);
      }
    });
  },

  dateFirstPublish(state, date) {
    state.dateFirstPublish = date;
  },

  reset(state) {
    Object.assign(state, defaultState());
  },

  patchGroup(state, updates) {
    Object.entries(updates).forEach(([key, value]) => {
      if (value === undefined && !(state.groupServerSide[key] === undefined)) {
        delete state.groupServerSide[key];
      } else {
        state.groupServerSide[key] = value;
      }
    });
  },

  setGroup(state, group) {
    state.groupServerSide = group;

    // Add notifications to group settings, handled in local storage
    state.groupServerSide.notifications = !localStorage.getItem('settings.op.disable_notif');
    localStorage.setItem('settings.op.lastGroupId', group._id);
    datadogLogs.logger.addContext(GroupRoute.GROUP, group._id);
  },

  setGroups(state, groups) {
    state.groups = groups;
  },

  setReportingDateRange(state, dateRange) {
    state.reportingDateRange = dateRange;
  },

  /** @param {Array<Team>} teams */
  setTeams(state, teams) {
    state.teams = teams;
  },

  setTravelTimeRadius(state, radius) {
    state.travelTimeRadius = radius;
  },

  setUser(state, user) {
    state.user = user;
  },
};

/** @type {import('vuex').ActionTree<State, State>} */
const actions = {
  /**
   * Start listening for urgency alerts.
   * @param context
   */
  async startWS({ state, commit, dispatch, getters }) {
    await dispatch('stopWS');
    if (getters.group._id == null) {
      throw new Error("Can't start WebSocket without a group id");
    }
    cache.ws = new EventsWebSocket(getters.group._id, data => {
      data.forEach(urgency => {
        urgency.localStatus = 'open';
      });
      commit('updateUrgencies', data);
    });
  },

  /**
   * Stop listening for urgency alerts.
   */
  async stopWS() {
    if (cache.ws) {
      cache.ws.close();
      cache.ws = null;
    }
  },

  async logout({ commit }) {
    commit('reset');
    if (localStorage.getItem('byAuth0')) {
      await auth0.logout({ openUrl: false });
      localStorage.removeItem('byAuth0');
      return;
    }
    await Api.logout();
  },

  async authenticate({ state, commit, getters, dispatch }) {
    if (getters.isAuthenticated) {
      return true;
    }
    if (localStorage.getItem('byAuth0')) {
      await untilAuthenticated(200);
      if (!auth0.isAuthenticated.value) {
        return false;
      }
      if (auth0.user['pysae/superuser']) {
        const user = auth0.user;
        user.superuser = true;
        commit('setUser', user);
        datadogLogs.addContext('user', user);
        return true;
      }
    }
    try {
      const user = await Api.checkAuth();
      commit('setUser', user);
      datadogLogs.logger.addContext('user', user);
    } catch {
      return false;
    }
    return true;
  },

  /**
   * Clear modules state.
   * @param context
   * @return {Promise<Array<void>>}
   */
  clearModules({ dispatch }) {
    return Promise.all([
      dispatch('alerts/clear'),
      dispatch('devices/clear'),
      dispatch('gtfs/clear'),
      dispatch('messages/clear'),
      dispatch('trips/clear'),
    ]);
  },

  /**
   * Get current group (and wait if none defined).
   * @param context
   * @return {Promise<Group>}
   */
  async getGroup({ state, getters }) {
    // TODO: [Clean] Change loading/waiting mechanism
    if (getters.group._id && !state.loading.loading.group) {
      return getters.group;
    }
    await new Promise(resolve => {
      (function wait() {
        if (getters.group._id && !state.loading.loading.group) resolve();
        else setTimeout(wait, 10);
      })();
    });

    return getters.group;
  },

  /**
   * Change group.
   * @param context
   * @param {string} groupId
   * @return {Promise<Group>}
   */
  async groupChange({ commit, dispatch, getters }, groupId) {
    if (getters.group._id === groupId) return;
    await dispatch('loading/throttleLoading', {
      key: 'group',
      callback: async () => {
        try {
          const group = await Api.getGroup(groupId);
          await dispatch('clearModules');
          commit('setGroup', group);

          await dispatch('stopPoller');

          // get the older gtfs for a group
          const gtfsFiles = await Api.gtfs.getGtfs(group._id);
          const dateFirstPublish = gtfsFiles.reduce((prev, current) => {
            if (!prev) return current.mod_time;
            return prev < current.mod_time ? prev : current.mod_time;
          }, null);
          commit('dateFirstPublish', dateFirstPublish);
        } catch {
          return;
        }
        if (getters.group._id) {
          dispatch('devices/getDevices');
          dispatch('integrations/loadData');
          dispatch('startPoller');
          dispatch('messages/resetNotifications');
          dispatch('messages/setHotInboxMessages');
          dispatch('startWS');
        }
      },
    });

    return getters.group;
  },

  async groupPatch({ state, commit }, { target, updates }) {
    commit('patchGroup', updates);
    await Api.group.patchGroup(state.groupServerSide._id, target, updates);
  },

  async groupPatchStateOnly({ commit }, updates) {
    commit('patchGroup', updates);
  },

  /**
   * Update current group.
   */
  async groupUpdate({ state, commit, getters }) {
    if (!getters.group._id) return;
    const group = await Api.getGroup(getters.group._id);
    commit('setGroup', group);
  },

  /**
   * Update groups list.
   * @param context
   * @return {Promise<{[groupId: string]: {_id: string, name: string}}>} Map<GroupId, Group>
   */
  async groupsUpdate({ state, commit, dispatch }) {
    await dispatch('loading/throttleLoading', {
      key: 'groups',
      callback: async () => {
        const groups = await Api.getGroups();

        commit(
          'setGroups',
          groups.sort((a, b) => (a.name > b.name ? 1 : -1))
        );
      },
    });
  },

  /**
   * Load teams list.
   * @param context
   * @return {Promise<void>}
   */
  async loadTeams({ getters, commit, dispatch }) {
    await dispatch('loading/throttleLoading', {
      key: 'teams',
      callback: async () => {
        const teams = await Api.getTeams(getters.group._id);
        commit('setTeams', teams);
      },
    });
  },

  /**
   * Start listening for updates.
   * @param context
   */
  async startPoller({ state, dispatch, getters }) {
    await dispatch('stopPoller');
    if (getters.group._id == null) {
      throw new Error("Can't start HistoryEventsPoller without a group id");
    }
    cache.poller = new HistoryEventsPoller(getters.group._id, data => {
      dispatch('trips/updateVkHistories', data);
      dispatch('activityLog/updateEntries', data);
      dispatch('devices/updateDevices', data);
    });
    await cache.poller.open();
  },

  /**
   * Stop listening for updates.
   */
  async stopPoller() {
    if (cache.poller) {
      cache.poller.close();
      cache.poller = null;
    }
  },

  /**
   * @param context
   * @param {User} user
   */
  async setUser({ commit }, user) {
    await Api.putUser(user);
    commit('setUser', user);
  },
};

/**
 * @typedef {Object} ModuleStates
 * @property {import('./activity-log').State} activityLog
 * @property {import('./alerts').State} alerts
 * @property {import('./devices').State} devices
 * @property {import('./drivers').State} drivers
 * @property {import('./gtfs').State} gtfs
 * @property {import('./integrations').State} integrations
 * @property {import('./loading').State} loading
 * @property {import('./messages').State} messages
 * @property {import('./trips').State} trips
 * @property {import('./vehicles').State} vehicles
 */
/** @typedef {typeof modules} Modules */
const modules = {
  activityLog,
  alerts,
  devices,
  drivers,
  gtfs,
  integrations,
  loading,
  messages,
  trips,
  vehicles,
  tripDetailed,
};

/** @typedef {import('vuex').Store<State>} Store */
const store = createStore({
  strict: import.meta.env.NODE_ENV === 'development',
  state: defaultState(),
  getters,
  mutations,
  actions,
  modules,

  plugins: [devicesUpdatePlugin, messagesHotInboxUpdatePlugin],
});

export default store;

if (import.meta.env.NODE_ENV === 'development') {
  window.Store = store;
}

/**
 * @typedef {Object} DriverMessage
 * @property {string} [color] - Deprecated
 * @property {string} message
 */

/**
 * @typedef {Object} Group
 * @property {string} _id
 * @property {GeoJSON.GeoJSON} bounds
 * @property {string} current_file
 * @property {string} group_id
 * @property {string} name
 * @property {number} next_state_load
 * @property {string} tz
 * @property {Object} [categories]
 * @property {string} [color]
 * @property {number} [connection_advance]
 * @property {object} [contact]
 * @property {number} [delay_device_online]
 * @property {number} [delay_device_offline_visible]
 * @property {string} [driver_call_number]
 * @property {Array<DriverMessage>} [driver_message_values]
 * @property {Array<number>} [driver_ontime_interval]
 * @property {boolean} [driver_option_messages_block_send]
 * @property {boolean} [notifications]
 * @property {boolean} [driver_stamp_button]
 * @property {string} [driver_trip_format]
 * @property {number} [incoming_distance_threshold]
 * @property {string} [logo_url]
 * @property {Array<string>} [deactivated_routes]
 * @property {Array<string>} [private_routes]
 * @property {string} [map_vehicle_tooltip_pattern]
 * @property {boolean} [option_driver_break] - deprecated
 * @property {EmbeddedInterface} [embedded_interface]
 * @property {boolean} [pub]
 * @property {boolean} [punctuality_check] - deprecated
 * @property {number} [retention_period]
 * @property {number} [shape_distance_threshold]
 * @property {number} [stop_distance_threshold]
 * @property {{[stopId: string]: number}} [stop_distance_threshold_exceptions]
 * @property {Array<Team>} [teams]
 * @property {TripListConfiguration} [trip_list_configuration]
 * @property {TripValidationRules} [trip_validation_rules]
 * @property {string} [date_first_publish]
 */

/**
 * @typedef {Object} GroupMinimal
 * @property {string} _id
 * @property {string} name
 * @property {string} color
 */

/**
 * @typedef {Object} GTFSDateRange
 * @property {string} endDate
 * @property {string} startDate
 */

/**
 * @typedef {Object} OperationWarning
 * @property {boolean} active
 * @property {number} [threshold]
 */

/**
 * @typedef {Object} Team
 * @property {string} team_id
 * @property {string} name
 * @property {string} color
 * @property {boolean} archived
 */

/**
 * @typedef {Object} TripListConfiguration
 * @property {{[key in import('@/pages/TripsListPage/Trips.conf').ColumnTypes]: boolean}} [merging]
 */

/**
 * @typedef {Object} TripValidationRules
 * @property {number} [start_delay_max]
 * @property {number} [start_delay_min]
 * @property {number} [start_distance_max]
 * @property {number} [vk_percent_min]
 */

/**
 * @typedef {Object} UpcomingFiles
 * @property {string} current_file
 * @property {number} setup_ts
 * @property {number} ts
 * @property {string} user_id
 */

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} _id
 * @property {string} email - Same as `_id`
 * @property {number} date_added
 * @property {string} [name]
 * @property {boolean} [superuser]
 * @property {Array<RoleObject>} roles
 */

/**
 * @typedef {Object} RoleObject
 * @property {string} role
 * @property {string} group_id
 */
