/**
 * Simplified definition of a Deferred class.
 * @template T
 * @property {Promise<T>} promise
 * @property {(value?: T | PromiseLike<T>) => void} resolve
 * @property {(reason?: any) => void} reject
 */
class Deferred {
  constructor() {
    this.promise = /** @type {Promise<T>} */ (
      new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    );

    Object.freeze(this);
  }
}

export default Deferred;
