<template>
  <div class="settings-passenger-count">
    <div class="form-group">
      <div>
        <span class="form-group__label">{{ $t('driver_passenger_count_custom_boarding') }}</span>
        <p class="form-group__sub-label">
          {{ $t('driver_passenger_count_custom_boarding-help') }}
        </p>
      </div>

      <div
        v-for="(customBoarding, index) in values.driver_passenger_count_custom_boarding"
        :key="index"
        class="input-group"
      >
        <input
          v-model="values.driver_passenger_count_custom_boarding[index]"
          class="form-group__input form-group__input--inline"
          name="driver_passenger_count_custom_boarding"
          required
          type="text"
        />

        <div class="btn-group">
          <Btn
            class="btn"
            type="secondary"
            :disabled="index === 0"
            @click="customBoardingMoveUp(index)"
            @mousedown.prevent
          >
            <font-awesome-icon icon="fa-arrow-up" />
          </Btn>
          <Btn
            class="btn"
            type="secondary"
            :disabled="index === values.driver_passenger_count_custom_boarding.length - 1"
            @click="customBoardingMoveDown(index)"
            @mousedown.prevent
          >
            <font-awesome-icon icon="fa-arrow-down" />
          </Btn>
          <Btn class="btn" type="secondary" @click="customBoardingRemove(index)" @mousedown.prevent>
            <font-awesome-icon icon="fa-minus" />
          </Btn>
        </div>
      </div>

      <Btn type="secondary" @click="customBoardingAdd">
        <font-awesome-icon icon="fa-plus" />
      </Btn>
    </div>

    <Btn type="primary" :disabled="!hasChanged || hasError" @click="saveInfos">
      <font-awesome-icon icon="fa-floppy-disk" />
      <span>{{ $t('save') }}</span>
    </Btn>
  </div>
</template>

<script>
import Btn from '@/components/ui/Btn.vue';

import SettingsMixin, { ConfigTarget } from './SettingsMixin';

export default {
  name: 'PassengerCount',
  components: { Btn },
  mixins: [SettingsMixin],

  computed: {
    /** @return {boolean} */
    hasError() {
      return Object.values(this.values.driver_passenger_count_custom_boarding).some(
        customBoarding => customBoarding === ''
      );
    },
  },

  methods: {
    /**
     * Returns default values
     *
     * @returns {Object}
     */
    getDefaultValues() {
      return {
        /** @type {Array<string>} */
        driver_passenger_count_custom_boarding: [],
      };
    },

    customBoardingAdd() {
      this.values.driver_passenger_count_custom_boarding.push('');
    },

    /** @param {number} index */
    customBoardingMoveDown(index) {
      this.values.driver_passenger_count_custom_boarding.splice(
        index + 1,
        0,
        ...this.values.driver_passenger_count_custom_boarding.splice(index, 1)
      );
    },

    /** @param {number} index */
    customBoardingMoveUp(index) {
      this.values.driver_passenger_count_custom_boarding.splice(
        index - 1,
        0,
        ...this.values.driver_passenger_count_custom_boarding.splice(index, 1)
      );
    },

    /** @param {number} index */
    customBoardingRemove(index) {
      this.values.driver_passenger_count_custom_boarding.splice(index, 1);
    },
    saveInfos() {
      this.save(ConfigTarget.DRIVER_APP);
    },
  },
};
</script>

<style lang="scss">
.settings-passenger-count {
  .btn-group {
    height: 34px;
    margin-bottom: 2px;
    margin-left: 0.5em;

    > .ui-btn {
      padding: 0 10px;
    }
  }

  .input-group {
    padding-bottom: 5px;
  }
}
</style>

<i18n locale="fr">
{
  "driver_passenger_count_custom_boarding": "Montées spécifiques",
  "driver_passenger_count_custom_boarding-help": "Définit les compteurs supplémentaires ajoutés dans l’interface de comptage de Driver."
}
</i18n>

<i18n locale="en">
{
  "driver_passenger_count_custom_boarding": "Custom boarding",
  "driver_passenger_count_custom_boarding-help": "Add custom boarding fields to show in Driver application."
}
</i18n>
