/* eslint-disable no-use-before-define */

const DEG_LENGTH = 40075e3 / 360;

/**
 * Create a polygon to simulate a circle of a given radius.
 * Until 'Circle' becomes a valid GeoJSON Feature.
 * @param {[number, number]} lngLat
 * @param {number} radius - in meters.
 * @return {GeoJSON.Polygon}
 */
function createGeoJSONCircle(lngLat, radius) {
  const NB_EDGES = 64;
  const coords = /** @type {Array<GeoJSON.Position>} */ ([]);

  const distanceX = radius / 1000 / (111.32 * Math.cos((lngLat[1] * Math.PI) / 180));
  const distanceY = radius / 1000 / 110.574;

  for (let i = 0; i < NB_EDGES; i += 1) {
    const theta = (i / NB_EDGES) * (2 * Math.PI);
    const x = distanceX * Math.cos(theta);
    const y = distanceY * Math.sin(theta);

    coords.push([lngLat[0] + x, lngLat[1] + y]);
  }
  coords.push(coords[0]);

  return {
    type: 'Polygon',
    coordinates: [coords],
  };
}

/**
 * Compute distance between 2 coordinates in meters.
 * @param {LatLng} coord1
 * @param {LatLng} coord2
 * @return {number}
 */
function distanceCoords(coord1, coord2) {
  return segmentInfo(coord1, coord2).distance;
}

/**
 * Compute distance and bearing between two coordinates
 * @param {LatLng} startCoords
 * @param {LatLng} endCoords
 */
function segmentInfo(startCoords, endCoords) {
  const lngFact = Math.cos((((startCoords.lat + endCoords.lat) / 2) * Math.PI) / 180);
  const dx = (endCoords.lng - startCoords.lng) * lngFact;
  const dy = endCoords.lat - startCoords.lat;
  return {
    distance: Math.sqrt(dx ** 2 + dy ** 2) * DEG_LENGTH,
    bearing: (-Math.atan2(dy, dx) * 180) / Math.PI + 90,
  };
}

export { createGeoJSONCircle, distanceCoords, segmentInfo };

/**
 * @typedef LatLng
 * @property {number} lat
 * @property {number} lng
 */
