/** @module */

/**
 * Convert an array (rows) of array (columns) to a csv file.
 * @param {Array<Array<string>>} data
 * @return {string} Blob URL.
 */
export function toCSV(data) {
  // create content
  let csvContent = '';
  data.forEach(rowArray => {
    const row = rowArray.join(';');
    csvContent += `${row}\r\n`;
  });

  // to blob
  const blob = new Blob(['\ufeff', csvContent]);

  // create url
  return URL.createObjectURL(blob);
}

/**
 * create a false element to trigger blob download with specified name
 * @param {*} url url of blob file
 * @param {*} name desired name of file
 */
export function triggerDownloadCSV(url, name) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
}
