<template>
  <div class="new-reports-punctuality">
    <div v-if="isLoading">
      <AnimatedDots v-if="isLoading" class="reports__loading reports__loading-dots" :type-v2="true" />
      <span class="reports__loading reports__loading-text">{{ $t('isLoading') }}</span>
    </div>
    <NoReportData v-else-if="!hasData" />
    <template v-else>
      <div class="reports__abstract">
        <NumberDisplay :title="$t('totalRecorded')" :number="calcTotalStopTimes" />
        <RadialBarChart :title="$t('averagePercent')" :value="calcGlobalPercentage" />
      </div>
      <div class="reports__barcharts">
        <BarChart3
          :height="chartHeight"
          :options="dayChart.options"
          :categories="dayChart.categories"
          :series="dayChart.series"
          :title="$t('chartDayTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />

        <BarChart3
          :height="chartHeight"
          :options="routeChart.options"
          :categories="routeChart.categories"
          :series="routeChart.series"
          :title="$t('chartRouteTitle')"
          :width="chartWidth"
          :yaxis-title="$t('yaxisTitle')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { stats as ApiStats } from '@/api';
import { dateGtfsFormatToObj } from '@/libs/helpers/dates';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import NumberDisplay from '@/components/ui/NumberDisplay.vue';
import NoReportData from '@/components/ui/NoReportData.vue';

import RadialBarChart from '@/components/ui/RadialBarChart.vue';
import BarChart3 from '@/components/ui/BarChart3.vue';

import { PunctualityReportsHelper, SeriesCorrespondance } from './PunctualityReportsHelper.js';

export default {
  name: 'NewReportsPunctuality',

  components: {
    AnimatedDots,
    NumberDisplay,
    RadialBarChart,
    NoReportData,
    BarChart3,
  },

  props: {
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },
    chartHeight: {
      type: Number,
      default: 620,
    },
    /** @type {Object} */
    chartWidth: {
      type: Number,
      default: 1200,
    },
  },

  emits: ['hasData', 'isDataLoading'],

  data: () => ({
    /** @type {Array<import('@/api').PunctualityByRoute>} */
    dataGroupedByRoute: null,
    /** @type {Array<import('@/api').PunctualityByDay>} */
    dataGroupedByDay: null,
    isLoading: false,

    dayChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },

    routeChart: {
      series: [],
      categories: [],
      options: {},
      data: [],
    },
  }),

  computed: {
    /**
     * Return total of all stop times on period
     * @returns {number}
     */
    calcTotalStopTimes() {
      if (!this.hasData) return 0;
      let sum = 0;
      this.dataGroupedByDay.forEach(day => {
        sum += day.total_count;
      });
      return sum;
    },

    /**
     * Return global punctuality percentage on period
     * @returns {number}
     */
    calcGlobalPercentage() {
      if (!this.hasData) return 0;
      let sum = 0;
      let total = 0;
      this.dataGroupedByDay.forEach(day => {
        sum += day.on_time_percentage * day.total_count;
        total += day.total_count;
      });
      const average = sum / total;
      return parseFloat(average.toFixed(0));
    },

    hasData() {
      return this.dataGroupedByDay?.length > 0 && this.dataGroupedByRoute?.length > 0;
    },

    groupId() {
      return this.$store.getters.group.group_id;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },

    dataGroupedByDay: {
      deep: true,
      handler() {
        this.dayChart.data = this.dataGroupedByDay;
        this.dayChart.series = PunctualityReportsHelper.getSeries(this.dataGroupedByDay);
        this.dayChart.options = this.getOptions(this.dataGroupedByDay, 'day');
        this.dayChart.categories = this.dayChart.data.map(el =>
          this.$d(dateGtfsFormatToObj(el.start_date), 'dayMonth').replace(/\./g, '')
        );
      },
    },

    dataGroupedByRoute: {
      deep: true,
      handler() {
        this.routeChart.data = this.dataGroupedByRoute;
        this.routeChart.series = PunctualityReportsHelper.getSeries(this.dataGroupedByRoute);
        this.routeChart.options = this.getOptions(this.dataGroupedByRoute, 'route');
        this.routeChart.categories = this.routeChart.data.map(el => el.route_short_name);
      },
    },
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      this.$emit('isDataLoading', true);

      const from = this.dateInterval.start;
      const to = this.dateInterval.end;

      this.dataGroupedByRoute = await ApiStats.getPunctualityStats(this.groupId, from, to, 'route');
      this.dataGroupedByDay = await ApiStats.getPunctualityStats(this.groupId, from, to, 'day');

      this.isLoading = false;
      this.$emit('hasData', this.hasData);
      this.$emit('isDataLoading', false);
    },

    /**
     * Options for ApexCharts
     * @param {Array<import('@/api').PunctualityByRoute | import('@/api').PunctualityByDay>} data
     * @param {'day'|'route'} chartType
     * @return {Object}
     */
    getOptions(data, chartType) {
      const genericOptions = {
        chart: {
          stackType: '100%',
        },
        yaxis: {
          max: Math.max(...data.map(el => el.total_count)),
        },
        dataLabels: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            const entry = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            if (!entry) return null;
            const key = `${[SeriesCorrespondance[seriesIndex]]}_percentage`;

            return val === 0 ? '' : `${parseFloat(entry[key].toFixed(2))} %`;
          },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            const focusedData = data[dataPointIndex + w.config.page * w.config.barsPerScreen];
            const key = SeriesCorrespondance[seriesIndex];
            return PunctualityReportsHelper.createTooltip(focusedData, key, chartType === 'route');
          },
        },
      };
      return genericOptions;
    },
  },
};
</script>

<style lang="scss">
.new-reports-punctuality {
  background-color: $canvas;

  .apexcharts-custom-tooltip {
    &__title {
      margin-bottom: 8px;
      font-weight: $font-weight-semi-bold;
      text-align: center;

      &.too_early {
        color: $danger;
      }

      &.on_time {
        color: $primary-light;
      }

      &.too_late {
        color: $warn;
      }
    }

    &__subtitle {
      margin-top: 8px;
      font-weight: $font-weight-semi-bold;
    }
  }
}
</style>

<i18n locale="fr">
{
  "totalRecorded": "Total des passages aux arrêts enregistrés",
  "averagePercent": "Ponctualité",
  "chartDayTitle": "Ponctualité par jour",
  "chartRouteTitle": "Ponctualité par ligne",
  "stopsRecorded": "Nombre de passage aux arrêts : {0}",
  "punctualityPercent": "Pourcentage : {0} %",
  "isLoading": "Le téléchargement des données peut prendre du temps. Veuillez patienter",
  "yaxisTitle": "%",
  "on_time": "À l'heure",
  "too_early": "En avance",
  "too_late": "En retard"
}
</i18n>

<i18n locale="en">
{
  "totalRecorded": "Total of recorded stops times",
  "averagePercent": "Punctuality",
  "chartDayTitle": "Punctuality per day",
  "chartRouteTitle": "Punctuality per route",
  "stopsRecorded": "Number of passage at stop : {0}",
  "punctualityPercent": "Percent : {0} %",
  "isLoading": "Please wait while the data is loaded",
  "yaxisTitle": "%",
  "on_time": "On time",
  "too_early": "Early",
  "too_late": "Late"
}
</i18n>
