<template>
  <div class="transport-plan">
    <div class="transport-plan__header">
      <Btn type="primary" @click="addNewTransportPlan">
        <i class="fas fa-plus" aria-hidden="true"></i>
        <span class="add-transport-plan-text">{{ $t('addGtfs.newGtfs') }}</span>
      </Btn>
    </div>

    <DataGridVuetify
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('gtfs', { count: renderedDataLength })"
      :data="gtfsListFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :show-header="false"
      :tabs="tabs"
    >
      <template #actions="propsAction">
        <ActionCell
          :actions="getActionsToShow(propsAction.object)"
          :object="propsAction.object"
          @archive="showModal(ModalType.ARCHIVE, propsAction.object)"
          @cancelPublication="showModal(ModalType.CANCEL_PUBLICATION, propsAction.object)"
          @copy="showModal(ModalType.DUPLICATE, propsAction.object)"
          @download="showModal(ModalType.DOWNLOAD, propsAction.object)"
          @edit="redirectToEditor(propsAction.object._id)"
          @publish="onPublishClick(propsAction.object)"
          @restore="showModal(ModalType.ARCHIVE, propsAction.object)"
          @view="redirectToEditor(propsAction.object._id)"
        />
      </template>
    </DataGridVuetify>

    <Modal v-if="modalShown === ModalType.NEW_GTFS" @close="closeModal()">
      <template #title>
        {{ $t('addGtfs.newGtfs') }}
      </template>
      <template #body>
        <label class="form-group__label" for="name">{{ $t('addGtfs.name') }}</label>
        <input
          id="name"
          v-model="gtfsName"
          class="form-group__input"
          :placeholder="$t('addGtfs.newGtfs')"
          type="text"
        />
        <!-- TODO : hidden block waiting for #817 Add gtfs import feature -->
        <template v-if="false">
          <div>{{ $t('addGtfs.or') }}</div>
          <Btn type="secondary" class="ui-btn--fullwidth">
            <font-awesome-icon icon="fa-folder-open" />
            {{ $t('addGtfs.import') }}
          </Btn>
        </template>
      </template>
      <template #cta>
        <Btn
          type="primary"
          class="ui-btn--fullwidth"
          :disabled="gtfsName.length === 0"
          @click="submitModalAddGtfs"
        >
          {{ $t('addGtfs.create') }}
        </Btn>
      </template>
    </Modal>

    <Modal v-if="modalShown === ModalType.DUPLICATE" @close="closeModal()">
      <template #title>
        {{ $t('duplicateGtfs.duplicating') }}
      </template>
      <template #body>
        <section class="schedule__duplicate-modal-body">
          <label class="form-group__label" for="name">{{ $t('duplicateGtfs.copyName') }}</label>
          <input id="name" v-model="gtfsName" class="form-group__input" type="text" />
        </section>
      </template>
      <template #cta>
        <Btn type="primary" :data-loading="loading" @click="submitModalDuplicateGtfs">
          <span v-if="!loading">{{ $t('duplicateGtfs.duplicate') }}</span>
          <span v-else>
            <font-awesome-icon icon="fa-circle-notch" />
            {{ $t('duplicateGtfs.duplicateLoading') }}
          </span>
        </Btn>
      </template>
    </Modal>

    <ModalDownload
      v-if="modalShown === ModalType.DOWNLOAD"
      :group-id="group.group_id"
      :gtfs-id="selectedGtfs._id"
      @close="closeModal"
    />

    <ModalArchiveRestore
      v-if="ModalType.ARCHIVE === modalShown"
      :group-id="group.group_id"
      :gtfs="selectedGtfs"
      @close="closeModal"
      @closeWithRefresh="closeModalWithRefresh"
    />

    <ModalPublication
      v-if="[ModalType.PUBLISH, ModalType.WARNING].includes(modalShown)"
      :mode="modalShown"
      :gtfs="selectedGtfs"
      @close="closeModal"
      @publishGtfs="publishOrScheduleGtfs"
      @redirectToEditor="redirectToEditor"
    />

    <ModalCancelPublication
      v-if="modalShown === ModalType.CANCEL_PUBLICATION"
      :group-id="group.group_id"
      :gtfs="selectedGtfs"
      @close="closeModal"
      @closeModalCancelPublication="closeModalWithRefresh"
    />
  </div>
</template>

<script>
import api from '@/api';
import Modal from '@/components/layout/Modal.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import { ColumnKey, getDatagrid } from '@/pages/TransportPlanListPage/TransportPlanList.conf.js';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

import ModalArchiveRestore from './ModalArchiveRestore.vue';
import ModalCancelPublication from './ModalCancelPublication.vue';
import ModalDownload from './ModalDownload.vue';
import ModalPublication from './ModalPublication.vue';
import { TransportPlanStatus } from './StatusCell.vue';
import { TransportPlanType } from './TypeCell.vue';

/** @enum {string} */
export const ModalType = {
  ARCHIVE: 'archive',
  CANCEL_PUBLICATION: 'cancelPublication',
  DOWNLOAD: 'download',
  DUPLICATE: 'copy',
  NEW_GTFS: 'newGtfs',
  PUBLISH: 'publish',
  RESTORE: 'restore',
  WARNING: 'warning',
};

/** @enum {string} */
const GtfsPublicationUser = {
  SYSTEM: 'system@pysae.com',
};

/** @enum {string} */
export const Tabs = {
  CURRENT: 'current',
  ARCHIVE: 'archived',
};

/** @enum {string} */
export const ArchiveStatus = {
  ARCHIVED: 'archived',
  NOT_ARCHIVED: 'notArchived',
};

export default {
  name: 'TransportPlanList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    Modal,
    ModalArchiveRestore,
    ModalCancelPublication,
    ModalDownload,
    ModalPublication,
  },

  data() {
    return {
      ArchiveStatus,
      ColumnKey,
      ModalType,
      Tabs,
      TransportPlanStatus,

      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {Array<import('@/store/gtfs').Publication>} */
      gtfsHistory: [],
      /** @type {Array<import('@/api').GtfsSchedule>} */
      gtfsList: [],
      /** @type {Array<GtfsFormatted>} */
      gtfsListFormatted: [],
      /** @type {Array<string>} */
      statusCategories: Object.values(Tabs),
      /** @type {string} */
      gtfsName: '',
      /** @type {Boolean} */
      loading: true,
      /** @type {ModalType} */
      modalShown: '',
      /** @type {GtfsFormatted} */
      selectedGtfs: null,
      /** @type {string} */
      selectedGtfsId: '',
      /** @type {Tabs} */
      selectedTab: Tabs.CURRENT,
      /** @type {number} */
      renderedDataLength: null,
    };
  },

  computed: {
    /**
     * @return {String}
     */
    editorBaseUrl() {
      return `/editor2/#/${this.$store.getters.group.group_id}`;
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
    tabs() {
      return this.statusCategories.map(category => ({
        value: category,
        name: this.$t(`tabs.${category}`),
        counter: null,
        dataList: [],
        filterField: ColumnKey.ARCHIVED,
        filterValues: Tabs.ARCHIVE === category ? [ArchiveStatus.ARCHIVED] : [ArchiveStatus.NOT_ARCHIVED],
        isDefaultActive: Tabs.CURRENT === category,
        icon: Tabs.ARCHIVE === category ? 'fa:fas fa-archive' : '',
      }));
    },
  },

  created() {
    this.onCreate();
  },

  methods: {
    addNewTransportPlan() {
      this.showModal(ModalType.NEW_GTFS, null);
    },

    closeModal() {
      this.modalShown = '';
      this.gtfsName = '';
      this.selectedGtfs = null;
      this.selectedGtfsId = '';
    },

    /**
     * @param {GtfsFormatted["_id"]} [elementIdToScrollTo]
     * @param {boolean} [onPublication]
     */
    async closeModalWithRefresh(elementIdToScrollTo, onPublication = false) {
      this.loading = true;
      this.closeModal();
      if (onPublication) {
        await this.$store.dispatch('groupUpdate');
      }
      await this.loadGtfsList();
      this.formatGtfsList();
      this.loading = false;
      if (elementIdToScrollTo) {
        // Timeout necessary to make animation work
        setTimeout(() => {
          this.scrollToElement(elementIdToScrollTo);
        }, 500);
      }
    },

    async loadGtfsHistory() {
      /** @type {Array<import('@/store/gtfs').Publication} */
      this.gtfsHistory = await api.gtfs.getGtfsPublications(this.$store.getters.group.group_id);
    },

    async loadGtfsList() {
      /** @type {Array<import('@/api').GtfsSchedule>} */
      this.gtfsList = await api.gtfs.getGtfs(this.$store.getters.group.group_id);
    },

    formatGtfsList() {
      const list = this.gtfsList.map(gtfs => {
        const gtfsStatus = this.findGtfsStatus(gtfs);
        const type = this.findGtfsType(gtfs);

        // TODO: Tornado format backward compatibility. To be removed to use gtfs.mod_time directly.
        const modTime = gtfs.mod_time.$date ?? gtfs.mod_time;
        let publicationTime = gtfs.published ? new Date(gtfs.published * 1000) : null;
        if (gtfs.scheduled) {
          publicationTime = new Date(gtfs.scheduled * 1000);
        }

        /** @type {Array<GtfsFormatted>} */
        return {
          _id: gtfs._id,
          archived: gtfs.archived && gtfs.archived > 0 ? ArchiveStatus.ARCHIVED : ArchiveStatus.NOT_ARCHIVED,
          name: gtfs.name,
          publicationTime,
          modificationTime: new Date(modTime) || null,
          status: gtfsStatus,
          type: type ?? '-',
        };
      });
      this.gtfsListFormatted = list;
    },

    /** Return the publication status of a gtfs
     * @param  {import('@/api').GtfsSchedule} gtfs
     * @return {TransportPlanStatus}
     */
    findGtfsStatus(gtfs) {
      const currentDate = new Date().getTime() / 1000;
      if (this.$store.getters.group.current_file === gtfs._id) {
        return TransportPlanStatus.ACTIVE;
      }
      if (gtfs.scheduled < currentDate && gtfs.published) {
        return TransportPlanStatus.OVER;
      }
      if (
        (gtfs.scheduled && gtfs.archived === ArchiveStatus.ARCHIVED) ||
        (gtfs.published && gtfs.archived === ArchiveStatus.ARCHIVED)
      ) {
        return TransportPlanStatus.OVER;
      }
      if (gtfs.scheduled) {
        return TransportPlanStatus.PENDING;
      }

      return TransportPlanStatus.DRAFT;
    },

    findGtfsType(gtfs) {
      if (gtfs?.mod_user === GtfsPublicationUser.SYSTEM) {
        return TransportPlanType.AUTO;
      }
      if (gtfs.scheduled) {
        return TransportPlanType.PLANNED;
      }
      if (gtfs.published) {
        return TransportPlanType.MANUAL;
      }

      return null;
    },

    getActionsToShow(object) {
      const actions = ['download'];
      if (object.archived === ArchiveStatus.ARCHIVED) {
        actions.push('restore');
      } else if (
        object.archived === ArchiveStatus.NOT_ARCHIVED &&
        ![TransportPlanStatus.ACTIVE, TransportPlanStatus.PENDING].includes(object.status)
      ) {
        actions.push('archive');
      }
      if (object.status === TransportPlanStatus.ACTIVE) {
        actions.push('view', 'copy');
      } else if (object.status === TransportPlanStatus.OVER) {
        actions.push('view', 'copy', 'publish');
      } else if (object.status === TransportPlanStatus.DRAFT) {
        actions.push('edit', 'copy', 'publish');
      } else if (object.status === TransportPlanStatus.PENDING) {
        actions.push('cancelPublication', 'view', 'copy');
      }
      return actions;
    },

    async onCreate() {
      this.loading = true;

      await this.loadGtfsList();
      await this.loadGtfsHistory();
      this.formatGtfsList();

      this.loading = false;
    },

    onPublishClick(item) {
      this.showModal(ModalType.PUBLISH, item);
    },

    /** Publish or schedule the publication of a gtfs
     * @param {PublicationDetails} publicationDetails
     */
    async publishOrScheduleGtfs(publicationDetails) {
      try {
        if (publicationDetails.scheduledPublication === false) {
          await api.gtfs.publishGtfs(this.group._id, this.selectedGtfs._id);
        } else {
          const hours = publicationDetails.startHourValue.split(':');
          const scheduleTime = publicationDetails.startDateValue.setHours(
            parseInt(hours[0]),
            parseInt(hours[1]),
            parseInt(hours[2])
          );
          await api.gtfs.scheduleGtfs(this.group._id, this.selectedGtfs._id, new Date(scheduleTime));
        }

        await this.closeModalWithRefresh(null, true);
      } catch {
        this.modalShown = this.ModalType.WARNING;
      }
    },

    /**
     * Redirect to editor with the selected gtfs
     * @param {string} gtfsId
     * @param {boolean} validation
     * @param {boolean} openInNewTab
     */
    redirectToEditor(gtfsId, validation = false, openInNewTab = false) {
      const validationPath = validation ? '/validation' : '';
      openInNewTab
        ? window.open(`${this.editorBaseUrl}/${gtfsId}${validationPath}`)
        : (window.location = `${this.editorBaseUrl}/${gtfsId}${validationPath}`);
    },

    /**
     * @param {string} elementId
     */
    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.classList.add('datagrid-vuetify__table-row--highlighted');
        setTimeout(() => {
          element.classList.remove('datagrid-vuetify__table-row--highlighted');
        }, 2000);
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },

    /**
     * Show given modal
     * @param {string} name
     * @param {GtfsFormatted} gtfs
     */
    showModal(name, gtfs) {
      if (gtfs) {
        this.selectedGtfs = gtfs;
        this.selectedGtfsId = gtfs._id;
        this.gtfsName = `${gtfs.name} (${this.$t('duplicateGtfs.copy')})`;
      }
      this.modalShown = name;
    },

    /**
     * Add a gtfs
     */
    async submitModalAddGtfs() {
      this.loading = true;
      const newGtfs = await api.gtfs.addGtfs(this.$store.getters.group.group_id, this.gtfsName);
      this.redirectToEditor(newGtfs._id, false, true);
      this.closeModalWithRefresh(newGtfs._id);
    },

    /**
     * Add a copie of a gtfs with another name
     */

    async submitModalDuplicateGtfs() {
      this.loading = true;
      try {
        await api.gtfs.duplicateGtfs(this.$store.getters.group.group_id, this.gtfsName, this.selectedGtfsId);
        await this.loadGtfsList();
        this.formatGtfsList();
        this.closeModal();
        this.loading = false;
        const duplicatedGtfs = this.gtfsList[this.gtfsList.length - 1];
        this.redirectToEditor(duplicatedGtfs._id, false, true);
        this.closeModalWithRefresh(duplicatedGtfs._id);
      } catch (error) {
        this.loading = false;
        console.error('Failed to duplicate GTFS:', error);
      }
    },

    /**
     * Delete gtfs
     */
    async submitModalRemove() {
      await api.gtfs.deleteGtfs(this.$store.getters.group.group_id, this.selectedGtfsId);
      const foundIndex = this.gtfsList.findIndex(gtfs => gtfs._id === this.selectedGtfsId);
      this.gtfsList.splice(foundIndex, 1);
      this.closeModal();
    },
  },
};

/**
 * @typedef {Object} PublicationDetails
 * @property {boolean} scheduledPublication
 * @property {string} startDateValue
 * @property {string} startHourValue
 * */

/**
 * @typedef {Object} PutGroupResponse
 * @property {string} modificationTime
 * @property {number} publicationTime
 */

/**
 * @typedef {Object} GtfsFormatted
 * @property {boolean} archived
 * @property {string} _id
 * @property {string} name
 * @property {string} status
 * @property {string} type
 * @property {Date} modificationTime
 * @property {number} publicationTime
 */
</script>

<style lang="scss">
.transport-plan {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: end;
    padding-bottom: 12px;

    .add-transport-plan-text {
      margin-left: 10px;
    }
  }

  .datagrid-vuetify__table-row {
    transition-duration: 0.3s;
    transition-property: box-shadow;

    &--highlighted {
      border: 3px solid $primary-light;
      box-shadow: 0 0 20px 0 rgba(0 184 113 / 50%);
    }
  }
}
</style>

<i18n locale="fr">
{
  "addGtfs": {
    "newGtfs": "Ajouter un plan de transport",
    "name": "Nom",
    "or": "ou",
    "create": "Créer",
    "import": "Importer"
  },
  "duplicateGtfs" : {
    "copy": "copie",
    "copyName": "Nom de la copie",
    "duplicate": "Dupliquer",
    "duplicating": "Dupliquer le plan de transport",
    "duplicateLoading": "Duplication..."
  },
  "tabs": {
    "archived": "Archives",
    "current": "Plans de transport",
  },
}
</i18n>

<i18n locale="en">
{
  "addGtfs": {
    "newGtfs": "Add a transport plan",
    "name": "Name",
    "or": "or",
    "create": "Create",
    "import": "Import"
  },
  "duplicateGtfs" : {
    "copy": "copy",
    "copyName": "Copy name",
    "duplicate": "Duplicate",
    "duplicating": "Duplicate transport plan",
    "duplicateLoading": "Duplicating..."
  },
  "tabs": {
    "archived": "Archives",
    "current": "Transport plans",
  },
}
</i18n>
