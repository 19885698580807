<template>
  <div class="admin">
    <div class="admin__header">
      <div class="admin__header--left">
        <Btn
          v-if="
            resource !== ResourceType.TRIP_TRACKING &&
            resource !== ResourceType.DRIVER_VERSIONS &&
            resource !== ResourceType.VERSIONS_LIST
          "
          type="primary"
          @click="addNewElement"
        >
          {{ addElementText }}
        </Btn>
      </div>
      <div class="admin__header--center">
        <Btn
          type="secondary"
          :route="{ path: '/admin/groups' }"
          :class="[resource === ResourceType.GROUPS ? 'admin__btn--selected' : 'admin__btn']"
        >
          {{ `${$t(ResourceType.GROUPS)} (${groups.length})` }}
        </Btn>
        <Btn
          type="secondary"
          :route="{ path: '/admin/users' }"
          :class="[resource === ResourceType.USERS ? 'admin__btn--selected' : 'admin__btn']"
        >
          {{ `${$t(ResourceType.USERS)} (${users.length})` }}
        </Btn>
        <Btn
          type="secondary"
          :route="{ path: '/admin/driverVersions' }"
          :class="[resource === ResourceType.DRIVER_VERSIONS ? 'admin__btn--selected' : 'admin__btn']"
        >
          {{ driverVersionsBtn }}
        </Btn>
        <Btn
          type="secondary"
          :route="{ path: '/admin/versionsList' }"
          :class="[resource === ResourceType.VERSIONS_LIST ? 'admin__btn--selected' : 'admin__btn']"
        >
          {{ $t('versionsList') }}
        </Btn>
        <Btn
          type="secondary"
          :route="{ path: '/admin/tripTracking' }"
          :class="[resource === ResourceType.TRIP_TRACKING ? 'admin__btn--selected' : 'admin__btn']"
        >
          {{ $t('tripTracking') }}
        </Btn>
      </div>
      <div class="admin__header--right">
        <Btn v-if="resource === ResourceType.USERS" type="secondary" @click="downloadUserList">
          {{ $t('export') }}
        </Btn>
        <Btn
          v-if="resource === ResourceType.TRIP_TRACKING"
          type="secondary"
          @click="triggerDownloadTripTracking"
        >
          {{ $t('export') }}
        </Btn>
      </div>
    </div>

    <TripTracking
      v-if="resource === ResourceType.TRIP_TRACKING"
      ref="tripTracking"
      :groups="groups"
      @tripTrackingDataReady="exportDataTripTracking"
    />

    <DriverVersions v-if="resource === ResourceType.DRIVER_VERSIONS" ref="driverVersions" />

    <Groups v-if="resource === ResourceType.GROUPS" ref="groups" :groups="groups" :loading="loading" />

    <Users v-if="resource === ResourceType.USERS" ref="users" :users="users" :loading="loading" />

    <VersionsList v-if="resource === ResourceType.VERSIONS_LIST" ref="VersionsList" :groups="groups" />
  </div>
</template>

<script>
import { admin as ApiAdmin, config as ApiConfig } from '@/api.js';
import Btn from '@/components/ui/Btn.vue';
import { toCSV, triggerDownloadCSV } from '@/libs/csv';
import Groups from './Groups.vue';
import Users from './Users.vue';
import TripTracking from './TripTracking.vue';
import DriverVersions from './DriverVersions.vue';
import VersionsList from './ListVersions.vue';

/** @enum {string} */
export const ResourceType = {
  GROUPS: 'groups',
  USERS: 'users',
  TRIP_TRACKING: 'tripTracking',
  DRIVER_VERSIONS: 'driverVersions',
  VERSIONS_LIST: 'versionsList',
};

export default {
  name: 'AdminPage',

  components: {
    Users,
    Btn,
    Groups,
    TripTracking,
    DriverVersions,
    VersionsList,
  },

  props: {
    resource: {
      default: ResourceType.GROUPS,
      type: String,
    },
  },

  data: () => ({
    ResourceType,

    /** @type {Array<import('@/store').Group>} */
    groups: [],
    /** @type {Boolean} */
    loading: false,
    /** @type {Array<import('@/store').User>} */
    users: [],
    version: [],
  }),

  computed: {
    /** @returns {string} */
    addElementText() {
      return this.resource === ResourceType.GROUPS ? this.$t('newGroup') : this.$t('newUser');
    },

    exportUsersLink() {
      const data = [['Email'], ...this.users.map(user => [user.email])];
      return toCSV(data);
    },

    /** @returns {string} */
    driverVersionsBtn() {
      const deprecatedLength = this.version.semver?.deprecated?.length || 0;
      const toUpdateLength = this.version.semver?.to_update?.length || 0;

      // Calculez la longueur totale
      const totalLength = deprecatedLength + toUpdateLength;

      return `${this.$t(ResourceType.DRIVER_VERSIONS)} (${totalLength})`;
    },
  },

  async created() {
    this.loading = true;
    this.groups = await ApiAdmin.groups.get();
    this.users = await ApiAdmin.users.get();
    this.version = await ApiConfig.getVersion();
    this.loading = false;
  },

  methods: {
    async addNewElement() {
      this.$refs[this.resource].addElement();
    },

    downloadUserList() {
      const blobUrl = this.exportUsersLink;

      triggerDownloadCSV(blobUrl, `liste_Mail_utilisateurs_Pysae.csv`);

      // clean up Url
      window.URL.revokeObjectURL(blobUrl);
    },

    exportDataTripTracking(dataGridContent) {
      // creating csv
      const data = [
        dataGridContent.translatedKeys,
        ...dataGridContent.data.map(row => Object.values(row).map(value => value?.toString() || '-')),
      ];
      const link = toCSV(data);
      // Trigger download
      triggerDownloadCSV(link, `${this.$t('tripTracking')}.csv`);
    },

    /**
     * Get data from dataGrid and create download
     */
    triggerDownloadTripTracking() {
      this.$refs.tripTracking.exportData();
    },
  },
};
</script>

<style lang="scss">
.admin {
  $datagrid-header: 48px;

  padding: $view-standard-padding;

  .datagrid-vuetify .v-table__wrapper {
    height: calc(100vh - ($view-standard-padding * 2) - $datagrid-header - 70px);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    &--left {
      display: flex;
      width: 380px;
    }

    &--center {
      & > .ui-btn:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      & > .ui-btn:nth-child(2) {
        margin-left: 0;
        border-left: none;
        border-radius: 0;
      }

      & > .ui-btn:nth-child(3) {
        margin-left: 0;
        border-left: none;
        border-radius: 0;
      }

      & > .ui-btn:nth-child(4) {
        margin-left: 0;
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__btn {
    color: $text-neutral !important;

    &--selected {
      color: $text-dark-variant;
    }
  }
}
</style>

<i18n locale="fr">
{
  "newGroup": "Nouveau groupe",
  "newUser": "Nouvel utilisateur",
  "groups": "Groupes",
  "users": "Utilisateurs",
  "export": "Télécharger",
  "tripTracking": "Suivi des courses",
  "driverVersions": "Versions de Driver",
  "versionsList": "Liste des versions"
}
</i18n>

<i18n locale="en">
{
  "newGroup": "New group",
  "newUser": "New user",
  "groups": "Groups",
  "users": "Users",
  "export": "Download",
  "tripTracking": "Trip tracking",
  "driverVersions": "Driver Versions",
  "versionsList": "Versions List"
}
</i18n>
