<template>
  <Modal modal-class="reports-modal-filters" :show="true" @close="$emit('close')">
    <template #title>
      {{ $t('editFilters') }}
    </template>

    <template #body>
      <div>
        <div class="form-group">
          <label for="routes" class="form-group__label">
            {{ $t('selectRoutes') }}
          </label>

          <VueMultiselect
            id="routes"
            v-model="routes"
            :close-on-select="false"
            class="form-group__input form-group__input--multiselect"
            mode="tags"
            searchable
            name="routes"
            value-prop="id"
            label="name"
            :options="routesOptions"
          >
            <template #placeholder>
              <span>{{ $t('allRoutes') }}</span>
            </template>
            <template #nooptions>
              <span>{{ $tc('none') }}</span>
            </template>
          </VueMultiselect>
        </div>

        <div class="form-group">
          <label for="stops" class="form-group__label">
            {{ $t('selectStops') }}
          </label>

          <VueMultiselect
            id="stops"
            v-model="stops"
            :close-on-select="false"
            class="form-group__input form-group__input--multiselect"
            mode="tags"
            name="stops"
            value-prop="id"
            label="name"
            :options="stopsOptions"
            searchable
          >
            <template #placeholder>
              <span>{{ $t('allStops') }}</span>
            </template>
            <template #nooptions>
              <span>{{ $tc('none') }}</span>
            </template>
          </VueMultiselect>
        </div>

        <div class="form-group">
          <label for="trip-headsigns" class="form-group__label">
            {{ $t('selectTripHeadsigns') }}
          </label>

          <VueMultiselect
            id="trip-headsigns"
            v-model="tripHeadsigns"
            :close-on-select="false"
            class="form-group__input form-group__input--multiselect"
            mode="tags"
            name="trip-headsigns"
            :options="tripHeadsignsOptions"
            searchable
          >
            <template #placeholder>
              <span>{{ $t('allTripHeadsigns') }}</span>
            </template>
            <template #nooptions>
              <span>{{ $tc('none', 2) }}</span>
            </template>
          </VueMultiselect>
        </div>

        <div class="form-group">
          <label for="teams" class="form-group__label">
            {{ $t('selectTeams') }}
          </label>

          <VueMultiselect
            id="teams"
            v-model="teams"
            :close-on-select="false"
            class="form-group__input form-group__input--multiselect"
            mode="tags"
            name="teams"
            :options="teamsOptions"
            searchable
          >
            <template #placholder>
              <span>{{ $t('allTeams') }}</span>
            </template>
            <template #nooptions>
              <span>{{ $tc('none', 2) }}</span>
            </template>
          </VueMultiselect>
        </div>

        <div class="form-group">
          <label for="blocks" class="form-group__label">
            {{ $t('selectBlocks') }}
          </label>

          <VueMultiselect
            id="blocks"
            v-model="blocks"
            :close-on-select="false"
            class="form-group__input form-group__input--multiselect"
            mode="tags"
            name="blocks"
            :options="blocksOptions"
            searchable
          >
            <template #placeholder>
              <span>{{ $t('allBlocks') }}</span>
            </template>
            <template #nooptions>
              <span>{{ $tc('none') }}</span>
            </template>
          </VueMultiselect>
        </div>

        <div class="form-group">
          <label for="time-from" class="form-group__label">
            {{ $t('selectTime') }}
          </label>

          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t('from') }}</span>
            </div>

            <input id="time-from" v-model="timeFrom" class="form-control" type="time" />

            <div class="input-group-prepend">
              <span class="input-group-text">{{ $t('to') }}</span>
            </div>

            <input id="time-to" v-model="timeTo" class="form-control" type="time" />
          </div>
        </div>

        <div class="form-group">
          <label class="form-group__label">{{ $t('selectDays') }}</label>

          <div>
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <label
                v-for="option in DAYS_OF_WEEK"
                :key="option"
                class="btn ui-btn secondary"
                :class="{
                  active: daysOfWeek[option],
                  focus: focus === option,
                }"
              >
                <input
                  v-model="daysOfWeek[option]"
                  autocomplete="off"
                  type="checkbox"
                  @focus="focus = option"
                  @blur="focus = null"
                />

                {{ $t(`dow.${option}`) }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="submit">
        {{ $t('submit') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import VueMultiselect from '@vueform/multiselect';

import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { secondsToStr, strToSeconds } from '@/libs/helpers/dates';
import { DAYS_OF_WEEK } from '@/libs/reports';
import { LocationType } from '@/store/gtfs';

export default {
  name: 'ReportsModalFilters',

  components: {
    Btn,
    Modal,
    VueMultiselect,
  },

  props: {
    /** @type {Vue.PropOptions<import('./index.vue').Filters>} */
    filters: {
      type: Object,
      required: true,
    },

    /** @type {Vue.PropOptions<Array<string>>} */
    gtfsIds: {
      type: Array,
      required: true,
    },
  },

  emits: ['close', 'submit'],

  data: () => ({
    DAYS_OF_WEEK,

    /** @type {Array<string>} */
    blocks: [],

    /** @type {Array<string>} */
    blocksOptions: [],

    /** @type {{[day: string]: boolean}} */
    daysOfWeek: {},

    /** @type {?string} */
    focus: null,

    /** @type {Array<{id: string, name: string}>} */
    routes: [],

    /** @type {Array<{id: string, name: string}>} */
    stops: [],

    /** @type {Array<string>} */
    teams: [],

    /** @type {Array<string>} */
    teamsOptions: [],

    /** @type {string} */
    timeFrom: '',

    /** @type {string} */
    timeTo: '',

    /** @type {Array<string>} */
    tripHeadsigns: [],

    /** @type {Array<string>} */
    tripHeadsignsOptions: [],
  }),

  computed: {
    /** @return {Array<{id: string, name: string}>} */
    routesOptions() {
      const routes = Object.entries(
        this.gtfsIds.reduce((acc, gtfsId) => {
          /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
          const gtfsRoutes = this.$store.getters['gtfs/getCachedGtfsTable'](gtfsId, 'routes');

          Object.entries(gtfsRoutes).forEach(([routeId, route]) => {
            if (!acc[routeId]) {
              acc[routeId] = route.route_short_name;
            }
          });

          return acc;
        }, {})
      ).map(([id, name]) => ({ name, id }));
      routes.sort((a, b) => (a.name > b.name ? 1 : -1));

      return routes;
    },

    /** @return {Array<{id: string, name: string}>} */
    stopsOptions() {
      const stops = Object.entries(
        this.gtfsIds.reduce((acc, gtfsId) => {
          /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
          const gtfsStops = this.$store.getters['gtfs/getCachedGtfsTable'](gtfsId, 'stops');

          Object.entries(gtfsStops).forEach(([stopId, stop]) => {
            if (
              (stop.location_type === LocationType.STOP || stop.location_type === undefined) &&
              !acc[stopId]
            ) {
              acc[stopId] = `${stop.stop_name} (${stopId})`;
            }
          });

          return acc;
        }, {})
      ).map(([id, name]) => ({ id, name }));
      stops.sort((a, b) => (a.name > b.name ? 1 : -1));

      return stops;
    },
  },

  watch: {
    filters: {
      immediate: true,
      handler() {
        if (this.filters.routes) {
          const routeSet = this.filters.routes.reduce((acc, routeId) => {
            acc[routeId] = true;

            return acc;
          }, /** @type {{[routeId: string]: boolean}} */ ({}));

          this.routes = this.routesOptions.filter(({ id }) => routeSet[id]);
        }

        if (this.filters.stops) {
          const stopSet = this.filters.stops.reduce((acc, stopId) => {
            acc[stopId] = true;

            return acc;
          }, /** @type {{[stopId: string]: boolean}} */ ({}));

          this.stops = this.stopsOptions.filter(({ id }) => stopSet[id]);
        }

        if (this.filters.tripHeadsigns) {
          this.tripHeadsigns = this.filters.tripHeadsigns.map(th => th);
        }

        if (this.filters.teams) {
          this.teams = this.filters.teams.map(t => t);
        }

        if (this.filters.blocks) {
          this.blocks = this.filters.blocks.map(b => b);
        }

        if (this.filters.time) {
          this.timeFrom = secondsToStr(this.filters.time.start);
          this.timeTo = secondsToStr(this.filters.time.end);
        }

        if (this.filters.daysOfWeek) {
          this.daysOfWeek = this.filters.daysOfWeek.reduce((acc, i) => {
            acc[DAYS_OF_WEEK[i]] = true;

            return acc;
          }, /** @type {{[stopId: string]: boolean}} */ ({}));
        } else {
          this.daysOfWeek = DAYS_OF_WEEK.reduce((acc, d) => {
            acc[d] = true;

            return acc;
          }, /** @type {{[stopId: string]: boolean}} */ ({}));
        }
      },
    },

    gtfsIds: {
      immediate: true,
      handler() {
        const blocks = {};
        const teams = {};
        const tripHeadsigns = {};

        this.gtfsIds.forEach(gtfsId => {
          /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
          const gtfsTrips = this.$store.getters['gtfs/getCachedGtfsTable'](gtfsId, 'trips');

          Object.values(gtfsTrips).forEach(trip => {
            if (trip.block_id && !blocks[trip.block_id]) {
              blocks[trip.block_id] = true;
            }

            if (trip.team_id && !teams[trip.team_id]) {
              teams[trip.team_id] = true;
            }

            if (trip.trip_headsign && !tripHeadsigns[trip.trip_headsign]) {
              tripHeadsigns[trip.trip_headsign] = true;
            }
          });
        });

        const arrBlocks = Object.keys(blocks);
        arrBlocks.sort((a, b) => (a > b ? 1 : -1));
        this.blocksOptions = arrBlocks;

        const arrTeams = Object.keys(teams);
        arrTeams.sort((a, b) => (a > b ? 1 : -1));
        this.teamsOptions = arrTeams;

        const arrTripHeadsigns = Object.keys(tripHeadsigns);
        arrTripHeadsigns.sort((a, b) => (a > b ? 1 : -1));
        this.tripHeadsignsOptions = arrTripHeadsigns;
      },
    },
  },

  methods: {
    submit() {
      const filters = {};

      if (this.routes.length > 0) {
        filters.routes = this.routes.map(({ id, name }) => id);
      }

      if (this.stops.length > 0) {
        filters.stops = this.stops.map(({ id, name }) => id);
      }

      if (this.tripHeadsigns.length > 0) {
        filters.tripHeadsigns = this.tripHeadsigns.map(th => th);
      }

      if (this.teams.length > 0) {
        filters.teams = this.teams.map(t => t);
      }

      if (this.blocks.length > 0) {
        filters.blocks = this.blocks.map(b => b);
      }

      if (this.timeFrom !== '' && this.timeTo !== '') {
        filters.time = {
          start: strToSeconds(this.timeFrom),
          end: strToSeconds(this.timeTo),
        };
      }

      if (Object.values(this.daysOfWeek).filter(d => d).length < DAYS_OF_WEEK.length) {
        filters.daysOfWeek = DAYS_OF_WEEK.reduce((acc, day, index) => {
          if (this.daysOfWeek[day]) {
            acc.push(index);
          }

          return acc;
        }, []);
      }

      this.$emit('submit', filters);
    },
  },
};
</script>

<style lang="scss">
.reports-modal-filters {
  .form-control {
    display: block;
    height: 34px;
    padding: 6px 12px;
    border: 1px solid $border;
    border-radius: 2px;
    background-color: $canvas;
    color: $text-dark;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%);
    font-family: inherit;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    &:focus {
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px rgb(102 175 233 / 60%);
      outline: 0;
    }

    &:invalid,
    &--invalid {
      border-color: $danger;

      &:focus {
        border-color: $danger;
        box-shadow: inset 0 1px 1px rgb(0 0 0 / 7.5%), 0 0 8px #ce8483;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "allBlocks": "Tous les services",
  "allRoutes": "Toutes les lignes",
  "allStops": "Tous les arrêts",
  "allTeams": "Toutes les équipes",
  "allTripHeadsigns": "Toutes les girouettes",
  "editFilters": "Filtrer les résultats",
  "from": "De",
  "selectBlocks": "Sélectionnez des services :",
  "selectDays": "Sélectionnez les jours pris en compte :",
  "selectRoutes": "Sélectionnez des lignes :",
  "selectStops": "Sélectionnez des arrêts :",
  "selectTeams": "Sélectionnez des équipes :",
  "selectTime": "Sélectionnez la période horaire :",
  "selectTripHeadsigns": "Sélectionnez des girouettes :",
  "to": "à"
}
</i18n>

<i18n locale="en">
{
  "allBlocks": "All services",
  "allRoutes": "All routes",
  "allStops": "All stops",
  "allTeams": "All teams",
  "allTripHeadsigns": "All headsigns",
  "editFilters": "Filter the results",
  "from": "From ",
  "selectBlocks": "Select services:",
  "selectDays": "Select the days:",
  "selectRoutes": "Select routes:",
  "selectStops": "Select stops:",
  "selectTeams": "Select teams:",
  "selectTime": "Select the time period:",
  "selectTripHeadsigns": "Select the trip headsigns:",
  "to": "To"
}
</i18n>

<i18n locale="cz">
{
  "allBlocks": "Všechny jízdy",
  "allRoutes": "Všechny trasy",
  "allStops": "Všechny zastávky",
  "allTeams": "Všechny týmy",
  "allTripHeadsigns": "Všechny elektronické cedule",
  "from": "Od",
  "selectBlocks": "Vyberte jízdy:",
  "selectDays": "Vyberte dny:",
  "selectRoutes": "Vyberte trasy:",
  "selectStops": "Vyberte zastávky:",
  "selectTeams": "Vyberte týmy:",
  "selectTime": "Vyberte časový úsek:",
  "selectTripHeadsigns": "Vyberte elektronické cedule:",
  "to": "Do",
  "editFilters": "Filtrovat výsledky"
}
</i18n>

<i18n locale="de">
{
  "allBlocks": "Alle Fahrten",
  "allRoutes": "Alle Strecken",
  "allStops": "Alle Haltestellen",
  "allTeams": "Alle Teams",
  "allTripHeadsigns": "Alle digitalen Anzeigetafeln",
  "from": "Von",
  "selectBlocks": "Fahrten auswählen:",
  "selectDays": "Tage auswählen:",
  "selectRoutes": "Strecken auswählen:",
  "selectStops": "Haltestellen auswählen:",
  "selectTeams": "Teams auswählen:",
  "selectTime": "Zeitspanne auswählen:",
  "selectTripHeadsigns": "Digitale Anzeigetafeln auswählen:",
  "to": "Bis",
  "editFilters": "Ergebnisse filtern"
}
</i18n>

<i18n locale="es">
{
  "allBlocks": "Todos los servicios",
  "allRoutes": "Todas las rutas",
  "allStops": "Todas las paradas",
  "allTeams": "Todos los equipos",
  "allTripHeadsigns": "Todos los indicadores",
  "from": "De",
  "selectBlocks": "Seleccionar servicios:",
  "selectDays": "Seleccionar los días:",
  "selectRoutes": "Seleccionar rutas:",
  "selectStops": "Seleccionar paradas:",
  "selectTeams": "Seleccionar equipos:",
  "selectTime": "Seleccionar el periodo de tiempo:",
  "selectTripHeadsigns": "Seleccionar los indicadores de viaje:",
  "to": "Para",
  "editFilters": "Filtra los resultados"
}
</i18n>

<i18n locale="it">
{
  "allBlocks": "Tutti i servizi",
  "allRoutes": "Tutti i percorsi",
  "allStops": "Tutte le fermate",
  "allTeams": "Tutti i team",
  "allTripHeadsigns": "Tutti gli indicatori di destinazione",
  "from": "Dalle",
  "selectBlocks": "Seleziona i servizi:",
  "selectDays": "Seleziona i giorni:",
  "selectRoutes": "Seleziona i percorsi:",
  "selectStops": "Seleziona le fermate",
  "selectTeams": "Seleziona i team:",
  "selectTime": "Seleziona il periodo di tempo:",
  "selectTripHeadsigns": "Seleziona gli indicatori di destinazione per il viaggio:",
  "to": "Alle",
  "editFilters": "Filtra i risultati"
}
</i18n>

<i18n locale="pl">
{
  "allBlocks": "Wszystkie usługi",
  "allRoutes": "Wszystkie trasy",
  "allStops": "Wszystkie postoje",
  "allTeams": "Wszystkie zespoły",
  "allTripHeadsigns": "Wszystkie tablice",
  "from": "Od",
  "selectBlocks": "Wybierz usługi:",
  "selectDays": "Wybierz dni:",
  "selectRoutes": "Wybierz trasy:",
  "selectStops": "Wybierz postoje:",
  "selectTeams": "Wybierz zespoły:",
  "selectTime": "Wybierz okres:",
  "selectTripHeadsigns": "Wybierz tablice podróżne:",
  "to": "Do",
  "editFilters": "Filtruj wyniki"
}
</i18n>
