<template>
  <div id="live-map" class="live-map">
    <div v-show="!minimalVersion" class="live-map__devices">
      <LiveMapDeviceListVue
        v-model:selectedDeviceId="selectedDeviceId"
        :sorted-devices="sortedDevices"
        @searchFilterList="updateSearchedDevices"
        @updateSortFilter="updateSortFilter"
        @deviceListFormated="updateDeviceListFormated"
      />
    </div>
    <div class="live-map__right-side" :style="`height: ${mapContainerHeight}`">
      <LiveMapDeviceFilter
        v-show="!minimalVersion"
        class="live-map__filters"
        :teams-filter-options="teams"
        :routes-filter-options="routesFilterOptions"
        @updateFilters="handleFilters"
      />

      <div class="live-map__map c-map" :class="{ 'c-map__no-margin': minimalVersion }">
        <div v-show="!minimalVersion" class="c-map__layers">
          <MapLayersDropdown v-model="layers" @openModal="modalShown = true" />
        </div>

        <MapboxMap
          v-slot="MapboxMap"
          v-model:bounds="mapBounds"
          :border-radius="minimalVersion ? '0px' : '12px'"
          :full-screen-option="minimalVersion ? false : true"
          :gtfs-id="gtfsId"
          :display-tooltip="true"
          :stops="mapStops"
          :stops-options="stopsOptions"
          :trips="mapTrips"
          @click="onMapClick"
          @click:device="onMapClick"
          @load="onMapLoad"
        >
          <MapboxDevices
            v-if="map"
            :devices="mapDevices"
            :gtfs-id="gtfsId"
            :map="map"
            :show-labels="layers.vehiclesLabels"
            :display-tooltip="true"
            @click="e => MapboxMap.onMapClick(e, 'device')"
            @mouseenter="onDevicesMouseEnter"
            @mouseleave="onDevicesMouseLeave"
          />
        </MapboxMap>
      </div>
    </div>
    <ModalMapOptions
      v-if="modalShown"
      v-model:keep-display-vehicle-label="layers['vehiclesLabels']"
      @close="modalShown = false"
    />
  </div>
</template>

<script>
import MapboxTraffic from '@mapbox/mapbox-gl-traffic';

import MapboxDevices from '@/components/map/MapboxDevices.vue';
import { HighlightType } from '@/components/map/MapboxTrips.vue';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { localeCompareSort } from '@/libs/helpers/strings';

import LiveMapDeviceListVue, { SortField } from './LiveMapDeviceList.vue';
import LiveMapDeviceFilter from './LiveMapDeviceFilter.vue';
import MapLayersDropdown from '@/components/map/MapLayersDropdown.vue';
import ModalMapOptions from './ModalMapOptions.vue';

import MapboxMap from '@/components/map/MapboxMap.vue';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { AlternativeState, deviceTeamsFilterFunction, OFF_ITINERARY } from '@/store/devices';
import { SortDirection } from '@/components/common/SortFilterButtons.vue';

const mapboxTrafficControl = new MapboxTraffic({ showTrafficButton: false });

const DEFAULT_LAYERS = {
  vehicles: true,
  vehiclesLabels: false,
  stops: true,
  stations: true,
  traffic: false,
  linesShapes: true,
};

export default {
  name: 'LiveMap',

  components: {
    MapLayersDropdown,
    MapboxDevices,
    ModalMapOptions,
    MapboxMap,
    LiveMapDeviceListVue,
    LiveMapDeviceFilter,
  },

  props: {
    date: {
      type: [String, Number, Date],
    },
    mapContainerHeight: {
      type: String,
      default: '100%',
    },
    minimalVersion: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    /** @type {{[deviceId: string]: ?string}} */
    formattedTripNames: {},

    /** @type {import('@/components/map/MapLayersDropdown.vue').LayerOptions} */
    layers: DEFAULT_LAYERS,

    /** @type {?mapboxgl.LngLatBounds} */
    mapBounds: null,

    /** @type {boolean} */
    mapLoaded: false,

    /** @type {boolean} */
    modalShown: false,

    /** @type {?string} */
    selectedDeviceId: null,

    /** @type {mapboxgl.Map} */
    map: null,

    /** @type {import('./LiveMapDeviceFilter.vue').FilterEvent} */
    filters: {
      routes: [],
      teams: [],
      categories: [],
    },
    searchedDevices: null,
    /** @type {import('@/components/common/SortFilterButtons.vue').SortFilter} */
    sortFilter: null,

    deviceListFormated: [],
  }),

  computed: {
    /** @return {string[]} */
    deactivatedRoutes() {
      return this.$store.getters.group.deactivated_routes;
    },
    /** @return {{[deviceId: string]: import('@/store/devices').Device}} */
    devices() {
      return this.$store.getters['devices/visibleDevices'];
    },

    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    gtfsRoutes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    /** @return {{[stopId: string]: import('@/store/gtfs').Stop}} */
    gtfsStops() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'stops');
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {Array<import('@/components/common/SelectFiltersDropdown.vue').DropdownOption>} */
    routesFilterOptions() {
      const routeOptions = Object.values(this.gtfsRoutes).reduce((options, route) => {
        options.push({
          label: route.route_short_name,
          id: route.route_id,
          isDeactivated: this.deactivatedRoutes.includes(route.route_id),
          routeProperties: {
            route_short_name: route.route_short_name,
            route_text_color: route.route_text_color,
            route_color: route.route_color,
          },
        });
        return options;
      }, []);
      return routeOptions.sort((a, b) => localeCompareSort(a.label, b.label, this.$i18n.locale));
    },

    /** @return {Array<string>} */
    lineFilterTripsIds() {
      const routesIds = this.filters.routes.reduce((ids, option) => {
        ids[option] = true;
        return ids;
      }, /** @type {{[routeId: string]: boolean}} */ ({}));

      return Object.keys(this.gtfsTrips).filter(tripId => routesIds[this.gtfsTrips[tripId].route_id]);
    },

    /** @return {Array<import('@/components/map/MapboxDevices.vue').MapDevice>} */
    mapDevices() {
      return this.sortedDevices.map(id => ({
        device: this.devices[id],
        id,
        highlight: id === this.selectedDeviceId,
        additionalDatas: this.deviceListFormated.find(d => d._id === id),
      }));
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapStop>} */
    mapStops() {
      const stopIds = Object.keys(
        this.lineFilterTripsIds.length > 0
          ? this.lineFilterTripsIds.reduce((acc, tripId) => {
              this.gtfsTrips[tripId].stop_times.forEach(st => {
                acc[st.stop_id] = true;
              });

              return acc;
            }, /** @type {{[stopId: string]: boolean}} */ ({}))
          : this.gtfsStops
      );

      return stopIds.map(id => ({ id, highlight: false }));
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapTrip>} */
    mapTrips() {
      if (!this.layers.linesShapes) return [];

      const tripIds =
        this.lineFilterTripsIds.length > 0 ? this.lineFilterTripsIds : Object.keys(this.gtfsTrips);

      const selectedDevice = this.selectedDeviceId ? this.devices[this.selectedDeviceId] : null;
      if (selectedDevice && selectedDevice.trip_id != null) {
        return tripIds.map(id => ({
          id,
          highlight: id === selectedDevice.trip_id ? HighlightType.MORE : HighlightType.LESS,
        }));
      }
      return tripIds.map(id => ({ id, highlight: HighlightType.NONE }));
    },

    /** @return {Array<string>} */
    sortedDevices() {
      const deviceTeamsFilter =
        this.filters.teams?.length > 0 && this.filters.teams.length !== this.teams.length
          ? deviceTeamsFilterFunction(this.filters.teams)
          : undefined;

      const devicesIds = Object.keys(this.devices).filter(deviceId => {
        const device = this.devices[deviceId];

        if (this.lineFilterTripsIds?.length > 0 && !this.lineFilterTripsIds.includes(device.trip_id)) {
          return false;
        }

        if (deviceTeamsFilter && !deviceTeamsFilter(device)) {
          return false;
        }

        // handle delay state
        let delayCategory =
          device.delay != null ? this.$store.getters['devices/getDelayState'](device.delay) : null;

        // handle dead running
        if (device.trip_id === null) delayCategory = AlternativeState.DEAD_RUNNING;
        // handle routing
        if (device.trip_pending) delayCategory = AlternativeState.ROUTING;

        if (device.off_itinerary) delayCategory = OFF_ITINERARY;

        if (this.filters.categories?.length > 0) {
          if (!this.filters.categories.includes(delayCategory)) {
            return false;
          }
        }
        if (this.searchedDevices) {
          if (this.searchedDevices.length === 0 || !this.searchedDevices.includes(deviceId)) return false;
        }
        return true;
      });

      const collator = new Intl.Collator(this.$i18n.locale, {
        numeric: true,
      });

      /**
       * @param {string} id
       * @return {boolean}
       */
      const isOnline = id => !!this.$store.state.devices.online[id];

      /**
       * @param {string} id
       * @return {?boolean}
       */
      const isOnTrip = id => !!this.devices[id].trip_id;

      /**
       * @param {string} id
       * @return {?string}
       */
      const getRouteName = id => {
        const route = this.gtfsRoutes[(this.gtfsTrips[this.devices[id].trip_id] || {}).route_id] || {};

        return route.route_short_name || route.route_long_name;
      };

      const getDelay = id => {
        let delay = this.devices[id].delay;
        // Handle case when delay not set, those values must stay last in order sort
        if (delay === null)
          if (this.sortFilter?.direction)
            this.sortFilter.direction === SortDirection.ASC ? (delay = Infinity) : (delay = -Infinity);
          else delay = Infinity;
        return delay;
      };

      const getVehicleLoad = id => {
        return this.devices[id]?.vehicle_load || 0;
      };

      /**
       * @param {boolean} a
       * @param {boolean} b
       * @return {number}
       */
      const compareBoolean = (a, b) => (a === b ? 0 : a ? -1 : 1);

      /**
       * @param {number} a
       * @param {number} b
       * @return {number}
       */
      const compareNumber = (a, b) => (a < b ? -1 : 1);

      const orderByFilter = (a, b) => {
        const firstValue = !this.sortFilter || this.sortFilter.direction === SortDirection.ASC ? a : b;
        const secondValue = firstValue !== a ? a : b;
        switch (this.sortFilter?.field?.id) {
          case SortField.PASSENGER_COUNT:
            return compareNumber(getVehicleLoad(firstValue), getVehicleLoad(secondValue));
          case SortField.DELAY:
            return compareNumber(getDelay(firstValue), getDelay(secondValue));
          case SortField.ROUTE:
          default:
            return collator.compare(getRouteName(firstValue), getRouteName(secondValue));
        }
      };

      devicesIds.sort(
        (a, b) =>
          compareBoolean(isOnline(a), isOnline(b)) ||
          compareBoolean(isOnTrip(a), isOnTrip(b)) ||
          orderByFilter(a, b) ||
          collator.compare(this.formattedTripNames[a], this.formattedTripNames[b])
      );

      return devicesIds;
    },

    /** @return {Partial<import('@/components/map/MapboxMap.vue').StopsOptions>} */
    stopsOptions() {
      return {
        stationsMarkers: this.layers.stations,
        stopsMarkers: this.layers.stops,
        stopsZones: this.layers.stopsZones,
      };
    },

    /** @return {Array<import('@/components/common/SelectFiltersDropdown.vue').DropdownOption>} */
    teams() {
      return [
        /* eslint-disable camelcase */ { id: '', label: this.$t('noTeam') },
        ...this.$store.getters.activeTeams.map(({ team_id, name }) => ({
          id: team_id,
          label: name ?? team_id,
        })),
      ];
    },
  },

  watch: {
    groupId() {
      this.loadDrivers();
      this.loadVehicles();
      this.loadActivityLog();
    },

    gtfsId: {
      immediate: true,
      handler() {
        this.fetchRoutes();
        this.fetchStops();
        this.fetchTrips();
      },
    },

    layers: {
      deep: true,
      handler() {
        this.layersLSSave();
      },
    },
    'layers.traffic': function layersShowTraffic(show) {
      if (!this.mapLoaded) return;

      if (show !== mapboxTrafficControl.options.showTraffic) {
        mapboxTrafficControl.toggleTraffic();
      }
    },
    selectedDeviceId() {
      if (this.selectedDeviceId !== null) {
        const device = this.mapDevices.find(d => d.id === this.selectedDeviceId);
        if (device) {
          this.map.setZoom(14);
          this.map.panTo([device.device.latlng[1], device.device.latlng[0]]);
        }
      } else {
        this.map.fitBounds(this.mapBounds, {
          padding: 40,
          animate: false,
        });
      }
    },
  },

  created() {
    this.layersLSLoad();
    this.loadDrivers();
    this.loadVehicles();
    this.loadActivityLog();
  },

  mounted() {
    const liveMap = document.getElementById('live-map');
    liveMap.addEventListener('click', evt => this.removeTooltipOnClickOutsideMap(evt));
  },

  methods: {
    async fetchRoutes() {
      await this.$store.dispatch('gtfs/getRoutesMap', { gtfsId: this.gtfsId });
    },

    async fetchStops() {
      await this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId });
    },

    async fetchTrips() {
      await this.$store.dispatch('gtfs/getTripsMap', { gtfsId: this.gtfsId });
    },
    /**
     * Remove potential tooltips on click outside mapbox
     */
    removeTooltipOnClickOutsideMap(evt) {
      const mapboxGlCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
      if (evt.target !== mapboxGlCanvas) MapboxHelper.removeAllTooltips(this.map);
    },

    /**
     * Load layers from localStorage.
     */
    layersLSLoad() {
      const savedOptions = MapboxHelper.getLayersOptionsFromLS('settings.op.liveMap.map.layerOptions');
      if (savedOptions) {
        // remove deprecated keys from localStorage :
        Object.keys(savedOptions).forEach(key => {
          if (!Object.keys(DEFAULT_LAYERS).includes(key)) {
            delete savedOptions[key];
          }
        });
        this.layers = savedOptions;
      }
    },

    /**
     * Save layers to localStorage.
     */
    layersLSSave() {
      MapboxHelper.saveLayerOptionstoLS('settings.op.liveMap.map.layerOptions', this.layers);
    },

    loadActivityLog() {
      this.$store.dispatch(
        'activityLog/loadEntries',
        dateObjToGtfsFormat(this.date ? new Date(this.date) : new Date())
      );
    },

    /**
     * Load drivers list for assigned values.
     */
    loadDrivers() {
      this.$store.dispatch('drivers/loadList');
    },

    /**
     * Load vehicles list for assigned values.
     */
    loadVehicles() {
      this.$store.dispatch('vehicles/loadList');
    },

    /**
     * @param {string} deviceId
     * @param {string} formattedTripName
     */
    onDeviceTripChange(deviceId, formattedTripName) {
      this.formattedTripNames[deviceId] = formattedTripName;
    },

    onDevicesMouseEnter() {
      this.map.getCanvasContainer().style.cursor = 'pointer';
    },

    onDevicesMouseLeave() {
      this.map.getCanvasContainer().style.cursor = '';
    },

    /** @param {mapboxgl.MapLayerMouseEvent} event */
    onMapClick(event) {
      const feature = event.features ? event.features[0] : null;
      if (feature) {
        this.selectedDeviceId = feature.properties.id;
      } else {
        this.selectedDeviceId = null;
      }
    },

    /** @param {{map: mapboxgl.Map}} event */
    onMapLoad({ map }) {
      map.addControl(mapboxTrafficControl);

      map.once('idle', () => {
        this.map = map;
        this.mapLoaded = true;

        if (this.layers.traffic !== mapboxTrafficControl.options.showTraffic) {
          mapboxTrafficControl.toggleTraffic();
        } else {
          mapboxTrafficControl.render();
        }
      });
    },
    /**
     * update filter Object
     * @param {import('./LiveMapDeviceFilter.vue').FilterEvent} filters
     */
    handleFilters(filters) {
      this.filters = filters;
      this.selectedDeviceId = null;
    },
    updateSearchedDevices(devices) {
      this.selectedDeviceId = null;
      this.searchedDevices = devices;
    },
    updateSortFilter(sortFilter) {
      this.sortFilter = sortFilter;
    },
    updateDeviceListFormated(deviceListFormated) {
      this.deviceListFormated = deviceListFormated;
    },
  },
};
</script>

<style lang="scss">
.live-map {
  display: flex;
  height: 100%;
  background-color: $canvas;

  .mapboxgl-map {
    border: 1px solid $border;
  }

  &__devices {
    display: flex;
    flex: 1;
  }

  &__right-side {
    display: flex;
    flex: 3;
    flex-direction: column;
  }

  &__filters {
    flex: 0 1 auto;
  }

  .c-map {
    position: relative;
    flex: 1 1 auto;
    height: 100%;
    margin: 0 12px 12px 0;

    &__layers {
      position: relative;
      z-index: $map-layers-index;
      margin: 10px;
    }

    &__no-margin {
      margin: 0;
    }
  }
}
</style>

<i18n locale="fr">
{
  "filter": "Filtre",
  "previousMap": "Interface précédente",
  "noTeam": "Sans équipe",

}
</i18n>

<i18n locale="en">
{
  "filter": "Filter",
  "previousMap": "Previous map",
  "noTeam": "No team",

}
</i18n>
