<template>
  <div class="reports" :class="isReportV2 ? 'reports--horizontal-menu' : 'reports--vertical-menu'">
    <div class="reports__menu" :class="{ sticky: isReportV2 }">
      <ReportsMenuV2
        v-if="isReportV2"
        v-model:metric="currentRouteName"
        :old-report-link="
          RouteName.REPORTING_PUNCTUALITY === currentRouteName ? RouteName.REPORTING_OLD_PUNCTUALITY : ''
        "
        :end-date="endDate"
        :download-link="downloadLink"
        :start-date="startDate"
        :has-data="hasData"
        :is-data-loading="isDataLoading"
        @submitPeriod="submitModalPeriod"
        @updateQuery="updateUrl"
      />
      <ReportsMenu
        v-else
        :group-by="query.groupBy"
        :metric="currentRouteName"
        :type="query.type"
        :available-options="availableOptions"
        :end-date="endDate"
        :filters="filters"
        :start-date="startDate"
        @editFilters="modalFiltersShown = true"
        @editPeriod="modalPeriodShown = true"
        @updateQuery="updateUrl"
      />
    </div>

    <div class="reports__content">
      <router-view
        :date-interval="dateInterval"
        :chart-width="chartWidth"
        :chart-height="chartHeight"
        :filters="filters"
        :group-by="query.groupBy"
        :type="query.type"
        :query-params="query"
        @error="showErrorToast"
        @downloadLink="setDownloadLink"
        @hasData="updateHasData"
        @isDataLoading="updateisDataLoading"
      />
    </div>

    <ReportsModalFilters
      v-if="modalFiltersShown"
      :filters="filters"
      :gtfs-ids="gtfsIds"
      @close="modalFiltersShown = false"
      @submit="submitModalFilters"
    />

    <ReportsModalPeriod
      v-if="modalPeriodShown"
      :end-date="endDate"
      :start-date="startDate"
      :metric="currentRouteName"
      @close="modalPeriodShown = false"
      @submit="submitModalPeriod"
    />
  </div>
</template>

<script>
import { useToast, POSITION } from 'vue-toastification';
import { dateGtfsFormatToObj, dateObjToGtfsFormat, recalculateMaxDateTo31days } from '@/libs/helpers/dates';
import { GroupBy, ReportType, ReportPages, ReportsRouteList } from '@/libs/reports';

import ReportsMenu from './ReportsMenu.vue';
import ReportsMenuV2 from './ReportsMenuV2.vue';
import ReportsModalFilters from './ReportsModalFilters.vue';
import ReportsModalPeriod from './ReportsModalPeriod.vue';
import ReportErrorToast from './ReportErrorToast.vue';
import { GroupRoute as RouteName } from '@/libs/routing';

const toast = useToast();

/**
 * @typedef {Object} ChartData
 * @property {string} ref
 * @property {number} barsPerScreen
 * @property {number} screenNumber
 * @property {number} numberOfScreens
 * @property {Array<import('@/api').PassengerCounts> | Array<import('./ReportsTripKm.vue').TripKMObject> | Array<import('@/api').TripTracking> | Array<import('@/api').DailyDevices>} data
 * @property {Array<import('@/api').PassengerCounts> | Array<import('./ReportsTripKm.vue').TripKMObject> | Array<import('@/api').TripTracking> | Array<import('@/api').DailyDevices>} screenData
 * @property {Array<import('@/components/ui/BarChart2.vue').ApexChartSerie>} series
 * @property {Object} options // ApexCharts Options object
 */

/**

/** @type {number} */
const CHART_HEIGHT_COEFF = 65 / 100;

/** @type {number} */
const CHART_WIDTH_COEFF = 75 / 100;

export default {
  name: 'Reports',

  components: {
    ReportsMenu,
    ReportsMenuV2,
    ReportsModalFilters,
    ReportsModalPeriod,
  },

  data: () => ({
    RouteName,
    ReportType,

    /** @type {number} */
    chartHeight: 620,

    /** @type {number} */
    chartWidth: 1200,

    /** @type {{[filter: string]: any}} */
    filters: {},

    /** @type {Array<string>} */
    gtfsIds: [],

    /** @type {boolean} */
    modalFiltersShown: false,

    /** @type {boolean} */
    modalPeriodShown: false,

    /** @type {string} */
    downloadLink: null,

    /** @type {Vue.PropOptions<ReportQuery>} */
    query: {},

    /** @type {boolean} */
    hasData: null,

    /** @type {boolean} */
    isDataLoading: false,
  }),

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isReportV2() {
      return ReportPages.includes(this.currentRouteName);
    },
    islimitedPeriod() {
      return [RouteName.REPORTING_PUNCTUALITY, RouteName.REPORTING_OLD_PUNCTUALITY].includes(
        this.currentRouteName
      );
    },
    endDate() {
      return this.reportingDateRange.endDate;
    },

    startDate() {
      return this.reportingDateRange.startDate;
    },

    /** @return {{[key: string]: GroupBy}} */
    availableOptions() {
      switch (this.currentRouteName) {
        case RouteName.REPORTING_PASSENGERS_APP: {
          return Object.freeze({ DAY: GroupBy.DAY, MONTH: GroupBy.MONTH });
        }
        case RouteName.REPORTING_OLD_PUNCTUALITY: {
          return Object.freeze({
            STOP: GroupBy.STOP,
            DEVICE: GroupBy.DEVICE,
            TRIP: GroupBy.TRIP,
            WEEK: GroupBy.WEEK,
            DAY: GroupBy.DAY,
            TEAM: GroupBy.TEAM,
            SERVICE: GroupBy.SERVICE,
            ROUTE: GroupBy.ROUTE,
          });
        }
        default:
          return {};
      }
    },

    /** @return {{start: number, end: number}} */
    dateInterval() {
      if (ReportsRouteList.includes(this.currentRouteName)) {
        const start = dateGtfsFormatToObj(this.reportingDateRange.startDate);
        const end = dateGtfsFormatToObj(this.reportingDateRange.endDate);

        // Exceptions for non refactored reports (+ new punctuality)
        if (!this.isReportV2 || this.currentRouteName === RouteName.REPORTING_PUNCTUALITY)
          end.setDate(end.getDate() + 1);

        return {
          start: start.getTime() / 1000,
          end: end.getTime() / 1000,
        };
      }
      return null;
    },

    /** @return {import('@/store/index').GTFSDateRange} */
    reportingDateRange() {
      return this.$store.state.reportingDateRange;
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler() {
        if (ReportsRouteList.includes(this.currentRouteName)) {
          this.loadGtfsIds();
        }
      },
    },

    currentRouteName() {
      this.downloadLink = null;
    },
    '$route.query': {
      immediate: true,
      handler() {
        if (this.islimitedPeriod) {
          this.$route.query.endDate = this.updateDateQueryToMaxDates(
            this.$route.query.startDate,
            this.$route.query.endDate
          );
        }

        if (this.$route.query.startDate) this.reportingDateRange.startDate = this.$route.query.startDate;
        if (this.$route.query.endDate) this.reportingDateRange.endDate = this.$route.query.endDate;
      },
    },
  },
  created() {
    this.changeChartHeightAndWidth();
    window.addEventListener('resize', this.changeChartHeightAndWidth);
  },

  unmounted() {
    window.removeEventListener('resize', this.changeChartHeightAndWidth);
  },

  methods: {
    changeChartHeightAndWidth() {
      this.chartHeight = window.innerHeight * CHART_HEIGHT_COEFF;
      this.chartWidth = window.innerWidth * CHART_WIDTH_COEFF;
    },

    async loadGtfsIds() {
      this.gtfsIds = (
        await this.$store.dispatch('gtfs/getGtfsPublicationsIn', {
          fromTs: this.dateInterval.start,
          toTs: this.dateInterval.end,
        })
      ).map(p => p.current_file);
    },

    /** @param {ReportQuery} [update] */
    updateUrl(update = {}) {
      const metric = update.metric || this.currentRouteName;
      const startDate = update.startDate || this.startDate;
      let endDate = update.endDate || this.endDate;

      // In case of limited period, limit max date 31 days after startDate
      if (this.islimitedPeriod) {
        endDate = this.updateDateQueryToMaxDates(startDate, endDate);
      }
      // Group by
      const availableOptions = Object.values(this.availableOptions);
      const updateGroupBy = update.groupBy || this.query.groupBy;
      let groupBy = availableOptions.includes(updateGroupBy) ? updateGroupBy : this.query.groupBy;

      if (![RouteName.REPORTING_PASSENGERS_APP, RouteName.REPORTING_OLD_PUNCTUALITY].includes(metric)) {
        groupBy = null;
      } else {
        if (metric === RouteName.REPORTING_OLD_PUNCTUALITY && groupBy === GroupBy.MONTH) {
          groupBy = GroupBy.TRIP;
        }
        if (
          metric === RouteName.REPORTING_PASSENGERS_APP &&
          ![GroupBy.MONTH, GroupBy.DAY].includes(groupBy)
        ) {
          groupBy = GroupBy.DAY;
        }
      }

      // Type
      let type = update.type || this.query.type;
      if (metric !== RouteName.REPORTING_OLD_PUNCTUALITY) {
        type = null;
      }

      const query = {
        startDate,
        endDate,
      };
      if (groupBy) query.groupBy = groupBy;
      if (type) query.type = type;

      this.query = query;
      this.$router.replace({ name: metric, query }).catch(() => {});
      this.$store.commit('setReportingDateRange', { endDate, startDate });
    },

    /** @param {string} link */
    setDownloadLink(link) {
      this.downloadLink = link;
    },

    /** Display an error message in a toast */
    showErrorToast() {
      toast.error(ReportErrorToast, { position: POSITION.BOTTOM_RIGHT });
    },

    submitModalFilters(filters) {
      this.modalFiltersShown = false;
      this.filters = filters;
    },

    submitModalPeriod({ startDateValue, endDateValue }) {
      this.modalPeriodShown = false;
      this.updateUrl({
        startDate: dateObjToGtfsFormat(startDateValue),
        endDate: dateObjToGtfsFormat(endDateValue),
      });
    },
    /**
     * Check & return an endDate less than 31 days after startDate
     * Take 2 dates in gtfs format, return an endDate in gtfs format
     */
    updateDateQueryToMaxDates(startDate, endDate) {
      const copyDateStart = dateGtfsFormatToObj(startDate);
      const copyDateEnd = dateGtfsFormatToObj(endDate);
      // if more than 1 month selected, endDate become startDate + 1 month
      const endDateCalculated = recalculateMaxDateTo31days(copyDateStart, copyDateEnd);
      return dateObjToGtfsFormat(endDateCalculated);
    },

    updateHasData(hasData) {
      this.hasData = hasData;
    },

    updateisDataLoading(isDataLoading) {
      this.isDataLoading = isDataLoading;
    },
  },
};

/**
 * @typedef {Object} Filters
 * @property {Array<string>} [blocks] - block ids.
 * @property {Array<number>} [daysOfWeek] - indexes of `DAYS_OF_WEEK`.
 * @property {Array<string>} [routes] - route ids.
 * @property {Array<string>} [stops] - stop ids.
 * @property {Array<string>} [teams] - team ids.
 * @property {{start: any, end: any}} [time] - TODO
 * @property {Array<string>} [tripHeadsigns]
 */

/**
 * @typedef {Object} ReportQuery
 * @property {string} [endDate]
 * @property {GroupBy} [groupBy]
 * @property {string} [startDate]
 * @property {ReportType} [type]
 */
</script>

<style lang="scss">
.reports {
  &--vertical-menu {
    display: flex;
    height: 100%;
  }

  &__menu {
    width: 250px;
  }

  &--horizontal-menu {
    display: block;

    .reports__menu {
      width: 100%;
    }
  }

  &__content {
    flex: 1;
    padding: $view-standard-padding;
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: $report-view-header-index;
  }
}
</style>
