<template>
  <div
    class="navbar navbar--vertical shrink"
    :class="{ 'shrink--true': shrinkedNav, 'shrink--false': !shrinkedNav }"
  >
    <div class="brand">
      <router-link
        v-if="group._id"
        :to="
          $store.getters.hasPermission(Permission.VIEW_DASHBOARD)
            ? getGroupRoute(GroupRoute.DASHBOARD)
            : getGroupRoute(GroupRoute.TRIP_LIST)
        "
      >
        <img alt="Brand" src="@/assets/img/pysae-64.png" width="50" />
      </router-link>
    </div>
    <div class="nav nav--vertical">
      <ul v-if="group._id">
        <li v-if="$store.getters.hasPermission(Permission.VIEW_DASHBOARD)">
          <router-link :to="getGroupRoute(GroupRoute.DASHBOARD)" class="nav__item" :title="$t('dashboard')">
            <font-awesome-icon icon="fa-chart-pie" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('dashboard') }}</span>
          </router-link>
        </li>

        <li>
          <router-link :to="getGroupRoute(GroupRoute.LIVE_MAP)" class="nav__item" :title="$t('map')">
            <font-awesome-icon icon="fa-regular fa-map" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('map') }}</span>
          </router-link>
        </li>

        <li>
          <router-link :to="getGroupRoute(GroupRoute.TRIP_LIST)" class="nav__item" :title="$t('trips')">
            <font-awesome-icon icon="fa-calendar-days" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('trips') }}</span>
          </router-link>
        </li>

        <!-- <li v-if="$store.getters.hasPermission(Permission.VIEW_DEVIATION_LIST)">
          <router-link
            :to="getGroupRoute(GroupRoute.DEVIATION_LIST)"
            class="nav__item"
            :title="$t('deviation')"
          >
            <font-awesome-icon icon="fa-solid fa-arrows-split-up-and-left" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('deviations') }}</span>
          </router-link>
        </li> -->

        <li v-if="$store.getters.hasPermission(Permission.VIEW_DUTIES)">
          <router-link
            :to="getGroupRoute(GroupRoute.DUTY_LIST)"
            class="nav__item duties"
            :title="$t('duties')"
          >
            <i class="icon-steering-wheel nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('duties') }}</span>
          </router-link>
        </li>
        <li v-if="$store.getters.hasPermission(Permission.VIEW_PLANNING)">
          <router-link :to="getGroupRoute(GroupRoute.PLANNING)" class="nav__item" :title="$t('planning')">
            <font-awesome-icon icon="fa-id-card" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('planning') }}</span>
          </router-link>
        </li>

        <li v-if="$store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES)">
          <router-link :to="getGroupRoute(GroupRoute.STOP_LIST)" class="nav__item" :title="$t('stops')">
            <font-awesome-icon icon="fa-sign-hanging" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('stops') }}</span>
          </router-link>
        </li>

        <li
          v-if="$store.getters.hasPermission(Permission.VIEW_MESSAGES)"
          @mouseover="toggleSubmenu(GroupRoute.MESSAGE_LIST)"
          @mouseleave="toggleSubmenu()"
        >
          <router-link
            :to="getGroupRoute(GroupRoute.MESSAGE_INBOX)"
            class="nav__item"
            :class="{
              'nav__item--active': GroupRoute.MESSAGE_LIST === activeGroupRoute,
            }"
            :title="$t('messages')"
          >
            <div>
              <font-awesome-icon icon="fa-envelope" class="nav__icon" />
              <span v-if="nbUnreadMessages > 0" class="message-counter">
                {{ nbUnreadMessages }}
              </span>
              <span class="shrink__hide nav__label">{{ $t('messages') }}</span>
            </div>
          </router-link>
          <NavbarSubmenu
            v-if="displayedSubmenu === GroupRoute.MESSAGE_LIST"
            :items="submenuItemsByCategory[GroupRoute.MESSAGE_LIST]"
          />
        </li>

        <li @mouseover="toggleSubmenu(GroupRoute.PASSENGERS_MESSAGE)" @mouseleave="toggleSubmenu()">
          <router-link
            :to="getGroupRoute(GroupRoute.PASSENGERS_MESSAGE)"
            class="nav__item"
            :class="{
              'submenu-active': displayedSubmenu === GroupRoute.PASSENGERS_MESSAGE,
              'nav__item--active': activeGroupRoute === GroupRoute.PASSENGERS_MESSAGE,
            }"
            :title="$t('passengersMessages')"
          >
            <font-awesome-icon icon="fa-comment-dots" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('passengersMessages') }}</span>
          </router-link>
          <NavbarSubmenu
            v-if="displayedSubmenu === GroupRoute.PASSENGERS_MESSAGE"
            :items="submenuItemsByCategory[GroupRoute.PASSENGERS_MESSAGE]"
          />
        </li>

        <li
          v-if="$store.getters.hasPermission(Permission.VIEW_REPORT)"
          @mouseover="toggleSubmenu(GroupRoute.REPORTS)"
          @mouseleave="toggleSubmenu()"
        >
          <router-link
            :to="
              getGroupRoute(GroupRoute.REPORTING_TRIP_KM, {
                query: {
                  startDate: reportingDateRange.startDate,
                  endDate: reportingDateRange.endDate,
                },
              })
            "
            class="nav__item"
            :class="{
              'submenu-active': displayedSubmenu === GroupRoute.REPORTS,
              'nav__item--active': activeGroupRoute === GroupRoute.REPORTS,
            }"
            :title="$t('report')"
          >
            <font-awesome-icon icon="fa-chart-line" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('report') }}</span>
          </router-link>
          <NavbarSubmenu
            v-if="displayedSubmenu === GroupRoute.REPORTS"
            :items="submenuItemsByCategory[GroupRoute.REPORTS]"
          />
        </li>
        <li
          v-if="$store.getters.hasPermission(Permission.VIEW_GROUP_FEATURES)"
          @mouseover="toggleSubmenu(GroupRoute.RESOURCES)"
          @mouseleave="toggleSubmenu()"
        >
          <router-link
            :to="getGroupRoute(GroupRoute.TRANSPORT_PLAN_LIST)"
            class="nav__item"
            :class="{
              'submenu-active': displayedSubmenu === GroupRoute.RESOURCES,
              'nav__item--active': activeGroupRoute === GroupRoute.RESOURCES,
            }"
            :title="$t('resources')"
          >
            <font-awesome-icon icon="fa-briefcase" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('resources') }}</span>
          </router-link>
          <NavbarSubmenu
            v-if="displayedSubmenu === GroupRoute.RESOURCES"
            :items="submenuItemsByCategory[GroupRoute.RESOURCES]"
          />
        </li>
        <li v-if="$store.getters.hasPermission(Permission.VIEW_INTEGRATIONS)">
          <router-link
            :to="getGroupRoute(GroupRoute.INTEGRATIONS)"
            class="nav__item"
            :title="$t('integrations')"
          >
            <font-awesome-icon icon="fa-link" class="nav__icon" />
            <span class="shrink__hide nav__label">{{ $t('integrations') }}</span>
          </router-link>
        </li>
      </ul>
    </div>

    <button class="shrink__button" @click="toggleShrink" @mousedown.prevent>
      <font-awesome-icon :icon="shrinkedNav ? 'fa-angle-right' : 'fa-angle-left'" />
    </button>
  </div>
</template>

<script>
import NavbarSubmenu from '@/components/ui/NavbarSubmenu.vue';
import { GroupRoute } from '@/libs/routing';
import { Permission } from '@/auth';

/** @enum {Array<SubmenuItem>} */
const SubMenusCategories = {
  [GroupRoute.PASSENGERS_MESSAGE]: [
    { localeKey: 'submenus.passengersMessage', route: GroupRoute.PASSENGERS_MESSAGE },
    { localeKey: 'submenus.passengerApp', route: GroupRoute.PASSENGERS_APP },
    { localeKey: 'submenus.gtfsRT', route: GroupRoute.GTFS_RT },
    { localeKey: 'submenus.passengersScreen', route: GroupRoute.PASSENGERS_SCREEN },
  ],
  [GroupRoute.MESSAGE_LIST]: [
    { localeKey: 'submenus.inbox', route: GroupRoute.MESSAGE_INBOX },
    { localeKey: 'submenus.sent', route: GroupRoute.MESSAGE_SENT },
  ],
  [GroupRoute.REPORTS]: [
    { localeKey: 'metrics.trip-km', route: GroupRoute.REPORTING_TRIP_KM },
    { localeKey: 'metrics.punctuality', route: GroupRoute.REPORTING_PUNCTUALITY },
    { localeKey: 'metrics.travel-time', route: GroupRoute.REPORTING_TRAVEL_TIME },
    { localeKey: 'metrics.trip-tracking', route: GroupRoute.REPORTING_TRIP_TRACKING },
    { localeKey: 'metrics.passenger-counts', route: GroupRoute.REPORTING_PASSENGER_COUNTS },
    { localeKey: 'metrics.connected-devices', route: GroupRoute.REPORTING_CONNECTED_DEVICES },
    { localeKey: 'metrics.passengers-app-sessions', route: GroupRoute.REPORTING_PASSENGERS_APP },
  ],
  [GroupRoute.RESOURCES]: [
    { localeKey: 'submenus.transportPlan', route: GroupRoute.TRANSPORT_PLAN_LIST },
    { localeKey: 'submenus.users', route: GroupRoute.USER_LIST },
    { localeKey: 'submenus.devices', route: GroupRoute.DEVICE_LIST },
    { localeKey: 'submenus.drivers', route: GroupRoute.DRIVER_LIST },
    { localeKey: 'submenus.vehicles', route: GroupRoute.VEHICLE_LIST },
  ],
};

export default {
  name: 'NavBarLeft',

  components: {
    NavbarSubmenu,
  },

  data: () => ({
    /** @type {string} */
    displayedSubmenu: null,

    GroupRoute,
    Permission,

    /** @type {boolean} */
    shrinkedNav: false,
  }),

  computed: {
    /** @return {?string} */
    activeGroupRoute() {
      const categoriesList = Object.keys(SubMenusCategories);

      let groupCategory = null;
      categoriesList.some(category => {
        if (SubMenusCategories[category].map(el => el.route).includes(this.$route.name)) {
          groupCategory = category;
          return true;
        }
        return false;
      });

      return groupCategory;
    },

    /** @return {number} */
    nbUnreadMessages() {
      return this.$store.getters['messages/nbUnreadMessages'];
    },

    /** @return {import('@/store').GTFSDateRange} */
    reportingDateRange() {
      return this.$store.state.reportingDateRange;
    },

    /** @return {import('@/store').Group} */
    group() {
      return this.$store.getters.group;
    },

    /**
     * Returns an array of formatted items for each submenu category
     * @return {{[key in SubMenusCategories]: Array<FormattedSubmenuItem>}}
     */
    submenuItemsByCategory() {
      const categoriesList = Object.keys(SubMenusCategories);
      return categoriesList.reduce((acc, category) => {
        const items = [];
        if (category === GroupRoute.PASSENGERS_MESSAGE || category === GroupRoute.RESOURCES) {
          SubMenusCategories[category].forEach(item => {
            if (
              (item.route !== GroupRoute.GTFS_RT ||
                this.$store.getters.hasPermission(Permission.VIEW_GTFS_RT)) &&
              (item.route !== GroupRoute.PASSENGERS_APP ||
                this.$store.getters.hasPermission(Permission.VIEW_PASSENGERS_APP))
            ) {
              items.push({
                route: this.getGroupRoute(item.route),
                translation: this.$t(item.localeKey),
              });
            }
          });
        } else if (category === GroupRoute.MESSAGE_LIST) {
          SubMenusCategories[category].forEach(item => {
            items.push({
              route: this.getGroupRoute(item.route),
              translation: this.$t(item.localeKey),
            });
          });
        } else if (category === GroupRoute.REPORTS) {
          SubMenusCategories[category].forEach(item => {
            const baseQuery = {
              startDate: this.reportingDateRange.startDate,
              endDate: this.reportingDateRange.endDate,
            };

            items.push({
              route: this.getGroupRoute(item.route, { query: baseQuery }),
              translation: this.$t(item.localeKey),
            });
          });
        }
        acc[category] = items;
        return acc;
      }, {});
    },
  },

  created() {
    this.shrinkedNav = localStorage.getItem('settings.op.navLeftClosed') === 'true';
  },

  methods: {
    toggleShrink() {
      this.shrinkedNav = !this.shrinkedNav;
      // @ts-ignore
      localStorage.setItem('settings.op.navLeftClosed', this.shrinkedNav);
    },

    /** @param {GroupRoute} [category] */
    toggleSubmenu(category) {
      this.displayedSubmenu = category || null;
    },

    /**
     * @param {string} name
     * @param {Partial<import('vue-router').Location>} [args]
     * @return {import('vue-router').Location}
     */
    getGroupRoute(name, args = {}) {
      return { name, params: { groupId: this.group._id }, ...args };
    },
  },
};

/**
 * @typedef {Object} FormattedSubmenuItem
 * @property {import('vue-router').Location} route
 * @property {string} translation
 */

/**
 * @typedef {Object} SubmenuItem
 * @property {string} localeKey
 * @property {GroupRoute} route
 */
</script>

<style lang="scss" scoped>
.navbar--vertical {
  position: relative;
  flex-direction: column;
  background-color: $secondary;

  li {
    position: relative;

    .nav__item {
      height: auto;
      padding: 12px 0 12px 27px;
      border-left: 3px solid transparent;
      color: $text-neutral;
      line-height: normal;

      @media screen and (max-height: 750px) {
        padding: 8px 0 8px 27px;
      }

      &--active,
      &.submenu-active,
      &:hover {
        border-left-color: $primary-light;
        background-color: darken($secondary, 2%);
        color: $text-light;
      }
    }
  }

  .duties {
    .icon-steering-wheel {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      background-image: url('../../assets/img/icons/steering-wheel.svg');
      background-size: cover;
    }

    &:hover,
    &.exact-active {
      .icon-steering-wheel {
        background-image: url('../../assets/img/icons/steering-wheel-light.svg');
      }
    }
  }

  .message-counter {
    position: absolute;
    top: 5px;
    left: 36px;
    min-width: 1.6em;
    padding: 1px 3px;
    border-radius: 10px;
    background-color: $danger;
    color: $text-light;
    font-size: 0.6em;
    text-align: center;
  }
}

/**
 * Shrink --true
 *   __hide
 *   __button
 */
.shrink {
  width: 180px;

  &--true {
    width: 84px;

    .shrink__hide {
      display: none;
    }
  }

  &--false {
    .shrink__show {
      display: none;
    }
  }

  &__button {
    align-self: flex-end;
    width: 84px;
    height: 50px;
    border: 0;
    background-color: transparent;
    color: $text-light;
    font-size: 1.5em;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: rgb(0 0 0 / 12%);
    }
  }
}

/* Custom */
.brand {
  display: inline-block;
  margin-bottom: 5px;
  padding: 20px;
  line-height: 0;
  text-align: center;
}

.envelope::before {
  content: '\f0e0';
}
</style>

<i18n locale="fr">
{
  "submenus": {
    "devices": "Appareils",
    "drivers": "Conducteurs",
    "gtfsRT": "GTFS-RT",
    "passengerApp": "Application voyageurs",
    "passengersMessage": "Messages voyageurs",
    "passengersScreen": "Affichage voyageurs",
    "transportPlan": "Plan de transport",
    "users": "Utilisateurs",
    "vehicles": "Véhicules",
    "inbox": "Boîte de réception",
    "sent": "Messages envoyés"
  },
  "passengersMessages": "Infos voyageurs",
  "duties": "Prises de service",
  "deviations": "Déviations",
  "dashboard": "Tableau de bord",
  "map": "Carte",
  "messages": "Messagerie",
  "resources": "Ressources",
  "report": "Rapports",
  "search": "Recherche",
  "stops": "Arrêts",
  "planning": "Planning",
  "trips": "Courses",
  "integrations": "Intégrations"
}
</i18n>

<i18n locale="en">
{
  "submenus": {
    "devices": "Devices",
    "drivers": "Drivers",
    "gtfsRT": "GTFS-RT",
    "passengerApp": "Passengers app",
    "passengersMessage": "Passengers message",
    "passengersScreen": "Passengers display",
    "transportPlan": "Transport plan",
    "users": "Users",
    "vehicles": "Vehicles",
    "inbox": "Inbox",
    "sent": "Sent"

  },
  "passengersMessages": "Passenger info",
  "dashboard": "Dashboard",
  "duties": "Duties",
  "deviations": "Deviations",
  "map": "Map",
  "messages": "Messages",
  "resources": "Resources",
  "report": "Reporting",
  "search": "Search",
  "stops": "Stops",
  "planning": "Planning",
  "trips": "Trips",
  "integrations": "Integrations"
}
</i18n>

<i18n locale="cz">
{
  "report": "Report",
  "trips": "Linky",
  "deviation": "Odchylka",
  "passengersMessages": "Informace o cestujících",
  "map": "Mapa",
  "messages": "Zprávy",
  "search": "Vyhledávání",
  "dashboard": "Přehled"
}
</i18n>

<i18n locale="de">
{
  "report": "Bericht",
  "trips": "Fahrten",
  "deviation": "Abweichung",
  "passengersMessages": "Informationen für Passagiere",
  "map": "Karte",
  "messages": "Nachrichten",
  "search": "Suche",
  "dashboard": "Übersicht"
}
</i18n>

<i18n locale="es">
{
  "report": "Informe",
  "trips": "Servicios",
  "deviation": "Desviación",
  "passengersMessages": "Información del pasajero",
  "map": "Mapa",
  "messages": "Mensajes",
  "search": "Buscar",
  "dashboard": "Resumen"
}
</i18n>

<i18n locale="it">
{
  "report": "Report",
  "trips": "Servizi",
  "deviation": "Deviazione",
  "passengersMessages": "Informazioni sui passeggeri",
  "map": "Mappa",
  "messages": "Messaggi",
  "search": "Cerca",
  "dashboard": "Panoramica"
}
</i18n>

<i18n locale="pl">
{
  "report": "Raport",
  "trips": "Usługi",
  "deviation": "Odchylenie",
  "passengersMessages": "Dane pasażera",
  "map": "Mapa",
  "messages": "Wiadomości",
  "search": "Wyszukaj",
  "dashboard": "Podsumowanie"
}
</i18n>
