/* eslint-disable no-use-before-define */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { padLeft } from './strings';

dayjs.extend(duration);

/** @enum {string} */
export const DatetimeFormat = {
  HHMM: 'HH:MM',
  HHMMSS: 'HH:MM:SS',
  DDMMYYYY: 'DD/MM/YYYY',
  GTFS_DATE: 'gtfsDate', // YYYYMMDD
  GTFS_DATE_QUERY: 'gtfsDateQuery', // YYYY-MM-DD
};

/**
 * Convert a date String formatted like YYYYMMDD (as in GTFS specification) to a Date object.
 * @param {string} gtfsDate
 * @return {Date}
 */
function dateGtfsFormatToObj(gtfsDate) {
  try {
    const year = gtfsDate.slice(0, 4);
    const month = gtfsDate.slice(4, 6);
    const day = gtfsDate.slice(6, 8);
    return new Date(Number.parseInt(year), Number.parseInt(month) - 1, Number.parseInt(day));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return null;
  }
}

/**
 * Converts a date to Midnight date as specified by GTFS (Noon minus 12 hours).
 *
 * @see https://gist.github.com/derhuerst/574edc94981a21ef0ce90713f1cff7f6
 *
 * @param {string|number|Date} date
 * @param {string} [tz]
 * @return {Date}
 */
function dateMidnight(date, tz) {
  const noon = dateNoon(date);
  const midnight = new Date(noon.getTime() - 3600 * 12 * 1000);
  midnight.setHours(midnight.getHours(), 0, 0, 0);

  if (!tz) return midnight;
  return new Date(midnight.getTime() - getTzDelta(midnight, tz) * 1000);
}

/**
 * @param {string|number|Date} date
 * @param {string} [tz]
 * @return {Date}
 */
function dateNoon(date, tz) {
  const noon = new Date(date);
  noon.setHours(12, 0, 0, 0);
  if (!tz) return noon;
  return new Date(noon.getTime() - getTzDelta(noon, tz) * 1000);
}

/**
 * Convert a Date object to String formatted like YYYYMMDD (as in GTFS specification).
 * @param {Date} date
 * @return {string}
 */
function dateObjToGtfsFormat(date) {
  let gtfsDate = padLeft(date.getFullYear(), 4, '0');
  gtfsDate += padLeft(date.getMonth() + 1, 2, '0');
  gtfsDate += padLeft(date.getDate(), 2, '0');
  return gtfsDate;
}

/**
 * Convert a timestamp to String formatted like YYYYMMDD
 * @param {number} ts
 * @param {boolean} [noHyphen]
 * @return {string}
 */
function timestampToGtfsFormat(ts, noHyphen) {
  const gtfsDate = dateObjToGtfsFormat(new Date(ts));
  const gtfsYear = gtfsDate.slice(0, 4);
  const gtfsMonth = gtfsDate.slice(4, 6);
  const gtfsDay = gtfsDate.slice(6, 8);
  if (noHyphen) return `${gtfsYear}${gtfsMonth}${gtfsDay}`;
  return `${gtfsYear}-${gtfsMonth}-${gtfsDay}`;
}

/**
 * Convert a timestamp in locale date string accorded to format option.
 * @param {number | Date} value - timestamp or Date object to convert.
 * @param {Object} options - object containing format and unix options
 * @param {DatetimeFormat} options.format -  should be one of the DatetimeFormat enum members
 * @param {boolean} options.unix - if true, timestamp is in unix format (seconds)
 * @param {string} [options.tz] - Optional tz to apply tzDelta
 * @return {string} datetime in requested format.
 */
function dateToFormattedString(value, options) {
  if (!value || !options || !options.format) return null;

  const initialDate = options.unix && value.constructor === Number ? value * 1000 : value;
  const tzDelta = getTzDelta(initialDate, options.tz);
  const date = new Date(initialDate + tzDelta * 1000);

  switch (options.format) {
    case DatetimeFormat.HHMM:
      return new Date(date).toLocaleString().substr(11, 5);
    case DatetimeFormat.HHMMSS:
      return new Date(date).toLocaleString().substr(11, 8);
    case DatetimeFormat.DDMMYYYY:
      return new Date(date).toLocaleString().substr(0, 10);
    case DatetimeFormat.GTFS_DATE:
      return dateObjToGtfsFormat(new Date(date));
    case DatetimeFormat.GTFS_DATE_QUERY: {
      const gtfsDate = dateObjToGtfsFormat(new Date(date));
      const gtfsYear = gtfsDate.slice(0, 4);
      const gtfsMonth = gtfsDate.slice(4, 6);
      const gtfsDay = gtfsDate.slice(6, 8);
      return `${gtfsYear}-${gtfsMonth}-${gtfsDay}`;
    }
    default: // format not supported
      throw new Error(`Unknown format ${options.format} for dateToFormattedString()`);
  }
}

/**
 * Format a delay to string.
 * @param {number} delay
 * @return {string}
 */
function formatDelay(delay) {
  // TODO: [I18n] Doesn't support localization.
  return [delay < 0 ? '-' : '', Math.floor(Math.abs(delay) / 60), 'min ', Math.abs(delay) % 60, 's'].join('');
}

/**
 * Convert seconds to fromat 'HH:mm:ss'
 * @param {number} sec
 * @return {string}
 */
function formatSecondsToHHMMSS(sec) {
  if (!sec) return '-';
  return dayjs.duration(sec, 'seconds').format('HH:mm:ss');
}

/**
 * Return date part of ISO 8601 format from `date`.
 * @param {Date} date
 * @return {string}
 */
function getISODate(date) {
  const year = String(date.getFullYear()).padStart(4, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Compute timezone offset in seconds between user's browser locale and given timezone.
 *
 * @param {string|number|Date} date
 * @param {string} [tz]
 * @return {number}
 */
function getTzDelta(date, tz) {
  if (!tz) return 0;

  const referenceDate = new Date(date);

  const reference = {
    hours: referenceDate.getHours(),
    minutes: referenceDate.getMinutes(),
    seconds: referenceDate.getSeconds(),
  };

  const dateString = referenceDate.toLocaleDateString('en');
  const localeDateString = referenceDate.toLocaleDateString('en', {
    timeZone: tz,
    hour12: false,
    hourCycle: 'h24',
  });

  const daysOffset = (new Date(localeDateString).getTime() - new Date(dateString).getTime()) / 1000;

  const localeTimeString = referenceDate.toLocaleTimeString('en', {
    timeZone: tz,
    hour12: false,
    hourCycle: 'h24',
  });

  const parsed = timeHHmmToTimeObj(localeTimeString);

  const tzDelta =
    daysOffset +
    (parsed.hours - reference.hours) * 3600 +
    (parsed.minutes - reference.minutes) * 60 +
    (parsed.seconds - reference.seconds);

  return tzDelta;
}

/**
 * For a given date, get the ISO week number
 *
 * Based on information at:
 *
 *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
 *
 * Algorithm is to find nearest thursday, it's year
 * is the year of the week number. Then get weeks
 * between that date and the first day of that year.
 *
 * Note that dates in one year can be weeks of previous
 * or next year, overlap is up to 3 days.
 *
 * e.g. 2014/12/29 is Monday in week  1 of 2015
 *      2012/1/1   is Sunday in week 52 of 2011
 * @param {Date} date
 * @return {[number, number]} year and week number.
 */
function getWeekNumber(date) {
  // Copy date so don't modify original
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  return [d.getUTCFullYear(), weekNo];
}

/**
 * Take a startDate and an endDate, return endDate corresponding to 31days period
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Date}
 */
function recalculateMaxDateTo31days(startDate, endDate) {
  const copyDateStart = dayjs(startDate);
  let copyDateEnd = endDate;

  const startDatePlusOneMonth = copyDateStart.add(30, 'day').toDate();
  // if more than 1 month selected, endDate become startDate + 1 month
  if (startDatePlusOneMonth < endDate) {
    copyDateEnd = startDatePlusOneMonth;
  }
  return copyDateEnd;
}

/**
 * Convert seconds in a relative time format. When value represent more than a
 * day, returns `false`.
 * @param {number} seconds - Value in seconds to format.
 * @return {string|false} - Formatted time.
 */
function secondsFormatTime(seconds) {
  // TODO: [I18n] Doesn't support localization.
  const value = Math.floor(seconds);
  if (value < 60) {
    // just seconds.
    return `${value}s`;
  }
  if (value < 3600) {
    // Minutes & seconds.
    const minutes = Math.floor(value / 60);
    const seconds = value - minutes * 60;
    return `${minutes}mn${seconds}s`;
  }
  if (value < 86400) {
    // Hours, minutes & secondes.
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;
    return `${hours}h${minutes}mn${seconds}s`;
  }
  // More than a day.
  return false;
}

/**
 * @param {number} seconds
 * @return {string}
 */
function secondsToStr(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const pad = v => String(v).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}`;
}

/**
 * @param {string} time
 * @return {number}
 */
function strToSeconds(time) {
  const parts = time.split(':');

  return Number(parts[0]) * 3600 + Number(parts[1]) * 60;
}

/**
 * Convert a timestamp in locale date format.
 *
 * @param {number} timestamp - timestamp in seconds to convert.
 * @param {Object} [options]
 * @param {string} [options.tz] - Optional tz to apply tzDelta
 * @return {string} timestampFormatHHMM - HH:MM time format.
 */
function timestampFormatDate(timestamp, options) {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, options?.tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);
  return date.toLocaleDateString();
}

/**
 * Convert a timestamp in locale date format and HH:MM time format.
 *
 * @param {number} timestamp - timestamp in seconds to convert.
 * @param {Object} [options]
 * @param {string} [options.tz] - Optional tz to apply tzDelta
 * @return {string} timestampFormatHHMM - HH:MM time format.
 */
function timestampFormatDateAndHHMM(timestamp, options) {
  const formatedDate = timestampFormatDate(timestamp, options);
  const formatedTimestamp = timestampFormatHHMM(timestamp, options);
  return `${formatedDate} - ${formatedTimestamp}`;
}

/**
 * Convert a timestamp in HH:MM time format.
 *
 * @param {number} timestamp - timestamp in seconds to convert.
 * @param {Object} [options]
 * @param {Date} [options.refDate] - Optional reference date to show day delta.
 * @param {string} [options.tz] - Optional tz to apply tzDelta
 * @return {string} timestampFormatHHMM - HH:MM time format.
 */
function timestampFormatHHMM(timestamp, options) {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, options?.tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);

  let res = padLeft(date.getHours(), 2, '0');
  res += ':';
  res += padLeft(date.getMinutes(), 2, '0');

  const refDate = options?.refDate;
  if (refDate) {
    date.setHours(refDate.getHours());
    const dDay = Math.round((date - refDate) / 864e5);
    if (dDay > 0) res += ` (J+${dDay})`;
    if (dDay < 0) res += ` (J${dDay})`;
  }

  return res;
}

/**
 * Convert a timestamp in HH:MM:SS time format.
 *
 * @param {number} timestamp - timestamp in seconds to convert.
 * @param {Object} [options]
 * @param {Date} [options.refDate] - Optional reference date to show day delta.
 * @param {string} [options.tz] - Optional tz to apply tzDelta
 * @return {string} timestampFormatHHMMSS - HH:MM:SS time format.
 */
function timestampFormatHHMMSS(timestamp, options) {
  const dateTimestamp = timestamp * 1000;
  const tzDelta = getTzDelta(dateTimestamp, options?.tz);

  const date = new Date(dateTimestamp + tzDelta * 1000);

  let res = padLeft(date.getHours(), 2, '0');
  res += ':';
  res += padLeft(date.getMinutes(), 2, '0');
  res += ':';
  res += padLeft(date.getSeconds(), 2, '0');

  const refDate = options?.refDate;
  if (refDate) {
    date.setHours(refDate.getHours());
    const dDay = Math.round((date - refDate) / 864e5);
    if (dDay > 0) res += ` (J+${dDay})`;
    if (dDay < 0) res += ` (J${dDay})`;
  }

  return res;
}

/**
 * Take an HH:mm time and return a TimeObject
 * @param {string} hhmmTime
 * @returns {TimeObject}
 */
function timeHHmmToTimeObj(hhmmTime) {
  const [hours, minutes, seconds] = hhmmTime.split(':');
  return {
    hours: hours !== undefined ? parseInt(hours) : 0,
    minutes: minutes !== undefined ? parseInt(minutes) : 0,
    seconds: seconds !== undefined ? parseInt(seconds) : 0,
  };
}

/**
 * Take a timeObject and a current Date on format YYYYMMDD and return a timestamp
 * @param {string} currentDate
 * @param {TimeObject} timeObject
 * @returns {number}
 */
function timeObjToTimestamp(currentDate, timeObject) {
  let date = dayjs(currentDate, 'YYYYMMDD');
  date = date.hour(timeObject.hours).minute(timeObject.minutes).second(timeObject.seconds);
  return date.unix();
}

/**
 * Converts a date to Midnight timestamp in seconds as specified by GTFS (Noon minus 12 hours).
 *
 * @see https://gist.github.com/derhuerst/574edc94981a21ef0ce90713f1cff7f6
 *
 * @param {string|number|Date} date
 * @param {string} [tz]
 * @return {number}
 */
function timestampMidnight(date, tz) {
  return dateMidnight(date, tz).getTime() / 1000;
}

export {
  dateGtfsFormatToObj,
  dateMidnight,
  dateNoon,
  dateObjToGtfsFormat,
  timestampToGtfsFormat,
  dateToFormattedString,
  formatDelay,
  formatSecondsToHHMMSS,
  getISODate,
  getTzDelta,
  getWeekNumber,
  recalculateMaxDateTo31days,
  secondsFormatTime,
  secondsToStr,
  strToSeconds,
  timestampFormatDate,
  timestampFormatDateAndHHMM,
  timestampFormatHHMM,
  timestampFormatHHMMSS,
  timeHHmmToTimeObj,
  timeObjToTimestamp,
  timestampMidnight,
};

/**
 * @typedef {Object} TimeObject
 * @property {number?} hours
 * @property {number?} minutes
 * @property {number?} seconds
 */
