<template>
  <div class="admin-trip-tracking">
    <div class="admin-trip-tracking__header">
      <div>
        <div class="admin-trip-tracking__from-to">{{ $t('from') }}</div>
        <DatePicker v-model:value="startDate" input-class="admin-trip-tracking__datepicker" />
      </div>
      <div>
        <div class="admin-trip-tracking__from-to">{{ $t('to') }}</div>
        <DatePicker v-model:value="endDate" input-class="admin-trip-tracking__datepicker" />
      </div>
      <Btn
        type="secondary"
        :disabled="!isNewDateSelected"
        class="admin-trip-tracking__btn"
        :data-loading="isBtnLoading"
        @click="getTripTracking"
      >
        <font-awesome-icon v-if="!isBtnLoading" icon="fa-chart-simple" />
        <font-awesome-icon v-else icon="fa-circle-notch" spin />
        {{ isBtnLoading ? $t('dataPreparation') : $t('calculate') }}
      </Btn>
    </div>
    <DataGridVuetify
      ref="dataGrid"
      v-model:renderedDataLength="renderedDataLength"
      :title="$t('groups', { count: renderedDataLength })"
      :data="groupListFormatted"
      :datagrid="datagrid"
      :loading="loading"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { stats as ApiStats } from '@/api';
import { admin as ApiAdmin } from '@/api.js';
import Btn from '@/components/ui/Btn.vue';
import DatePicker from '@/components/ui/Datepicker.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';

import { getDatagrid, TripTrackingCategory } from './TripTracking.conf.js';

export default {
  name: 'AdminTripTracking',

  components: {
    Btn,
    DataGridVuetify,
    DatePicker,
  },

  emits: ['tripTrackingDataReady'],

  data() {
    return {
      datagrid: getDatagrid(),
      /** @type {Array<import('@/store').Group>} */
      groups: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {number} */
      renderedDataLength: null,
      /** @type {Array<TripTrackingGroup>} */
      groupListFormatted: [],
      /** @type {Date} */
      startDate: dayjs(new Date()).subtract(1, 'week').toDate(),
      /** @type {Date} */
      endDate: new Date(),
      isBtnLoading: false,
      /** @type {Date} */
      initialEndDate: null,
      /** @type {Date} */
      initialStartDate: null,
    };
  },

  computed: {
    /** @return {Boolean} */
    isNewDateSelected() {
      if (!this.initialStartDate || !this.initialEndDate) return true;
      return (
        dateObjToGtfsFormat(this.initialStartDate) !== dateObjToGtfsFormat(this.startDate) ||
        dateObjToGtfsFormat(this.initialEndDate) !== dateObjToGtfsFormat(this.endDate)
      );
    },
  },

  async created() {
    this.groups = (await ApiAdmin.groups.get()) || [];
    this.formatList();
  },

  methods: {
    exportData() {
      const data = this.$refs.dataGrid.exportData();
      // Replace trip tracking category by trip tracking percentage
      data.data.forEach(dataRow => {
        dataRow.tripTrackingCategory = this.groupListFormatted.find(
          group => group._id === dataRow.group_id
        ).tripTracking;
      });
      this.$emit('tripTrackingDataReady', data);
    },

    formatList() {
      this.loading = true;
      this.groupListFormatted = this.groups.reduce((acc, group) => {
        group.tripTracking = 0;
        group.tripTrackingCategory = this.getTripTrackingCategory(group.tripTracking);
        acc.push(group);
        return acc;
      }, []);
      this.loading = false;
    },

    /**
     * @param {number} tripTrackingPercentage
     */
    getTripTrackingCategory(tripTrackingPercentage) {
      let tripTrackingCategory = TripTrackingCategory.LOW;
      if (tripTrackingPercentage >= 50) tripTrackingCategory = TripTrackingCategory.MID;
      if (tripTrackingPercentage >= 80) tripTrackingCategory = TripTrackingCategory.HIGH;
      return tripTrackingCategory;
    },

    async getTripTracking() {
      this.isBtnLoading = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const [i, group] of this.groups.entries()) {
        await this.getOneGroupTripTracking(group._id);
        if (i === this.groups.length - 1) {
          this.isBtnLoading = false;
        }
      }
      this.initialStartDate = this.startDate;
      this.initialEndDate = this.endDate;
    },

    async getOneGroupTripTracking(groupId) {
      const startDate = dateObjToGtfsFormat(this.startDate);
      const endDate = dateObjToGtfsFormat(this.endDate);

      const responseDay = await ApiStats.getTripTracking(groupId, 'day', startDate, endDate);

      let trackingPercentage = 0;
      if (responseDay.length > 0) {
        const sumOfTrips = responseDay.reduce(
          (acc, trip) => {
            acc.totalScheduledTrips += trip.scheduled;
            acc.totalTrackedTrips += trip.tracked;
            return acc;
          },
          { totalScheduledTrips: 0, totalTrackedTrips: 0 }
        );
        trackingPercentage = Math.floor(
          (sumOfTrips.totalTrackedTrips / sumOfTrips.totalScheduledTrips) * 100
        );
      }

      const currentGroupIndex = this.groupListFormatted.findIndex(group => group._id === groupId);
      this.groupListFormatted[currentGroupIndex].tripTracking = trackingPercentage;
      this.groupListFormatted[currentGroupIndex].tripTrackingCategory =
        this.getTripTrackingCategory(trackingPercentage);
      return groupId;
    },
  },
};

/**
 * @typedef {Object} TripTrackingGroup
 * @extends Group
 * @property {number} tripTracking
 * @property {TripTrackingCategory} tripTrackingCategory
 */
</script>

<style lang="scss">
.admin-trip-tracking {
  .datepicker {
    box-sizing: border-box;
    padding: 0.5em 0.5em 0.5em 0.8em;
    border: 1px solid $border;
    border-radius: 2em;
    background: $canvas;
    box-shadow: 0 2px 2px rgb(0 0 0 / 7%);
  }

  &__btn {
    height: 43px;

    svg {
      margin-right: 5px;
    }
  }

  &__datepicker {
    display: flex;
    justify-content: center;
    width: 90px;
    padding: 5px 0;
    border: none;
    background-color: transparent;
    color: var(--font-color);
    font-weight: $font-weight-semi-bold;
    font-size: 1em;
    font-family: $font-poppins;
    line-height: 1.5em;
    text-align: center;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &__from-to {
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }

  &__header {
    display: flex;
    gap: 10px;
    align-items: end;
    margin-bottom: 12px;
  }
}
</style>

<i18n locale="fr">
  {
    "calculate": "Calculer",
    "dataPreparation": "Préparation des données",
    "from": "DU",
    "groups": "Groupes",
    "to": "AU",
  }
  </i18n>

<i18n locale="en">
  {
    "calculate": "Calculate",
    "dataPreparation": "Data preparation",
    "from": "FROM",
    "groups": "Groups",
    "to": "TO",
  }
  </i18n>
