<template>
  <div class="profile">
    <form @submit.prevent="save">
      <div class="form-group">
        <label class="form-group__label" for="email">
          {{ $t('email') }}
        </label>
        <input
          id="email"
          class="form-group__input form-group__input--disabled"
          disabled
          type="text"
          :value="email"
        />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="name">
          {{ $t('name') }}
        </label>
        <input id="name" v-model="name" class="form-group__input" type="text" />
      </div>

      <div class="form-group">
        <label class="form-group__label" for="passwordOld">
          {{ $t('passwordOld') }}
        </label>
        <input
          id="passwordOld"
          v-model="passwordOld"
          class="form-group__input"
          :type="viewPassword.old ? 'text' : 'password'"
          @blur="checkMissingField()"
        />
        <v-icon
          v-if="passwordOld.length > 0"
          size="x-small"
          class="eye"
          @mousedown="showPassword('old')"
          @mouseup="hidePassword('old')"
        >
          fa:fas fa-eye
        </v-icon>
      </div>

      <NewPassword
        ref="newPassword"
        @changeNewPasswordValidity="setNewPasswordValidity"
        @newPassword="setNewPassword"
      />

      <div v-if="showError" class="error-message__container">
        <span class="error-message__message">
          {{ $t('badOldPassword') }}
        </span>
      </div>

      <div v-if="showMissingFieldError" class="error-message__container">
        <span class="error-message__message">
          {{ $t('missingField') }}
        </span>
      </div>

      <Btn type="primary" :disabled="!hasNameChanged && !isFormDataValid">
        <font-awesome-icon :icon="showSuccess ? 'fa-check' : 'fa-floppy-disk'" />
        <span v-if="showSuccess">
          {{ $t('saved') }}
        </span>
        <span v-else>
          {{ $t('save') }}
        </span>
      </Btn>
    </form>
  </div>
</template>

<script>
import api from '@/api';
import NewPassword from '@/components/common/NewPassword.vue';
import Btn from '@/components/ui/Btn.vue';

export default {
  name: 'Profile',

  components: {
    Btn,
    NewPassword,
  },

  data: () => ({
    email: '',
    isNewPasswordValid: false,
    name: '',
    passwordNew: null,
    passwordOld: '',
    showError: false,
    showMissingFieldError: false,
    showSuccess: false,
    viewPassword: {
      old: false,
    },
  }),

  computed: {
    /** @return {boolean} */
    isFormDataValid() {
      return this.passwordOld !== '' && this.isNewPasswordValid;
    },

    /** @return {boolean} */
    hasNameChanged() {
      return this.name !== this.user.name;
    },

    /** @return {import('@/store').User} */
    user() {
      return this.$store.state.user;
    },
  },

  watch: {
    'user.email': {
      immediate: true,
      handler(value) {
        this.email = value;
      },
    },

    'user.name': {
      immediate: true,
      handler(value) {
        this.name = value;
      },
    },
  },

  methods: {
    checkMissingField() {
      if (this.passwordNew && (this.passwordOld.length === 0 || this.passwordNew.length === 0)) {
        this.showMissingFieldError = true;
      } else {
        this.showMissingFieldError = false;
      }
    },

    save() {
      if (this.hasNameChanged) {
        this.saveName();
      }
      if (this.isFormDataValid) {
        this.savePassword();
      }
    },

    async saveName() {
      this.showError = false;
      const user = { ...this.user, name: this.name };
      await this.$store.dispatch('setUser', user);
    },

    async savePassword() {
      try {
        await api.passwordChange(this.passwordOld, this.passwordNew, this.passwordNew);

        this.$refs.newPassword.reset();
        this.passwordOld = '';
        this.showSuccess = true;
      } catch (e) {
        this.showSuccess = false;
        this.showError = true;
      }
    },

    /**
     * @param {string} newPassword
     * */
    setNewPassword(newPassword) {
      this.passwordNew = newPassword;
      this.checkMissingField();
    },

    /**
     * @param {Boolean} isValid
     */
    setNewPasswordValidity(isValid) {
      this.isNewPasswordValid = isValid;
    },

    hidePassword(type) {
      this.viewPassword[type] = false;
    },

    showPassword(type) {
      this.viewPassword[type] = true;
    },
  },
};
</script>

<style lang="scss">
.profile {
  margin: 30px 30px 30px 0;
  padding: $view-standard-padding;

  .eye {
    position: absolute;
    top: 38px;
    left: 48%;
    color: $text-neutral;
  }

  .form-group {
    position: relative;
  }

  .form-group__input {
    position: relative;
    width: 50%;
  }
}
</style>

<i18n locale="fr">
{
  "badOldPassword": "Mot de passe actuel incorrect",
  "email": "Email",
  "missingField": "Veuillez compléter tous les champs",
  "name": "Nom",
  "passwordOld": "Mot de passe actuel",
  "saved": "Enregistré"
}
</i18n>

<i18n locale="en">
{
  "badOldPassword": "Wrong current password",
  "email": "Email",
  "missingField": "Please complete all required fields",
  "name": "Name",
  "passwordOld": "Old password",
  "saved": "Saved"
}
</i18n>

<i18n locale="cz">
{
  "name": "Název"
}
</i18n>

<i18n locale="de">
{
  "name": "Name"
}
</i18n>

<i18n locale="es">
{
  "name": "Nombre"
}
</i18n>

<i18n locale="it">
{
  "name": "Nome"
}
</i18n>

<i18n locale="pl">
{
  "name": "Nazwa"
}
</i18n>
