<template>
  <v-card v-if="device" class="live-map-device-card" elevation="0">
    <div class="live-map-device-card__header">
      <div class="live-map-device-card__trip">
        <template v-if="device?.trip_id">
          <div class="live-map-device-card__trip__badge">
            <RouteBadge
              v-if="device.route_id && gtfsRoutes[device.route_id]"
              class="live-map-device-card__trip-number"
              :route="gtfsRoutes[device.route_id]"
              :is-experimental="isDeactivatedRoute(device.route_id)"
              :value="gtfsRoutes[device.route_id].route_short_name"
            />
          </div>
          <div class="live-map-device-card__trip__infos">
            <div
              v-if="!$store.getters.hasPermission(Permission.VIEW_TRIP_VIEW)"
              class="live-map-device-card__trip__infos__title"
            >
              {{ formattedTripName }}
            </div>
            <router-link
              v-else
              class="live-map-device-card__trip__infos__title"
              :to="{
                name: GroupRoute.TRIP_DETAILED,
                params: { tripId: device.trip_id },
                query: tripStartDate ? { date: tripStartDate } : null,
              }"
            >
              {{ formattedTripName }}
            </router-link>

            <div class="live-map-device-card__trip__infos__state">
              <DeviceDelay
                v-if="!device.trip_pending && device.trip_id"
                class="live-map-device-card__trip-delay"
                :delay="device.delay"
              />

              <span v-else-if="device.trip_pending">
                {{ $t('tripPending') }}
              </span>
              <div v-if="device?.off_itinerary" class="info-tag info-tag--warning">
                <span>
                  <font-awesome-icon icon="fa-circle-exclamation" class="mr-1" />
                  {{ $t('offItinerary') }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          {{ $t('HLP') }}
        </template>
      </div>

      <div class="live-map-device-card__sub-infos">
        <div class="live-map-device-card__sub-infos__grouped">
          <div v-if="permission && deviceItem.driver" class="live-map-device-card__sub-infos__item">
            <span :title="deviceItem.driver">
              <v-icon>fa:fas fa-user</v-icon>
              {{ deviceItem.driver }}
            </span>
          </div>
          <div v-if="permission && deviceItem.vehicle" class="live-map-device-card__sub-infos__item">
            <span :title="deviceItem.vehicle">
              <v-icon>fa:fas fa-bus</v-icon>
              {{ deviceItem.vehicle }}
            </span>
          </div>
        </div>
        <div class="live-map-device-card__sub-infos__grouped">
          <div class="live-map-device-card__sub-infos__item">
            <span>
              <v-icon
                icon="fa-circle"
                class="live-map-device-card__status-indicator"
                :class="isOnline ? 'online' : 'offline'"
              >
                fa:fas fa-circle
              </v-icon>
              <router-link
                v-if="permission"
                class="live-map-device-card__device-name"
                :to="{ name: GroupRoute.DEVICE_DETAILLED, params: { deviceId } }"
              >
                {{ device.name || deviceId }}
              </router-link>
            </span>
          </div>

          <div v-if="device?.trip_id && vehicleLoadLabel" class="live-map-device-card__sub-infos__item">
            <span class="live-map-device-card__count-passenger">
              <v-icon>fa:fas fa-users</v-icon>
              {{ vehicleLoadLabel }}
              <v-chip
                size="small"
                :title="!vehicleLoadPercentage ? $t('noCapacity') : null"
                :variant="vehicleLoadPercentage && vehicleLoadPercentage > 100 ? 'flat' : 'outlined'"
              >
                {{ (vehicleLoadPercentage ? vehicleLoadPercentage : '-') + ' %' }}
              </v-chip>
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import DeviceDelay from '@/components/common/DeviceDelay.vue';
import RouteBadge from '@/components/common/RouteBadge.vue';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { Permission } from '@/auth';
import { GroupRoute } from '@/libs/routing';

export default {
  name: 'LiveMapDeviceRow',

  components: {
    DeviceDelay,
    RouteBadge,
  },

  props: {
    deviceId: {
      type: String,
      required: true,
    },
  },

  emits: ['change:formattedTripName', 'formatedDeviceItem'],

  data: () => ({
    GroupRoute,
    Permission,

    formattedTripName: '',
  }),

  computed: {
    ...(() => {
      const mapping = {
        /** @return {{[id: string]: import('@/store/drivers').Driver}} */
        driversList: (/** @type {import('@/store').State} */ state) => state.drivers.list,
        /** @return {{[id: string]: import('@/store/vehicles').Vehicle}} */
        vehiclesList: (/** @type {import('@/store').State} */ state) => state.vehicles.list,
      };

      return /** @type {typeof mapping} */ (mapState(mapping));
    })(),

    /** @return {?import('@/store/activity-log').ActivityLogEntry} */
    activityLogEntry() {
      if (this.deviceId && this.device?.ts)
        return this.$store.getters['activityLog/getEntry'](this.deviceId, this.device?.ts);
      return null;
    },

    deviceItem() {
      if (this.device) {
        return {
          _id: this.deviceId,
          driver: this.driver && `${this.driver.driver_name} (${this.driver.staff_number})`,
          vehicle: this.vehicle && `${this.vehicle.license_plate} (${this.vehicle.fleet_number})`,
          formattedTripName: this.formattedTripName,
          route: this.gtfsRoutes[this.device.route_id],
        };
      }
      return null;
    },

    /** @return {?import('@/store/devices').Device} */
    device() {
      return this.$store.getters['devices/visibleDevices'][this.deviceId];
    },

    /** @return {?import('@/store/drivers').Driver} */
    driver() {
      if (this.activityLogEntry) {
        return this.driversList[this.activityLogEntry.driver_id];
      }

      return null;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {{[routeId: string]: import('@/store/gtfs').Route}} */
    gtfsRoutes() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'routes');
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {boolean} */
    isOnline() {
      return !!this.$store.state.devices.online[this.device.device_id];
    },

    /** @return {boolean} */
    permission() {
      return (
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_DEVICES) &&
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_DRIVERS) &&
        this.$store.getters.hasPermission(Permission.VIEW_TRIP_VEHICLES)
      );
    },

    /** @return {string} */
    tripStartDate() {
      if (this.device && this.device.trip && this.device.trip.start_date) {
        return getISODate(dateGtfsFormatToObj(this.device.trip.start_date));
      }
      return null;
    },

    /** @return {?import('@/store/vehicles').Vehicle} */
    vehicle() {
      if (this.activityLogEntry) {
        return this.vehiclesList[this.activityLogEntry.vehicle_id];
      }

      return null;
    },
    /** @return {?string} */
    vehicleLoadLabel() {
      if (!this.device?.vehicle_load) return null;
      let label = this.device?.vehicle_load.toString();
      if (this.vehicle?.capacity) {
        label += `/${this.vehicle.capacity}`;
      }
      return label;
    },
    /** @return {?number} */
    vehicleLoadPercentage() {
      if (!this.vehicle?.capacity || !this.device?.vehicle_load || this.device.vehicle_load < 0) return null;
      else return Math.max(0, Math.round((this.device.vehicle_load / this.vehicle?.capacity) * 100));
    },
  },

  watch: {
    'device.trip_id': {
      immediate: true,
      handler(tripId) {
        this.generateFormattedTripName(tripId).then(name => {
          this.formattedTripName = name;
        });
      },
    },

    formattedTripName() {
      this.$emit('change:formattedTripName', this.formattedTripName);
    },
    deviceItem() {
      if (this.deviceItem) this.$emit('formatedDeviceItem', this.deviceItem);
    },
  },

  methods: {
    /**
     * @param {string} tripId
     * @return {Promise<string>}
     */
    async generateFormattedTripName(tripId) {
      if (tripId) {
        return this.$store.dispatch('gtfs/formatTripName', {
          tripId,
          date: new Date(),
        });
      }
      return '';
    },

    /**
     * @param {string} routeId
     * @return {boolean}
     */
    isDeactivatedRoute(routeId) {
      return this.$store.getters.group.deactivated_routes?.includes(routeId);
    },
  },
};
</script>

<style lang="scss">
.live-map-device-card {
  margin: 12px;
  border: 1px solid $border;
  border-radius: 12px;
  background: $canvas;

  // padding: 10px;

  &__trip {
    display: flex;
    gap: 12px;
    padding: 16px;

    &__badge {
      height: 64px;

      .route-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        max-width: 64px;
        height: 100%;
        padding: 8px;
        border-radius: 8px;
        font-weight: 600;
      }
    }

    &__infos {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      gap: 4px;

      &__title {
        font-weight: 600;
      }

      &__state {
        display: flex;
        flex-direction: row;
        gap: 12px;

        > span {
          line-height: 20px;
        }
      }
    }
  }

  &__sub-infos {
    display: flex;
    flex-direction: column;
    background-color: $background;

    &__grouped {
      display: flex;
      flex-direction: row;
    }

    &__item {
      width: 100%;
      padding: 12px 16px;
      border-top: 1px solid $background-variant;

      &:not(:last-child) {
        border-right: 1px solid $background-variant;
      }

      span:not(.v-chip) {
        @include multi-line-ellipsis($font-size: 14px, $line-height: 1.85, $lines-to-show: 1);
      }

      .v-chip {
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;

        &:not(.v-chip--variant-flat) {
          background-color: $background-variant;
          color: $text-dark-variant;
        }
      }

      i {
        width: 20px;
        margin-right: 6px;
        font-size: 14px;
      }
    }
  }

  &__status-indicator {
    &.online {
      color: $primary-light;
    }

    &.offline {
      color: $danger;
    }
  }
}
</style>

<i18n locale="fr">
{
  "HLP": "Haut-le-pied",
  "offline": "Déconnecté",
  "tripPending": "Acheminement",
  "offItinerary": "Hors itinéraire",
  "noCapacity": "La capacité du véhicule n'est pas connue"
}
</i18n>

<i18n locale="en">
{
  "HLP": "Dead running",
  "offline": "Offline",
  "tripPending": "Routing",
  "offItinerary": "Off itinerary",
  "noCapacity": "Vehicle capacity is unknown"
}
</i18n>

<i18n locale="cz">
{
  "offline": "Offline",
  "tripPending": "Pojíždění",
  "HLP": "Na cestě z/do depa"
}
</i18n>

<i18n locale="de">
{
  "offline": "Offline",
  "tripPending": "Routing",
  "HLP": "Fahrt zum oder vom Busdepot"
}
</i18n>

<i18n locale="es">
{
  "offline": "Sin conexión",
  "tripPending": "Buscando ruta",
  "HLP": "Sin pasajeros"
}
</i18n>

<i18n locale="it">
{
  "offline": "Offline",
  "tripPending": "Percorso",
  "HLP": "In tragitto da o verso il deposito"
}
</i18n>

<i18n locale="pl">
{
  "offline": "W trybie offline",
  "tripPending": "Trasowanie",
  "HLP": "Przejazdy bez pasażerów"
}
</i18n>
