<template>
  <Modal class="modal-download" @close="close">
    <template #title>
      {{ $t('downloadGtfs') }}
    </template>

    <template #body>
      <div>
        <input v-model="fullDownloadLink" class="modal-download__input" :readonly="true" />
        <Btn class="modal-download__btn-copy" type="secondary" smaller @click="copyLinkToClipboard">
          <font-awesome-icon icon="fa-link" class="modal-download__icon" />
          {{ $t('copyUrl') }}
        </Btn>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" class="modal-download__btn" @click="startDownload">
        <font-awesome-icon icon="fa-download" class="modal-download__icon" />
        {{ $t('downloadZip') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import { useToast } from 'vue-toastification';

import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { baseAppUrl } from '@/libs/helpers/url';

const toast = useToast();

export default {
  name: 'ModalDownload',

  components: {
    Btn,
    Modal,
  },

  props: {
    groupId: {
      required: true,
      type: String,
    },

    gtfsId: {
      default: null,
      type: String,
    },
  },

  emits: ['close'],

  computed: {
    /** @return {String} */
    downloadLink() {
      return `/api/v2/groups/${this.groupId}/gtfs/${this.gtfsId}`;
    },

    /** @return {String} */
    fullDownloadLink() {
      return baseAppUrl + this.downloadLink;
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    copyLinkToClipboard() {
      navigator.clipboard.writeText(this.fullDownloadLink).then(() => {
        const toastId = toast.success(this.$t('copiedLink'), {
          icon: 'fas fa-check',
          position: 'bottom-right',
        });
        setTimeout(() => toast.dismiss(toastId), 5000);
      });
    },

    startDownload() {
      window.location = this.downloadLink;
    },
  },
};
</script>

<style lang="scss">
.modal-download {
  &__btn-copy {
    vertical-align: middle;
    width: 85px;
    height: 30px;
  }

  &__icon {
    margin-right: 5px;
  }

  &__input {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    width: calc(100% - 85px);
    height: 30px;
    padding-left: 5px;
    border: 1px solid $border-variant;
    border-radius: 5px;
    color: $text-dark-variant;
    font-family: $font-poppins;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<i18n locale="fr">
{
  "copiedLink": "Lien copié",
  "copyUrl": "Copier",
  "downloadGtfs": "Télécharger le plan de transport",
  "downloadZip": "Télécharger (.zip)"
}
</i18n>

<i18n locale="en">
{
  "copiedLink": "Copied link",
  "copyUrl": "Copy",
  "downloadGtfs": "Download transport plan",
  "downloadZip": "Download (.zip)"
}
</i18n>
