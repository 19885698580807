<template>
  <div class="reports-punctuality">
    <div v-if="type === ReportType.GRAPH" class="report">
      <BarChart :categories="chartCategorie" :series="historyPunctuality" :title="$t('punctuality')" />
    </div>
    <div v-else-if="type === ReportType.TABLE" class="report-table">
      <div class="reports-punctuality__header">
        <div class="reports-punctuality__header-subitem">
          <div class="dropdown" :class="{ 'dropdown--open': dropdownOpened }">
            <Btn
              type="secondary"
              :class="{ active: dropdownOpened }"
              @click="dropdownToggle"
              @mousedown.prevent
            >
              <span>{{ $t('columns.title') }}</span>
              <font-awesome-icon :icon="dropdownOpened ? 'fa-angle-up' : 'fa-angle-down'" />
            </Btn>

            <ul class="dropdown__menu dropdown__menu--columns" @click.stop>
              <li class="dropdown__item dropdown__item--separator">
                <Checkbox
                  id="columns"
                  :checked="allColumnsChecked"
                  @change="checked => checkColumns(checked)"
                >
                  <template #label>
                    {{ $t('columns.allChecked') }}
                  </template>
                </Checkbox>
              </li>

              <li
                v-for="column in ['categories', 'average', 'rate']"
                :key="column + '_dropdown'"
                class="dropdown__item"
              >
                <Checkbox
                  :id="column"
                  :checked="showColumns[column]"
                  @change="checked => checkColumns(checked, column)"
                >
                  <template #label>
                    {{ $t('columns.' + column) }}
                  </template>
                </Checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="!isLoading" class="reports-punctuality__header-subitem">
          <Btn type="secondary" @click="downloadRaw()">
            {{ $t('downloadRawData') }}
          </Btn>
          <Btn type="primary" @click="downloadFormated()">
            {{ $t('download') }}
          </Btn>
        </div>
      </div>
      <div v-if="isLoading" class="reports-punctuality__table-loading">
        <AnimatedDots />
      </div>
      <table v-else class="table">
        <thead class="table__head">
          <tr>
            <th v-for="column in ColumnsGroupBy[groupBy]" :key="column" class="table__head-cell">
              {{ $t(`columns.${column}`) }}
            </th>

            <template v-if="showColumns.categories">
              <th
                v-for="(col, i) in categoriesColumns"
                :key="i"
                class="table__head-cell"
                :class="`type--${col.type}`"
              >
                {{ col.title }}
              </th>
            </template>

            <th v-if="showColumns.average" class="table__head-cell">
              {{ $t('columns.average') }}
            </th>

            <th v-if="showColumns.rate" class="table__head-cell">
              {{ $t('columns.rate') }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="id in sortedRows" :key="id" class="table__row">
            <td v-for="column in ColumnsGroupBy[groupBy]" :key="column" class="table__cell">
              {{ groupedData[id][column] }}
            </td>

            <template v-if="showColumns.categories">
              <td
                v-for="(col, i) in categoriesColumns"
                :key="i"
                class="table__cell"
                :class="`type--${col.type}`"
              >
                {{ groupedData[id].categories[i] || 0 }}
              </td>
            </template>

            <td v-if="showColumns.average" class="table__cell">
              {{ formatDelay(Math.round(groupedData[id].average / groupedData[id].count / 60)) }}
            </td>

            <td v-if="showColumns.rate" class="table__cell">
              {{ Math.round((groupedData[id].rate / groupedData[id].count) * 100) }}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { trips as ApiTrips } from '@/api';
import AnimatedDots from '@/components/ui/AnimatedDots.vue';
import BarChart from '@/components/ui/BarChart.vue';
import Btn from '@/components/ui/Btn.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import { dateGtfsFormatToObj, getWeekNumber } from '@/libs/helpers/dates';
import { PunctualityReportHelper, GroupBy, ReportType } from '@/libs/reports';
import { DelayState } from '@/store/devices';
import { triggerDownloadCSV } from '@/libs/csv';

const ColumnsGroupBy = {
  [GroupBy.DAY]: ['date'],
  [GroupBy.DEVICE]: ['id', 'name'],
  [GroupBy.ROUTE]: ['id', 'routeShortName', 'routeLongName'],
  [GroupBy.SERVICE]: ['id'],
  [GroupBy.STOP]: ['id', 'name'],
  [GroupBy.TEAM]: ['name'],
  [GroupBy.TRIP]: ['id', 'formattedTripName'],
  [GroupBy.WEEK]: ['week'],
};
const { formatDelay } = PunctualityReportHelper;

export default {
  name: 'ReportsPunctuality',

  components: {
    AnimatedDots,
    BarChart,
    Btn,
    Checkbox,
  },

  props: {
    /** @type {Vue.PropOptions<{start: number, end: number}>} */
    dateInterval: {
      type: Object,
      required: true,
    },

    /** @type {Vue.PropOptions<import('./index.vue').Filters>} */
    filters: {
      type: Object,
      required: true,
    },

    /** @type {Vue.PropOptions<GroupBy>} */
    groupBy: {
      type: String,
      default: GroupBy.ROUTE,
    },

    /** @type {Vue.PropOptions<ReportType>} */
    type: {
      type: String,
      default: ReportType.TABLE,
    },
    /** @type {Vue.PropOptions<ReportQuery>} */
    queryParams: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['error'],

  data: () => ({
    ColumnsGroupBy,
    DelayState,
    ReportType,
    formatDelay,

    /** @type {string} */
    downloadLink: null,
    /** @type {string} */
    downloadRawDataLink: null,

    /** @type {boolean} */
    dropdownOpened: false,

    /** @type {{[key: string]: string}} */
    formattedTripNames: {},

    /** @type {{[gtfsId: string]: import('@/store/gtfs').Gtfs}} */
    gtfsData: {},

    /** @type {ReadonlyArray<import('@/api').StopTimeHistory>} */
    historyStopTimes: [],

    isLoading: false,

    showColumns: {
      average: true,
      categories: true,
      rate: true,
    },
  }),

  computed: {
    /** @return {boolean} */
    allColumnsChecked() {
      return Object.values(this.showColumns).every(c => c);
    },

    /** @return {{bounds: {down: number, up: number}, cats: Array<number>}} */
    categories() {
      return (
        this.$store.getters.group.categories || {
          bounds: { down: 2, up: 3 },
          cats: [-120, 0, 120, 300],
        }
      );
    },

    /** @return {Array<{ title: string, type: string }>} */
    categoriesColumns() {
      return PunctualityReportHelper.categoriesColumns(this.categories);
    },

    /** @return {Array<string>} */
    chartCategorie() {
      const categorie = [];
      const columns = ColumnsGroupBy[this.groupBy];

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const groupedKey in this.groupedData) {
        let name = '';
        const data = this.groupedData[groupedKey];
        for (let i = 0; i < columns.length; i += 1) {
          name = `${name} ${data[columns[i]]}`;
        }
        categorie.push(name);
      }
      return categorie;
    },
    /** @return {(sth: import('@/api').StopTimeHistory) => string} */
    getGroupById() {
      switch (this.groupBy) {
        case GroupBy.DAY: {
          return sth => sth.start_date;
        }

        case GroupBy.DEVICE: {
          return sth => sth.device_id;
        }

        case GroupBy.ROUTE: {
          return sth => {
            /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
            const { trips } = this.gtfsData[sth.gtfs_id];
            const trip = trips[sth.trip_id];

            return trip.route_id;
          };
        }

        case GroupBy.SERVICE: {
          return sth => {
            /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
            const { trips } = this.gtfsData[sth.gtfs_id];
            const trip = trips[sth.trip_id];

            return trip.block_id || '-';
          };
        }

        case GroupBy.STOP: {
          return sth => sth.stop_id;
        }

        case GroupBy.TEAM: {
          return sth => {
            /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
            const { trips } = this.gtfsData[sth.gtfs_id];
            const trip = trips[sth.trip_id];

            return trip.team_id || '-';
          };
        }

        case GroupBy.TRIP: {
          return sth => sth.trip_id;
        }

        case GroupBy.WEEK: {
          return sth => {
            const date = dateGtfsFormatToObj(sth.start_date);
            const [year, week] = getWeekNumber(date);

            return `${year}-W${String(week).padStart(2, '0')}`;
          };
        }
        default:
          return () => '-';
      }
    },

    /** @return {Array<ChartData>} */
    donutChartData() {
      const chartData = [];
      this.mapStops.forEach(stop => {
        const data = {};
        this.categoriesColumns.forEach((col, i) => {
          if (this.groupedData[stop.id]) {
            data[col.type] = (data[col.type] || 0) + (this.groupedData[stop.id].categories[i] || 0);
          } else {
            data[col.type] = (data[col.type] || 0) + 0;
          }
        });
        data.lon = this.gtfsStops[stop.id].stop_lon;
        data.lat = this.gtfsStops[stop.id].stop_lat;
        data.name = this.gtfsStops[stop.id].stop_name;
        chartData.push(data);
      });
      return chartData;
    },

    /** @return {{[id: string]: RowResults & RowInfos}} */
    groupedData() {
      if (this.isLoading) return {};

      const cats = this.categories.cats.concat([Infinity]);

      return this.historyStopTimes.reduce((acc, hst) => {
        if (!this.isFiltered(hst)) return acc;

        const id = this.getGroupById(hst);
        const indexCategory = cats.findIndex(upper => hst.calculatedDelay < upper);
        const rate =
          indexCategory >= this.categories.bounds.down && indexCategory <= this.categories.bounds.up ? 1 : 0;

        if (!acc[id]) {
          const infos = this.getGroupByColumnsInfos(hst);
          acc[id] = {
            id,
            ...infos,

            categories: {
              [indexCategory]: 1,
            },

            average: hst.calculatedDelay,
            rate,

            count: 1,
          };
        } else {
          acc[id].categories[indexCategory] = (acc[id].categories[indexCategory] || 0) + 1;
          acc[id].average += hst.calculatedDelay;
          acc[id].rate += rate;

          acc[id].count += 1;
        }

        return acc;
      }, {});
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {{[tripId: string]: import('@/store/gtfs').Trip}} */
    gtfsTrips() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'trips');
    },

    /** @return {{[stopId: string]: import('@/store/gtfs').Stop}} */
    gtfsStops() {
      return this.$store.getters['gtfs/getCachedGtfsTable'](this.gtfsId, 'stops');
    },

    /** @return {Array<import('@/components/ui/BarChart.vue').BarSerie>} */
    historyPunctuality() {
      const data = this.sortedRows.reduce(
        (acc, rowKey, j) => {
          this.categoriesColumns.forEach((col, i) => {
            acc[col.type][j] = (acc[col.type][j] || 0) + (this.groupedData[rowKey].categories[i] || 0);
          });

          return acc;
        },
        { early: [], late: [], 'on-time': [] }
      );

      return [
        {
          color: '#00B871', // $primary-light
          data: data['on-time'],
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.ON_TIME}`)),
        },
        {
          color: '#EB5757', // $danger
          data: data.early,
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.EARLY}`)),
        },
        {
          color: '#f99c49', // $warn
          data: data.late,
          name: /** @type {string} */ (this.$t(`delayState.${DelayState.LATE}`)),
        },
      ];
    },

    /** @return {Array<import('@/components/map/MapboxMap.vue').MapStop>} */
    mapStops() {
      return Object.keys(this.gtfsStops).map(id => ({ id, highlight: false }));
    },

    /** @return {Array<string>} */
    sortedRows() {
      return PunctualityReportHelper.sortedRows(this.groupedData);
    },
  },

  watch: {
    dateInterval: {
      immediate: true,
      handler(value, old) {
        const currValue = value || {};
        const oldValue = old || {};

        if (currValue.start !== oldValue.start || currValue.end !== oldValue.end) {
          this.loadData();
        }
      },
    },
  },

  methods: {
    downloadFormated() {
      if (!this.downloadLink) {
        this.downloadLink = PunctualityReportHelper.downloadFormatedReportPunctuality(
          this.groupedData,
          this.downloadLink,
          this.categories,
          this.groupBy
        );
      }
      triggerDownloadCSV(
        this.downloadLink,
        this.$t('dataFileName', [this.queryParams.startDate, this.queryParams.endDate])
      );
    },
    downloadRaw() {
      if (!this.downloadRawDataLink) {
        this.downloadRawDataLink = PunctualityReportHelper.downloadRawReportPunctuality(
          this.historyStopTimes,
          this.downloadRawDataLink
        );
      }
      triggerDownloadCSV(
        this.downloadRawDataLink,
        this.$t('rawDataFilename', [this.queryParams.startDate, this.queryParams.endDate])
      );
    },
    /**
     * @param {boolean} check
     * @param {string} [column]
     */
    checkColumns(check, column) {
      if (check) {
        if (column) {
          this.showColumns[column] = true;
        } else {
          this.showColumns = {
            average: true,
            categories: true,
            rate: true,
          };
        }
      } else if (column) {
        this.showColumns[column] = false;
      } else {
        this.showColumns = {
          average: false,
          categories: false,
          rate: false,
        };
      }
    },

    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = null;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },

    /**
     * @param {import('@/api').StopTimeHistory} sth
     * @return {{[column: string]: string}}
     */
    getGroupByColumnsInfos(sth) {
      switch (this.groupBy) {
        case GroupBy.DAY: {
          return { date: this.$d(dateGtfsFormatToObj(sth.start_date), 'dateShort') };
        }

        case GroupBy.DEVICE: {
          /** @type {?import('@/store/devices').Device} */
          const device = this.$store.state.devices.list[sth.device_id];

          return { name: (device && device.name) || '-' };
        }

        case GroupBy.ROUTE: {
          /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
          const { trips } = this.gtfsData[sth.gtfs_id];
          /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
          const { routes } = this.gtfsData[sth.gtfs_id];

          const trip = trips[sth.trip_id];
          const route = routes[trip.route_id];

          return {
            routeShortName: (route && route.route_short_name) || '-',
            routeLongName: (route && route.route_long_name) || '-',
          };
        }

        case GroupBy.SERVICE: {
          return {};
        }

        case GroupBy.STOP: {
          /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
          const { stops } = this.gtfsData[sth.gtfs_id];
          const stop = stops[sth.stop_id];

          return { name: (stop && stop.stop_name) || '-' };
        }

        case GroupBy.TEAM: {
          /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
          const { trips } = this.gtfsData[sth.gtfs_id];
          const trip = trips[sth.trip_id];

          return { name: trip.team_id || '-' };
        }

        case GroupBy.TRIP: {
          const key = `${sth.gtfs_id}/${sth.trip_id}/${sth.start_date}`;

          return { formattedTripName: this.formattedTripNames[key] || '-' };
        }

        case GroupBy.WEEK: {
          const date = dateGtfsFormatToObj(sth.start_date);
          const weekNumber = getWeekNumber(date);

          // Set to monday of week
          date.setDate(date.getDate() - ((date.getDay() || 7) - 1));
          const week = `S${String(weekNumber[1]).padStart(2, '0')} (${this.$d(date, 'dateShort')})`;

          return { week };
        }
        default:
          return {};
      }
    },

    /**
     * @param {import('@/api').StopTimeHistory} sth
     * @return {boolean}
     */
    isFiltered(sth) {
      if (!this.gtfsData[sth.gtfs_id]) return false;
      /** @type {{[tripId: string]: import('@/store/gtfs').Trip}} */
      const { trips } = this.gtfsData[sth.gtfs_id];
      const trip = trips[sth.trip_id];

      if (this.filters.blocks && !this.filters.blocks.includes(trip.block_id)) {
        return false;
      }

      const dayOfWeek = (dateGtfsFormatToObj(sth.start_date).getDay() || 7) - 1;
      if (this.filters.daysOfWeek && !this.filters.daysOfWeek.includes(dayOfWeek)) {
        return false;
      }

      if (this.filters.routes && !this.filters.routes.includes(trip.route_id)) {
        return false;
      }

      if (this.filters.stops && !this.filters.stops.includes(sth.stop_id)) {
        return false;
      }

      if (this.filters.teams && !this.filters.teams.includes(trip.team_id)) {
        return false;
      }

      if (this.filters.time) {
        const value = trip.stop_times[0].departure_time;

        if (value < this.filters.time.start || value > this.filters.time.end) {
          return false;
        }
      }

      if (this.filters.tripHeadsigns && !this.filters.tripHeadsigns.includes(trip.trip_headsign)) {
        return false;
      }

      return true;
    },

    async loadData() {
      this.isLoading = true;
      const deviceList = this.$store.getters['devices/getDevicesInfos'];

      const loadHistory = async () => {
        try {
          const history = await ApiTrips.getHistoryStopTimes(
            this.$store.getters.group._id,
            this.dateInterval.start,
            this.dateInterval.end
          ).then(history =>
            history.reduce((acc, sth) => {
              if (sth.event === 'departure' || (sth.event === 'arrival' && sth.last_stop)) {
                const key = JSON.stringify({
                  startDate: sth.start_date,
                  gtfsId: sth.gtfs_id,
                  tripId: sth.trip_id,
                  stopSequence: sth.stop_sequence,
                });

                if (!acc[key]) {
                  acc[key] = sth;
                }
              }

              return acc;
            }, /** @type {{[key: string]: import('@/api').StopTimeHistory}} */ ({}))
          );
          this.historyStopTimes = Object.freeze(Object.values(history));
        } catch (e) {
          this.$emit('error');
          this.isLoading = false;
        }
      };

      const loadGtfs = async () => {
        /** @type {Array<string>} */
        const gtfsIds = (
          await this.$store.dispatch('gtfs/getGtfsPublicationsIn', {
            fromTs: this.dateInterval.start,
            toTs: this.dateInterval.end,
          })
        ).map(p => p.current_file);

        /** @type {{[gtfsId: string]: import('@/store/gtfs').Gtfs}} */
        const gtfsData = {};

        await Promise.all(
          gtfsIds.map(async gtfsId => {
            const [routes, stops, trips] = await Promise.all([
              this.$store.dispatch('gtfs/getRoutesMap', { gtfsId }),
              this.$store.dispatch('gtfs/getStopsMap', { gtfsId }),
              this.$store.dispatch('gtfs/getTripsMap', { gtfsId }),
            ]);

            gtfsData[gtfsId] = { routes, stops, trips };
          })
        );

        this.gtfsData = Object.freeze(gtfsData);
      };

      await Promise.all([loadHistory(), loadGtfs()]);

      /** @type {{[key: string]: string}} */
      const formattedTripNames = {};
      await Promise.all(
        this.historyStopTimes.map(async hst => {
          const { gtfs_id: gtfsId, trip_id: tripId, start_date: startDate } = hst;
          const key = `${gtfsId}/${tripId}/${startDate}`;

          if (!formattedTripNames[key]) {
            formattedTripNames[key] = '-';
            return this.$store
              .dispatch('gtfs/formatTripName', {
                gtfsId,
                tripId,
                date: dateGtfsFormatToObj(startDate),
              })
              .then(name => {
                formattedTripNames[key] = name;
              });
          }
          return null;
        }, /** @type {{[key: string]: string}} */ ({}))
      );

      this.historyStopTimes = this.historyStopTimes.map(hst => {
        const newObject = {
          ...hst,
          ...PunctualityReportHelper.getAdditionalStopTimeInfosByGtfs(
            hst,
            this.gtfsData,
            this.$store.getters.group.tz,
            deviceList,
            this.$store.getters.activeTeams
          ),
        };
        return newObject;
      });

      this.formattedTripNames = Object.freeze(formattedTripNames);

      this.downloadLink = null;
      this.downloadRawDataLink = null;
      this.isLoading = false;
    },

    onMarkerMouseEnterOrLeave(index) {
      this.$refs[`marker_${index}`][0].togglePopup();
    },
  },
};

/**
 * @typedef {Object} ChartData
 * @property {number} early
 * @property {number} late
 * @property {number} onTime
 * @property {number} lat
 * @property {number} lon
 * @property {string} name
 */

/**
 * @typedef {{[column: string]: string}} RowInfos
 */

/**
 * @typedef {Object} RowResults
 * @property {string} id
 * @property {{[indexCategory: number]: number}} categories
 * @property {number} average
 * @property {number} rate
 * @property {number} count
 */

/**
 * @typedef {Object} ReportQuery
 * @property {string} [endDate]
 * @property {GroupBy} [groupBy]
 * @property {ReportPages} [metric]
 * @property {string} [startDate]
 * @property {ReportType} [type]
 */
</script>

<style lang="scss">
.reports-punctuality {
  height: 100%;

  .box {
    float: left;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    &--red {
      background-color: $danger;
    }

    &--green {
      background-color: $primary-light;
    }

    &--orange {
      background-color: $warn;
    }
  }

  .report-map {
    height: 100%;
  }

  .type {
    &--early {
      background-color: change-color($danger, $alpha: 0.15);
    }

    &--late {
      background-color: change-color($warn, $alpha: 0.15);
    }

    &--on-time {
      background-color: change-color($primary-light, $alpha: 0.15);
    }
  }

  .dropdown {
    display: inline-block;
    margin-right: 10px;
    text-align: left;

    &__menu {
      right: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0 0;
    -webkit-box-pack: justify;
  }

  &__header-subitem {
    display: flex;
  }

  &__table-loading {
    margin-top: 20px;
  }
}
</style>

<i18n locale="fr">
{
  "columns": {
    "title": "Colonnes",
    "early": {
      "0": "Avance > {0}",
      "1": "Avance entre {0} et {1}"
    },
    "late": {
      "0": "Retard > {0}",
      "1": "Retard entre {0} et {1}"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} sec",
    "allChecked": "Tout cocher",
    "average": "Avance/retard moyen",
    "categories": "Catégories d'avance/retard",
    "date": "Date",
    "formattedTripName": "Nom de course formaté",
    "id": "Identifiant",
    "name": "Nom",
    "rate": "Taux de ponctualité",
    "routeLongName": "Nom de ligne long",
    "routeShortName": "Nom de ligne court",
    "week": "Semaine"
  },
  "downloadRawData": "Télécharger les données brutes",
  "dataFileName": "rapport_de_ponctualité_{0}_à_{1}.csv",
  "rawDataFilename": "rapport_de_ponctualité_brut_{0}_à_{1}.csv",
  "punctuality": "Ponctualité"
}
</i18n>

<i18n locale="en">
{
  "columns": {
    "title": "Columns",
    "early": {
      "0": "Advance > {0}",
      "1": "Advance between {0} and {1}"
    },
    "late": {
      "0": "Delay > {0}",
      "1": "Delay between {0} and {1}"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} sec",
    "allChecked": "Check all",
    "average": "Average advance/delay",
    "categories": "Advance/delay categories",
    "date": "Date",
    "formattedTripName": "Formatted trip name",
    "id": "Identifier",
    "name": "Name",
    "rate": "Punctuality rate",
    "routeLongName": "Long line name",
    "routeShortName": "Short line name",
    "week": "Week"
  },
  "downloadRawData": "Download raw data",
  "dataFileName": "punctuality_report_{0}_to_{1}.csv",
  "rawDataFilename": "raw_punctuality_report_{0}_to_{1}.csv",
  "punctuality": "Punctuality"
}
</i18n>

<i18n locale="cz">
{
  "columns": {
    "early": {
      "0": "Předstih > {0} minut",
      "1": "Předstih  {0} až {1} minut"
    },
    "late": {
      "0": "Zpoždění > {0} minut",
      "1": "Zpoždění {0} až {1} minut"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} s",
    "average": "Průměrný předstih/zpoždění",
    "categories": "Kategorie předstihu/zpoždění",
    "rate": "Dochvilnost",
    "routeLongName": "Dlouhý název linky",
    "routeShortName": "Krátký název linky",
    "week": "Týden",
    "allChecked": "Ověřit vše",
    "date": "Datum",
    "formattedTripName": "Formátovaný název jízdy",
    "id": "Identifikátor",
    "name": "Název",
    "title": "Sloupce"
  },
  "punctuality": "Dochvilnost"
}
</i18n>

<i18n locale="de">
{
  "columns": {
    "early": {
      "0": "Verfrühung > {0} Min.",
      "1": "Verfrühung zwischen {0} und {1} Min."
    },
    "late": {
      "0": "Verspätung > {0} Min.",
      "1": "Verspätung zwischen {0} und {1} Min."
    },
    "min": "{0} Min.",
    "minSec": "{0} Min. {1} Sek.",
    "average": "Durchschnittliche Verfrühung/Verspätung",
    "categories": "Kategorien für Verfrühung/Verspätung",
    "rate": "Pünktlichkeitsrate",
    "routeLongName": "Langer Name der Strecke",
    "routeShortName": "Kurzname der Strecke",
    "week": "Woche",
    "allChecked": "Alle auswählen",
    "date": "Datum",
    "formattedTripName": "Formatierter Fahrtname",
    "id": "Kennung",
    "name": "Name",
    "title": "Spalten"
  },
  "punctuality": "Pünktlichkeit"
}
</i18n>

<i18n locale="es">
{
  "columns": {
    "early": {
      "0": "Adelanto > {0} min",
      "1": "Adelanto de entre {0} y {1} min"
    },
    "late": {
      "0": "Retraso > {0} min",
      "1": "Retraso de entre {0} y {1} min"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} seg",
    "average": "Adelanto/retraso medio",
    "categories": "Categorías de adelanto/retraso",
    "rate": "Tasa de puntualidad",
    "routeLongName": "Nombre de línea largo",
    "routeShortName": "Nombre de línea corto",
    "week": "Semana",
    "allChecked": "Verificar todo",
    "date": "Fecha",
    "formattedTripName": "Nombre de servicio formateado",
    "id": "Identificador",
    "name": "Nombre",
    "title": "Columnas"
  },
  "punctuality": "Puntualidad"
}
</i18n>

<i18n locale="it">
{
  "columns": {
    "early": {
      "0": "Anticipo > {0} min",
      "1": "Anticipo compreso tra {0} e {1} min"
    },
    "late": {
      "0": "Ritardo > {0} min",
      "1": "Ritardo compreso tra {0} e {1} min"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} sec",
    "average": "Anticipo/ritardo medio",
    "categories": "Categorie di anticipo/ritardo",
    "rate": "Indice di puntualità",
    "routeLongName": "Nome completo della linea",
    "routeShortName": "Nome abbreviato della linea",
    "week": "Settimana",
    "allChecked": "Contrassegna tutto",
    "date": "Data",
    "formattedTripName": "Nome formattato del servizio",
    "id": "Identificatore",
    "name": "Nome",
    "title": "Colonne"
  },
  "punctuality": "Puntualità"
}
</i18n>

<i18n locale="pl">
{
  "columns": {
    "early": {
      "0": "Przed czasem > {0} min",
      "1": "{0}-{1} min przed czasem"
    },
    "late": {
      "0": "Opóźnienie > {0} min",
      "1": "Opóźnienie między {0} a {1} min"
    },
    "min": "{0} min",
    "minSec": "{0} min {1} sek.",
    "average": "Średni przejazd przedwczesny/opóźniony",
    "categories": "Kategorie przejazdów przedwczesnych/opóźnionych",
    "rate": "Współczynnik punktualności",
    "routeLongName": "Nazwa długiej linii",
    "routeShortName": "Nazwa krótkiej linii",
    "week": "Tydzień",
    "allChecked": "Zaznacz wszystkie",
    "date": "Data",
    "formattedTripName": "Sformatowana nazwa usługi",
    "id": "Identyfikator",
    "name": "Nazwa",
    "title": "Kolumny"
  },
  "punctuality": "Punktualność"
}
</i18n>
