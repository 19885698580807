// https://github.com/jquense/yup/blob/master/src/locale.ts
// Converted to JS using https://transform.tools/typescript-to-javascript

import { printValue, ValidationError } from 'yup'

export let mixed = {
  default: 'Ce champs est invalide',
  required: 'Ce champs est requis',
  defined: 'Ce champs doit être défini',
  notNull: 'Ce champs ne peut pas être null',
  oneOf: 'Ce champs doit correspondre à une des valeurs suivantes: ${values}',
  notOneOf: 'Ce champs ne doit pas corresponds à une des valeurs suivantes: ${values}',
  notType: ({ path, type, value, originalValue }) => {
    const castMsg =
      originalValue != null && originalValue !== value
        ? ` (converti à partir de la valeur \`${printValue(originalValue, true)}\`).`
        : '.'

    return type !== 'mixed'
      ? `Ce champs doit être de type \`${type}\`, ` +
      `mais la valeur finale est: \`${printValue(value, true)}\`` +
      castMsg
      : `Ce champs doit correspondre au type configuré. ` +
      `La valeur validée est: \`${printValue(value, true)}\`` +
      castMsg
  },
}

export let string = {
  length: 'Ce champs doit faire ${length} caractères exactement',
  min: 'Ce champs doit faire au moins ${min} caractères',
  max: 'Ce champs doit faire au plus ${max} caractères',
  matches: 'Ce champs doit correspondre à l\'expression suivante: "${regex}"',
  email: 'Ce champs doit être un email valide',
  url: 'Ce champs doit être une URL valide',
  uuid: 'Ce champs doit être un UUID valide',
  trim: 'Ce champs doit être une chaine de caractère taillée',
  lowercase: 'Ce champs doit être une chaine de caractère en miniscule',
  uppercase: 'Ce champs doit être une chaine de caractère en majuscule',
}

export let number = {
  min: 'Ce champs doit être plus grand que ou égal à ${min}',
  max: 'Ce champs doit être plus petit que ou égal à ${max}',
  lessThan: 'Ce champs doit être plus petit que ${less}',
  moreThan: 'Ce champs doit être plus grand que ${more}',
  positive: 'Ce champs doit être un nombre positif',
  negative: 'Ce champs doit être un nombre négatif',
  integer: 'Ce champs doit être un entier',
}

export let date = {
  min: 'Ce champs doit être après ${min}',
  max: 'Ce champs doit être avant ${max}',
}

export let boolean = {
  isValue: 'Ce champs doit être ${value}',
}

export let object = {
  noUnknown: 'Ce champs a des clés non spécifiées: ${unknown}',
}

export let array = {
  min: 'Ce champs doit avoir au moins ${min} éléments',
  max: 'Ce champs doit avoir au maximum ${max} éléments',
  length: 'Ce champs doit avoir ${length} éléments',
}

export let tuple = {
  notType: params => {
    const { path, value, spec } = params
    const typeLen = spec.types.length
    if (Array.isArray(value)) {
      if (value.length < typeLen)
        return `Ce tuple n'as pas assez d'éléments, ${typeLen} attendus mais ${
          value.length
        } obtenus pour la valeur: \`${printValue(value, true)}\``
      if (value.length > typeLen)
        return `Ce tuple a trop d'éléments, ${typeLen} attendus mais ${
          value.length
        } obtenus pour la valeur: \`${printValue(value, true)}\``
    }

    return ValidationError.formatError(mixed.notType, params)
  },
}

export default Object.assign(Object.create(null), {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
})
