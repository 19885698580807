<template>
  <div class="select-filter">
    <div class="select-filter__dropdown-selector dropdown" :class="{ 'dropdown--open': dropdownOpened }">
      <Btn
        class="select-filter__button"
        :type="hasFiltersActive ? 'secondary-active' : 'secondary'"
        :disabled="disabled"
        @click="dropdownToggle"
        @mousedown.prevent
      >
        <div v-if="typeof displayedLabelButton === 'object'" class="select-filter__button-label-route">
          <div v-for="route in displayedLabelButton" :key="route.id">
            <RouteBadge
              :route="route.routeProperties"
              :value="route.label"
              :is-experimental="route.isDeactivated"
            />
          </div>
          <span v-if="hasManyRoutesBadge">...</span>
        </div>
        <span v-else class="select-filter__button-label">{{ displayedLabelButton }}</span>
        <font-awesome-icon :icon="dropdownOpened ? 'fa-angle-up' : 'fa-angle-down'" />
      </Btn>

      <ul class="dropdown__menu" @click.stop>
        <li class="dropdown__item dropdown__item--separator">
          <Checkbox
            :id="`${lsStorageKey}_columns`"
            :checked="isEverythingChecked"
            :indeterminate="filters.length > 0 && !isEverythingChecked"
            @change="toggleAll(filters.length > 0 && !isEverythingChecked)"
          >
            <template #label>
              {{ isEverythingChecked ? $t('uncheckAll') : $t('columns.allChecked') }}
            </template>
          </Checkbox>
        </li>

        <li
          v-for="option in filterList"
          :key="`${lsStorageKey}_${option.id.toString()}_dropdown`"
          class="dropdown__item"
        >
          <div v-if="option.isSeparationline" class="separation-line" />
          <div v-else>
            <Checkbox
              :id="`${lsStorageKey}_${option.id.toString()}`"
              :checked="filters.includes(option.id)"
              @change="updateSelectedValues(option.id)"
            >
              <template #label>
                {{ option.label }}
              </template>
            </Checkbox>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'clone-deep';
import Btn from '@/components/ui/Btn.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import RouteBadge from '@/components/common/RouteBadge.vue';

export default {
  name: 'SelectFiltersDropdown',

  components: {
    Btn,
    Checkbox,
    RouteBadge,
  },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    /** @type {import('vue').Prop<Array<DropdownOption>>} */
    filterList: {
      type: Array,
      required: true,
    },
    lsStorageKey: {
      type: String,
      required: true,
    },
    filterName: {
      type: String,
      required: true,
    },
    /** @type {import('vue').Prop<Array<String>>} */
    filters: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:filters'],

  data() {
    return { dropdownOpened: false, hasManyRoutesBadge: false };
  },
  computed: {
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
    /** @return {Array<DropdownOption>} */
    optionList() {
      return this.filterList.filter(option => option.isSeparationline !== true);
    },
    /** @return {boolean} */
    isEverythingChecked() {
      return this.filters.length === this.optionList.length;
    },
    /** @return {boolean} */
    isNothingChecked() {
      return this.filters.length === 0;
    },
    /** @return {boolean} */
    hasFiltersActive() {
      // we are considering that some filters are selected only if 1 or more filters are selected, but not everyone
      return !this.isEverythingChecked && !this.isNothingChecked;
    },
    /** @return {string | Array<DropdownOption>} */
    displayedLabelButton() {
      if (!this.hasFiltersActive) return this.filterName;

      // handle case route for badges
      if (this.optionList[0].routeProperties) {
        let result = this.optionList.filter(option => this.filters.includes(option.id));
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hasManyRoutesBadge = result.length > 3;
        if (result.length > 3) {
          result = result.splice(0, 3);
        }
        return result;
      }

      const items = this.optionList
        .filter(option => this.filters.includes(option.id))
        .map(option => {
          return option.label;
        });
      return items.join(', ');
    },
  },

  created() {
    // Check if user has saved filters in LS
    const hasSavedFilters = this.searchLsAndLoadFilters();
    // if no values,  check all
    if (!hasSavedFilters) this.toggleAll();
  },

  methods: {
    /**
     * Close the opened dropdown
     */
    dropdownClose() {
      this.dropdownOpened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open a dropdown
     */
    dropdownOpen() {
      this.dropdownOpened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Call dropdownOpen or dropdownClose
     */
    dropdownToggle() {
      if (!this.dropdownOpened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },

    updateSelectedValues(value) {
      let newFilterList = [];
      if (this.filters.includes(value)) newFilterList = cloneDeep(this.filters.filter(v => v !== value));
      else newFilterList = [...this.filters, value];

      this.saveChange(newFilterList);
    },

    /**
     * @param {Boolean} isPartialSelection
     * @param {Boolean} forceSelect
     */
    toggleAll(isPartialSelection, forceSelect = false) {
      let newFilterList = [];
      if (!this.isEverythingChecked || forceSelect) newFilterList = this.optionList.map(option => option.id);
      if (isPartialSelection) newFilterList = [];

      this.saveChange(newFilterList);
    },

    saveChange(newFilterList) {
      this.$emit('update:filters', newFilterList);
      this.saveFilters(newFilterList);
    },

    searchLsAndLoadFilters() {
      const savedFilters = this.getLsFilters();
      if (savedFilters && savedFilters.length > 0) {
        this.$emit('update:filters', savedFilters);
        return true;
      }
      return false;
    },

    /**
     * @param {Array<string>} filters
     */
    saveFilters(filters) {
      setTimeout(() => {
        if (this.isEverythingChecked || this.isNothingChecked) {
          localStorage.removeItem(this.lsStorageKey);
        } else {
          localStorage.setItem(this.lsStorageKey, JSON.stringify({ groupId: this.groupId, filters }));
        }
      }, 1000);
    },

    /**
     * @return {Array<string>}
     */
    getLsFilters() {
      const stored = JSON.parse(localStorage.getItem(this.lsStorageKey));
      // Don't recover filters if groupId is different
      if (!stored || (stored && stored.groupId !== this.groupId)) return [];

      // Don't recover filters if stored values are different from optionList
      if (stored.filters.length > 0 && this.optionList.length > 0) {
        const unusualValue = stored.filters.find(
          filter => !this.optionList.map(option => option.id).includes(filter)
        );
        if (unusualValue) return [];
      }
      return stored.filters;
    },
  },
};
/**
 * @typedef {Object} DropdownOption
 * @property {string} id
 * @property {string} [label]
 * @property {boolean} [isSeparationline]
 * @property {Object} [routeProperties]
 * @property {boolean} [isDeactivated]
 */
</script>

<style lang="scss">
.select-filter {
  .ui-btn {
    border-radius: 46px;
  }

  .ui-btn.secondary {
    border-color: $border;
  }

  .dropdown__menu {
    overflow: auto;
    max-height: 300px;
    margin-top: 10px;
    border-color: $border-variant;
    border-radius: 4px;
    background-color: $canvas;
  }

  &__button {
    display: flex;

    &:not(.icon-only) {
      svg {
        &:not(:first-child) {
          align-self: center;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  &__button-label {
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button-label-route {
    display: flex;
    gap: 4px;
  }

  &__dropdown-selector {
    .dropdown__item {
      margin-bottom: 4px;
    }

    .dropdown__item:has(.separation-line) {
      height: 4px;
      padding-top: 1px;
    }

    .separation-line {
      width: 100%;
      height: 1px;
      margin: 0;
      padding: 0;
      background-color: $border;
    }
  }
}
</style>

<i18n locale="fr">
{
  "columns": {
    "columns": "Colonnes",
    "allChecked": "Tout cocher"
  }
}
</i18n>

<i18n locale="en">
{
  "columns": {
    "columns": "Columns",
    "allChecked": "Check all"
  }
}
</i18n>
