// we use the plugin https://www.npmjs.com/package/vite-plugin-vuetify
// it will  automatically import all Vuetify components as you use them
// No need to specify it there

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa';

const customTheme = {
  colors: {
    success: '#00b871', // $primary-light
    text: '#333', // $text-dark
  },
};

export default createVuetify({
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
});
