const appSuffix = window.location.hostname !== 'localhost' ? 'op2' : '';
const appPort = window.location.port ? `:${window.location.port}` : '';

const baseAppUrl = `${window.location.protocol}//${window.location.hostname}${appPort}`;
const AppUrl = `${baseAppUrl}/${appSuffix}`;

/**
 * Generate an URL encoded string to pass query params.
 * @param {{[key: string]: string}} obj
 * @return {string}
 */
function objToParams(obj) {
  return Object.entries(obj)
    .reduce((acc, [key, value]) => {
      acc.push(`${key}=${encodeURIComponent(value)}`);
      return acc;
    }, [])
    .join('&');
}

export { baseAppUrl, AppUrl, objToParams };
