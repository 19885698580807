<script setup>
import { GroupRoute } from '@/libs/routing';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchBar from '@/components/common/SearchBar.vue';

const router = useRouter();
const store = useStore();
const menu = ref(false);

const searchGroup = ref();
const searchBarInput = computed(() => {
  return searchGroup.value?.$el.getElementsByTagName('input')?.[0];
});

const groupList = store.state.groups;

const filteredGroupList = computed(() => {
  return filteredList.value ?? groupList;
});

const filteredList = ref();

/** @return {import('@/store').Group} */
const currentGroup = computed(() => {
  return store.getters.group;
});

const hasOnlyOneGroup = computed(() => {
  return groupList.length === 1;
});

watch(
  menu,
  menuValue => {
    if (menuValue) {
      filteredList.value = undefined;
      setTimeout(() => {
        searchBarInput.value?.focus();
      }, 250);
    }
  },
  {
    immediate: true,
  }
);

/** @param {string} groupId */
function selectGroup(groupId) {
  menu.value = false;
  router.push({ name: GroupRoute.GROUP, params: { groupId } });
}

function setSearchedList(list) {
  filteredList.value = list;
}
</script>

<template>
  <div class="select-group-dropdown">
    <v-menu v-model="menu" offset="8" min-height="100" width="400" :close-on-content-click="false">
      <template #activator="{ isActive, props }">
        <v-btn
          :disabled="hasOnlyOneGroup"
          class="select-group-dropdown__btn"
          variant="text"
          height="36"
          min-width="40"
          v-bind="props"
        >
          <v-avatar class="mr-2" size="x-small" :color="currentGroup.color ?? '#00B871'">
            <span class="select-group-dropdown__avatar">{{ currentGroup.name.slice(0, 1) }}</span>
          </v-avatar>

          {{ $t(currentGroup.name) }}
          <v-icon v-if="!hasOnlyOneGroup" class="ml-2">
            {{ isActive ? 'fa:fas fa-angle-up' : 'fa:fas fa-angle-down' }}
          </v-icon>
        </v-btn>
      </template>
      <v-list class="topbar-dropdown__list select-group-dropdown__list">
        <v-list-item>
          <SearchBar
            ref="searchGroup"
            class="select-group-dropdown__search"
            :search-fields="['_id', 'name']"
            :search-list="groupList"
            @filteredList="setSearchedList"
          />
        </v-list-item>
        <v-divider></v-divider>
        <div class="select-group-dropdown__scrollable-list">
          <v-list-item
            v-for="(item, index) in filteredGroupList"
            :key="index"
            :class="{ 'item-selected': item._id === currentGroup._id }"
            :value="index"
            @click="selectGroup(item._id)"
          >
            <template #prepend>
              <v-avatar size="x-small" :color="item.color">
                <span class="select-group-dropdown__avatar">{{ item.name.slice(0, 1) }}</span>
              </v-avatar>
            </template>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="filteredGroupList.length === 0" class="select-group-dropdown__no-result">
            {{ $t('noResult') }}
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss">
.select-group-dropdown {
  &__btn {
    border: 1px solid $border;
    border-radius: 8px;
    background-color: transparent;

    .v-btn__content {
      font-size: 16px;
      text-transform: capitalize;

      i {
        font-size: 16px;
      }
    }
  }

  &__btn.v-btn--disabled {
    padding-left: 0;
    border: none;
    opacity: 1;
  }

  &__search {
    .table-search-bar__input {
      width: 100%;
      height: 36px;
    }
  }

  &__avatar {
    font-size: 14px;
  }

  &__list {
    .item-selected {
      background: $background-variant;

      .v-list-item-title {
        font-weight: 600;
      }
    }
  }

  &__scrollable-list {
    overflow: auto;
    max-height: 290px;
  }

  &__no-result {
    font-style: italic;
  }
}
</style>
