/**
 * Detect user's language.
 * https://github.com/kazupon/vue-cli-plugin-i18n/issues/13#issuecomment-404221369
 * @param {Array<string>} availableLocales
 * @return {?string}
 */
export default function detectLang(availableLocales) {
  // defined from https://gist.github.com/ksol/62b489572944ca70b4ba
  // These window.navigator contain language information
  // 1. languages -> Array of preferred languages (eg ["en-US", "zh-CN", "ja-JP"])
  //                   Firefox^32, Chrome^32
  // 2. language  -> Preferred language as String (eg "en-US") Firefox^5, IE^11,
  //                   Safari, Chrome sends Browser UI language
  // 3. browserLanguage -> UI Language of IE
  // 4. userLanguage    -> Language of Windows Regional Options
  // 5. systemLanguage  -> UI Language of Windows
  const browserLanguagePropertyKeys = [
    'languages',
    'language',
    'browserLanguage',
    'userLanguage',
    'systemLanguage',
  ];

  let allLangs = [];
  browserLanguagePropertyKeys.forEach(a => allLangs.push(navigator[a]));
  allLangs = []
    .concat(...allLangs) // Merge all values into flattened array
    .filter(v => v) // Remove undefined values
    .map(v => v.substring(0, 2)) // Shorten strings to use two chars (en-US -> en)
    .filter((v, i, a) => a.indexOf(v) === i); // Returns unique values

  // Returns first language matched in available languages
  return allLangs.find(x => availableLocales.includes(x));
}
