<script setup>
import dayjs from 'dayjs';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { trips } from '@/api';
import ModalPassengersMessage from '@/components/common/ModalPassengersMessage.vue';
import ModalTripModification from '@/components/common/ModalTripModification/index.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { TripListDataFormatter } from '@/pages/TripsListPage/TripListDataFormatterHelper';
import { LocationType } from '@/store/gtfs';

import { getDatagrid, ColumnKey } from './DeviationList.conf';
import DeleteTripUpdatesModal from './ModalDeleteTripUpdates.vue';

/** @enum {string} */
const ModalType = {
  DELETE: 'delete',
  MODIFY: 'modify',
  PASSENGER_MESSAGE: 'message',
};

/** @enum {string} */
const Tabs = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const store = useStore();
const { t } = useI18n();

/** @type {import('vue').Ref<import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid>} */
const datagrid = ref(getDatagrid());
/** @type {import('vue').Ref<String> | null} */
const error = ref(null);
/** @type {import('vue').Ref<Boolean>} */
const loading = ref(true);
/** @type {import('vue').Ref<string>} */
const modalShown = ref(null);
/** @type {import('vue').Ref<Array<import('@/pages/PassengersMessage/index.vue').OptionsEntities>>}*/
const optionsEntities = ref([]);
/** @type {import('vue').Ref<import('@/pages/PassengersMessage/index.vue').PassengersMessage>} */
const preFilledMessage = ref(null);
/** @type {import('vue').Ref<Number>} */
const renderedDataLength = ref(0);
/** @type {import('vue').Ref<string>} */
const selectedTab = ref(Tabs.ACTIVE);
/** @type {import('vue').Ref<DeviationTrip | null>} */
const selectedTrip = ref(null);
/** @type {import('vue').Ref<Array<DeviationTrip>>} */
const shownTrips = ref([]);

/** @type {string} */
const gtfsDate = dateObjToGtfsFormat(new Date());
/** @type {Array<String>} */
const statusCategories = [Tabs.ALL, Tabs.ACTIVE, Tabs.INACTIVE];

/** @return {import('@/store').Group} */
const group = computed(() => store.getters.group);

/** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
const tabs = computed(() => {
  return statusCategories.map(category => ({
    value: category,
    name: t(`tabs.${category}`),
    counter: null,
    dataList: [],
    filterField: ColumnKey.DATE,
    filterValues: getFilterValues(category),
    isDefaultActive: selectedTab.value === category,
    icon: getTabIcon(category),
  }));
});

/** @return {String} */
function getTabIcon(category) {
  if (category === Tabs.ACTIVE) return 'fa:fas fa-calendar-alt';
  else if (category === Tabs.INACTIVE) return 'fa:fas fa-archive';
  return '';
}

/** @return {Array<String>} */
function getFilterValues(category) {
  if (category === Tabs.ACTIVE) {
    return [dateObjToGtfsFormat(new Date())];
  } else if (category === Tabs.INACTIVE) {
    return [dateObjToGtfsFormat(dayjs(new Date()).subtract(1, 'day').toDate())];
  }
  return [];
}

// TODO - Temporary - waiting for new endpoint
async function loadData() {
  loading.value = true;

  try {
    const tripsData = await trips.getTripListV4(group.value._id, gtfsDate);
    error.value = null;

    const processedTrips = tripsData
      .filter(
        trip =>
          trip.updates && (trip.updates.delay || trip.updates.canceled || trip.updates.skipped_stop_sequences)
      )
      .map(trip => {
        const gtfsId = trip.gtfs && trip.gtfs.length > 0 ? trip.gtfs[0].id : null;
        return {
          id: trip.id,
          formatted_name: trip.formatted_name,
          route: trip.route,
          service_date: trip.service_date,
          updates: trip.updates,
          gtfs_id: gtfsId,
          departureTime: trip.departure_time,
          firstStop: trip.first_stop,
          lastStop: trip.last_stop,
        };
      });

    shownTrips.value = processedTrips;
  } catch (e) {
    error.value = t('serverError');
  } finally {
    loading.value = false;
  }
}

// TODO
function download() {
  window.open(`/api/v3/groups/${group.value._id}/export/deviation-list?date=${gtfsDate}`);
}

function onTabChange(selectedTabValue) {
  selectedTab.value = selectedTabValue;
}

// TODO
function newTripModification() {}

function schedulePassengersAlert(trip) {
  modalShown.value = ModalType.PASSENGER_MESSAGE;
  const message = {
    header_text: '',
    active: true,
    description_text: '',
    active_date_times: [
      {
        start_date: trip.service_date,
        end_date: trip.service_date,
        start_time: '00:00',
        end_time: '23:59',
      },
    ],
    effect: null,
    informed_entity: [{ route_id: trip.route.id }],
  };
  // Canceled trip
  if (trip.updates.canceled) {
    message.header_text = t('canceledHeaderText') + trip.route.short_name;
    message.description_text = t('cancelationDescription', [
      TripListDataFormatter.formatTimeHHMM(trip.departureTime, group.value.tz),
      trip.firstStop.name,
      trip.lastStop.name,
      dayjs(trip.service_date, 'YYYYMMDD').format('DD/MM/YYYY'),
      dayjs(trip.service_date, 'YYYYMMDD').format('DD/MM/YYYY'),
    ]);
    // "No service" effect
    message.effect = 1;
  }
  // Delayed or modified trip
  if (trip.updates.delay || trip.updates.skipped_stop_sequences) {
    message.header_text = t('deviationHeaderText') + trip.route.short_name;
    message.description_text = t('deviationDescription', [
      dayjs(trip.service_date, 'YYYYMMDD').format('DD/MM/YYYY'),
      dayjs(trip.service_date, 'YYYYMMDD').format('DD/MM/YYYY'),
      TripListDataFormatter.formatTimeHHMM(trip.departureTime, group.value.tz),
      trip.firstStop.name,
      trip.lastStop.name,
    ]);
    // "Detour" effect
    message.effect = 4;
  }
  preFilledMessage.value = message;
}

/**
 * @param {DeviationTrip} trip
 */
function editTrip(trip) {
  showModal(ModalType.MODIFY, trip);
}

/**
 * @param {String} type
 * @param {DeviationTrip} [trip]
 */
function showModal(type, trip = null) {
  selectedTrip.value = trip;
  modalShown.value = type;
}

/**
 * @param {boolean} [refresh]
 */
async function closeModal(refresh = false) {
  modalShown.value = null;
  selectedTrip.value = null;
  if (refresh) {
    await loadData();
  }
}

async function getRoutes() {
  /** @type {{[routeId: string]: import('@/store/gtfs').Route}} */
  const routes = await store.dispatch('gtfs/getRoutesMap', {
    ts: Date.now(),
  });

  return Object.values(routes).map(r => {
    const value = { route_id: r.route_id };
    return {
      name: r.route_short_name,
      key: JSON.stringify(value),
      value,
    };
  });
}

async function getStops() {
  /** @type {{[stopId: string]: import('@/store/gtfs').Stop}} */
  const stops = await store.dispatch('gtfs/getStopsMap', { ts: Date.now() });

  return Object.values(stops).map(s => {
    const value = { stop_id: s.stop_id };
    let stopName = `${s.stop_name} - ${s.stop_code || s.stop_id}`;
    if (s.location_type?.toString() === LocationType.STATION) {
      stopName += t('station');
    }
    return {
      name: stopName,
      key: JSON.stringify(value),
      value,
    };
  });
}

async function getOptionsEntities() {
  const [routes, stops] = await Promise.all([getRoutes(), getStops()]);

  optionsEntities.value = [
    {
      label: /** @type {string} */ (t('routes')),
      values: routes,
    },
    {
      label: /** @type {string} */ (t('stops')),
      values: stops,
    },
  ];
}

onMounted(() => {
  if (group.value._id) {
    loadData();
    getOptionsEntities();
  }
});

/**
 * @typedef {Object} DeviationTrip
 * @property {string} id
 * @property {string} formatted_name
 * @property {import('@/api').TripListRoute} route
 * @property {string} service_date
 * @property {import('@/api').TripUpdates} updates
 * @property {string} gtfs_id
 * @property {number} departureTime
 * @property {import('@/api').TripListStop} firstStop
 * @property {import('@/api').TripListStop} lastStop
 */
</script>

<template>
  <div class="deviation-list">
    <div class="deviation-list__header">
      <Btn type="secondary" class="deviation-list__btn" @click="download">
        <font-awesome-icon icon="fa-download" class="fa-icon" />
        {{ $t('download') }}
      </Btn>

      <Btn type="primary" class="deviation-list__btn" @click="newTripModification">
        <font-awesome-icon icon="fa-plus" class="fa-icon" />
        {{ $t('newTripModification') }}
      </Btn>
    </div>

    <div class="deviation-list__body">
      <DataGridVuetify
        ref="dataGrid"
        v-model:renderedDataLength="renderedDataLength"
        :data="shownTrips"
        :datagrid="datagrid"
        :loading="loading"
        :error="error"
        :tabs="tabs"
        @tabChange="onTabChange"
      >
        <template #actions="propsAction">
          <ActionCell
            :actions="['programAlert', 'edit', 'delete']"
            :object="propsAction.object"
            @programAlert="schedulePassengersAlert(propsAction.object)"
            @edit="editTrip(propsAction.object)"
            @delete="showModal(ModalType.DELETE, propsAction.object)"
          />
        </template>
      </DataGridVuetify>
    </div>

    <ModalPassengersMessage
      v-if="modalShown === ModalType.PASSENGER_MESSAGE"
      :options-entities="optionsEntities"
      :selected-message="preFilledMessage"
      @close="closeModal"
    />

    <ModalTripModification
      v-if="modalShown === ModalType.MODIFY"
      :date="gtfsDate"
      :gtfs-id="selectedTrip.gtfs_id"
      :trip-formatted-name="selectedTrip.formatted_name"
      :trip-id="selectedTrip.id"
      :trip-updates="selectedTrip.updates"
      @close="closeModal(true)"
    />

    <DeleteTripUpdatesModal
      v-if="modalShown === ModalType.DELETE && selectedTrip"
      :trip="selectedTrip"
      @close="closeModal"
      @closeRefresh="closeModal(true)"
    />
  </div>
</template>

<style lang="scss">
.deviation-list {
  padding: $view-standard-padding;

  &__btn {
    svg {
      margin-right: 5px;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }
}
</style>

<i18n locale="fr">
{
  "canceledHeaderText": "Service annulé sur votre ligne ",
  "cancelationDescription": "Annulation du trajet de {0} entre {1} et {2} sur la période du {3} au {4}.",
  "deviationDescription":  "Déviation prévue entre le {0} et le {1} sur le trajet de {2} entre {3} et {4}",
  "deviationHeaderText": "Déviation sur votre ligne ",
  "download": "Télécharger",
  "newTripModification": "Nouvelle modification",
  "serverError": "Une erreur est survenue, veuillez réessayer plus tard.",
  "tabs": {
    "all": "Toutes",
    "active": "Actives",
    "inactive": "Inactives"
  }
}
</i18n>

<i18n locale="en">
{
  "canceledHeaderText": "Trip has been canceled on line ",
  "cancelationDescription": "Cancelation of the {0} trip between {1} and {2} from {3} to {4}.",
  "deviationDescription":  "Planned detour from {0} to {1} on the {2} trip from {3} to {4}",
  "deviationHeaderText": "Detour on your trip ",
  "download": "Download",
  "newTripModification": "New Trip Modification",
  "serverError": "An error occurred, please try again later.",
  "tabs": {
    "all": "All",
    "active": "Active",
    "inactive": "Inactive"
  }
}
</i18n>
